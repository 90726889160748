import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteProps, Route, Redirect } from 'react-router-dom'
import { AppState } from '../Core/store'
import Config from '../Common/Config'
import { MeResponseEntity } from '../Core/API/APIResponse.types'

export enum Roles {
  ISSUER = 'issuer',
  INVESTOR = 'investor'
}

function canAccess(pathRoles: Roles[], userRoles: Roles[]) {
  for (const role of pathRoles) {
    if (userRoles.includes(role)) {
      return true
    }
  }

  return false
}

export function getRoles(user: Partial<MeResponseEntity>) {
  let roles: Roles[] = []
  if (user) {
    roles = user.issuer ? [...roles, Roles.ISSUER] : roles
    roles = user.investor ? [...roles, Roles.INVESTOR] : roles
  }

  return roles
}

type Props =
  RouteProps &
  ReturnType<typeof mapStateToProps> &
  {
    pathAllowedUsers?: Roles[]
  }

const mapStateToProps = (state: AppState) => ({
  loggedIn: state.auth.loggedIn,
  me: state.auth.me
})

class PrivateRoute extends Component<Props> {

  public render() {
    const {
      component: Component,
      loggedIn,
      me,
      pathAllowedUsers,
      ...rest
    } = this.props

    if (loggedIn) {
      if (me && me.eventId && pathAllowedUsers) {
        const myRoles = getRoles(me)
        if (canAccess(pathAllowedUsers, myRoles)) {
          // @ts-ignore
          return <Route {...rest} render={(props) => <Component {...props}/>} />
        } else {
          return <Redirect to={'/unauthorized'}/>
        }
      }
      // @ts-ignore
      return <Route {...rest} render={(props) => <Component {...props}/>} />

    } else {
      window.location.replace(`${Config.ONBOARDING_BASE_URL}/logout`)
      return null
    }
  }
}

export default connect(
  mapStateToProps
)(PrivateRoute)
