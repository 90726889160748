import React, { FC } from 'react'
import { BasePropsTypes } from '../../Common/Types/BaseProps.types'
import Spinner from '../Spinner'
import './styles.scss'

interface Props extends BasePropsTypes {
  isLoading?: boolean
}

const Panel: FC<Props> = (props) => {

  const {
    className = '',
    isLoading
  } = props

  return (
    <div className={`panelContainer ${className}`}>
      {isLoading
        ? <Spinner/>
        : props.children
      }
    </div>
  )
}

export default Panel
