import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Header from '../../Elements/Header'
import { ReactComponent as Logo } from '../../Common/Assets/siemens-header-logo.svg'
import HamburgerMenu from '../HamburgerMenu'
import NavItem from '../../Elements/NavItem'
import { MODALS } from '../../Common/Constants'
import { logout } from '../../Core/Auth/Auth.actions'
import { AppState } from '../../Core/store'
import { toggleModal } from '../../Core/UI/UI.actions'
import './styles.scss'

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    eventId: string
  }

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout,
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  me: state.auth.me
})

class EventHeader extends Component<Props> {

  public toggleHelpAndSupportModal = () => {
    this.props.toggleModal(MODALS.HELP_AND_SUPPORT, true)
  }

  public logoutHandler = async () => {
    await this.props.logout()
  }

  public render() {
    const {
      eventId,
      me
    } = this.props

    const routePart = me.issuer ? 'issuer' : 'donor'

    return (
      <div className='eventHeader'>
        <Container>
          <Header>
            <Link to={`/event/${eventId}/dashboard/${routePart}`}>
              <Logo
                style={{width: '100%'}}
              />
            </Link>

            <HamburgerMenu
              render={({closeMenu}) => {
                return (
                  <Fragment>
                    {me.investor &&
                      <Fragment>
                        <NavItem to={`/event/${eventId}/dashboard/${routePart}`} onClick={closeMenu}>
                            <Trans>Dashboard</Trans>
                        </NavItem>

                        <NavItem to={`/event/${eventId}/settings`} onClick={closeMenu}>
                            <Trans>My account</Trans>
                        </NavItem>

                        <NavItem
                          onClick={() => {
                            this.toggleHelpAndSupportModal()
                            closeMenu()
                          }}
                        >
                            <Trans>Help and support</Trans>
                        </NavItem>
                      </Fragment>
                    }

                    <NavItem onClick={this.logoutHandler}>
                      <Trans>Log out</Trans>
                    </NavItem>
                  </Fragment>
                )
              }}
            />
          </Header>
        </Container>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventHeader)
