import {StripePaymentMethod} from '../Core/types'

export enum MODALS {
  HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
  DELETE_MY_DATA = 'DELETE_MY_DATA',
  MAKE_A_DONATION = 'MAKE_A_DONATION',
  BANK_INSTRUCTIONS = 'BANK_INSTRUCTIONS',
  CRYPTO_INSTRUCTIONS = 'CRYPTO_INSTRUCTIONS',
  KYA_PROMPT = 'KYA_PROMPT',
  SHARE_MODAL = 'SHARE_MODAL',
  PAYMENT_FAILED_MODAL = 'PAYMENT_FAILED_MODAL',
  STRIPE = 'STRIPE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_EMAIL = 'CHANGE_EMAIL'
}

export enum SUPPORTED_LANGUAGES {
  en = 'en',
  de = 'de',
  fr = 'fr'
}

export const MAX_AMOUNT_WITHOUT_KYA = 10000
export const MAX_AMOUNT_FIAT = 10000

export const STRIPE_CSS = {
  base: {
    fontSize: '1rem',
    fontFamily: 'Siemens Sans, Calibri, sans-serif',
    color: '#2D373C'
  }
}

export const STRIPE_PAYMENT_METHODS: StripePaymentMethod[] = [
/*
not supporting eur
  {
    id: 'ach_credit_transfer',
    name: 'Bank Transfer',
    flow: 'receiver',
    countries: ['US'],
    currencies: ['usd']
  },
*/
  {
    id: 'alipay',
    name: 'Alipay',
    flow: 'redirect',
    countries: ['CN', 'HK', 'SG', 'JP'],
    currencies: ['aud', 'cad', 'eur', 'gbp', 'hkd', 'jpy', 'nzd', 'sgd', 'usd'],
    element: false,
    poll: false
  },
  {
    id: 'bancontact',
    name: 'Bancontact',
    flow: 'redirect',
    countries: ['BE'],
    currencies: ['eur'],
    element: false,
    poll: false
  },
  {
    id: 'card',
    name: 'Card',
    flow: 'none',
    element: true,
    poll: false
  },
  {
    id: 'eps',
    name: 'EPS',
    flow: 'redirect',
    countries: ['AT'],
    currencies: ['eur'],
    element: false,
    poll: false
  },
/*
weird NL stuff
  {
    id: 'ideal',
    name: 'iDEAL',
    flow: 'redirect',
    countries: ['NL'],
    currencies: ['eur'],
    element: true,
    poll: false
  },
*/
  {
    id: 'giropay',
    name: 'Giropay',
    flow: 'redirect',
    countries: ['DE'],
    currencies: ['eur'],
    element: false,
    poll: false
  },
  {
    id: 'multibanco',
    name: 'Multibanco',
    flow: 'receiver',
    countries: ['PT'],
    currencies: ['eur'],
    element: false,
    poll: true
  },
  {
    id: 'sepa_debit',
    name: 'SEPA Direct Debit',
    flow: 'none',
    countries: ['FR', 'DE', 'ES', 'BE', 'NL', 'LU', 'IT', 'PT', 'AT', 'IE', 'FI'],
    currencies: ['eur'],
    element: true,
    poll: false
  },
  {
    id: 'sofort',
    name: 'SOFORT',
    flow: 'redirect',
    countries: ['DE', 'AT'],
    currencies: ['eur'],
    element: false,
    poll: false
  },
  {
    id: 'wechat',
    name: 'WeChat',
    flow: 'none',
    countries: ['CN', 'HK', 'SG', 'JP'],
    currencies: ['aud', 'cad', 'eur', 'gbp', 'hkd', 'jpy', 'nzd', 'sgd', 'usd'],
    element: false,
    poll: true
  }
]
