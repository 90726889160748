import React, { FC } from 'react'
import { BasePropsTypes } from '../../Common/Types/BaseProps.types'
import './styles.scss'

interface Props extends BasePropsTypes {}

const PanelBody: FC<Props> = (props) => {

  const {
    className = ''
  } = props

  return (
    <div className={`panelBody ${className}`}>
      {props.children}
    </div>
  )
}

export default PanelBody
