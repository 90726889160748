import React, { Component } from 'react'

class DonateTosDe extends Component {
  public render() {
    return (
      <div>
        <p>Sie spenden an <strong>Africa GreenTec Help e.V.</strong>, um das Projekt #connect2evolve im Senegal zu unterstützen. Auf Ihrer Bank- oder Kreditkartenabrechnung wird angegeben, dass Ihre Spende an Africa GreenTec Help e.V. geleistet wurde.</p>
        <p>Bitte informieren Sie sich auf der Website von Africa GreenTec Help e.V. über den Zweck der gemeinnützigen Organisation. (<a href='https://www.agthelpev.de' target='_blank' rel='noopener noreferrer'>www.agthelpev.de</a>) </p>
        <p>Sobald eine Spende erfolgt ist, ist die Transaktion endgültig und kann nicht angefochten oder rückgängig gemacht werden. </p>
        <p>Sollte der angestrebte Gesamtbetrag nicht erreicht werden und das Projekt nicht wie beschrieben umgesetzt werden können, wird das gesammelte Geld für andere Projekte im Rahmen des Zwecks von Africa GreenTec Help e.V. verwendet.</p>
        <p>Africa GreenTec Help e.V. behält sich das Recht vor, die endgültige Entscheidung über den Standort des Solartainers® zu treffen, falls unvorhergesehene Umstände seine Aufstellung in Bacco Ndieme verhindern. </p>
        <p><strong>Zahlungsmodalitäten</strong></p>
        <p>Alle Spenden auf dieser Website sind mit einer Transaktionsgebühr verbunden. Der durchschnittliche Betrag unserer Spendenquittungen beträgt etwa 95% des ursprünglichen Betrags. Bitte berücksichtigen Sie dies bei Ihrer Spende, um die Transaktionskosten zu reduzieren.</p>
        <p>Wenn Sie eine Spende tätigen, kann es aufgrund gesetzlicher Bestimmungen (z.B. zur Verhinderung von Geldwäsche) notwendig sein, Ihre Identität oder die von Ihnen gemachten Angaben zu überprüfen, insbesondere bei Spenden über 10.000 EUR. Sie verpflichten sich, die erforderlichen Informationen oder Dokumente zur Verfügung zu stellen. Wenn kein ausreichender Identitätsnachweis erbracht wird, wird die Spende an den Spender zurückgegeben, mit Ausnahme der Transaktions- und Umtauschgebühren.</p>
        <p>Africa GreenTec Help e.V. wird für die Zwecke der Allgemeinen Datenschutzverordnung (GDPR) Spenderinformationen von allen Spendern aufbewahren, die den KYC/AML-Prozess (Know Your Customer/Anti-Money Laundering) durchlaufen müssen. Diese Informationen werden bei Sum and Substance, dem Dienstleister für diese Überprüfungen, mindestens fünf (5) Jahre lang aufbewahrt. </p>
        <p>IP (Internetprotokoll)-Daten werden nur zu statistischen Zwecken gesammelt. Sie werden nicht mit Namen, E-Mail-Adressen oder anderen auf diesen Seiten gesammelten Informationen verknüpft.</p>
        <p>Für jede Spende über 200 EUR kann eine Steuerbescheinigung angefordert werden. Bitte wenden Sie sich an Africa GreenTec Help e.V. (info@africagreentec.help) für diesen Zweck.</p>
        <p>In Deutschland ist für Spenden unter 200 EUR keine gesonderte Steuerbescheinigung erforderlich; als Nachweis der Spende reicht eine Kopie der Transaktion auf dem für die Spende verwendeten Konto aus.</p>
        <p>Siemens Gas and Power GmbH & Co. KG Version 0, Januar 2020</p>
      </div>
    )
  }
}

export default DonateTosDe
