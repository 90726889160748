import React, {Component} from 'react'
import {withI18n, withI18nProps} from '@lingui/react'
import { Trans } from '@lingui/macro'
import {CardElement, IbanElement, IdealBankElement, ReactStripeElements} from 'react-stripe-elements'
import {STRIPE_CSS} from '../../Common/Constants'
import {StripePaymentMethod} from '../../Core/types'
import {getStripePaymentMethodsByCountryCode} from './utils'

type Props =
  withI18nProps &
  {
    countryCode: string,
    onMethodChange: (method: StripePaymentMethod) => void,
    onElementChange: (method: ReactStripeElements.ElementChangeResponse) => void,
    initiated: boolean,
    paymentMethod?: StripePaymentMethod
  }

interface State {
}

class DonateStripePaymentMethod extends Component<Props, State> {

  public state: State = {
  }

  public handleMethodChange(method: StripePaymentMethod) {
    this.setState({method})
    this.props.onMethodChange(method)
  }

/*
  public handleIbanChange(response: ReactStripeElements.ElementChangeResponse) {
    this.props.onElementChange(response)
  }
*/

  public renderSelectedMethod() {
    const {initiated, paymentMethod} = this.props

    if (!paymentMethod || initiated) {
      return
    }

    let flowNode
    if (paymentMethod.id === 'card') {
      flowNode = this.renderFlowCard()
    } else if (paymentMethod.id === 'ideal') {
      flowNode = this.renderFlowIdeal()
    } else if (paymentMethod.id === 'sepa_debit') {
      flowNode = this.renderFlowSepaDebit()
    } else if (paymentMethod.id === 'wechat') {
      flowNode = this.renderFlowWechat()
    } else if (paymentMethod.flow === 'redirect') {
      flowNode = this.renderFlowRedirect()
    } else if (paymentMethod.flow === 'receiver') {
      flowNode = this.renderFlowReceiver()
    }

    return (
      <div className={['stripe-payment-info', `stripe-payment-info-${paymentMethod.id}`].join(' ')}>
        {flowNode}
      </div>
    )
  }

  public renderFlowCard() {
    return (
      <div>
        <CardElement onChange={this.props.onElementChange} style={STRIPE_CSS}  />
      </div>
    )
  }

  public renderFlowSepaDebit() {
    return (
        <div>
          <IbanElement supportedCountries={['SEPA']} style={STRIPE_CSS} onChange={this.props.onElementChange} />
          <p className='notice'>
            <Trans>
              By providing your IBAN and confirming this payment, you’re authorizing Africa GreenTec Help e.V. and
              Stripe, our payment provider, to send instructions to your bank to debit your account. You’re entitled
              to a refund under the terms and conditions of your agreement with your bank.
            </Trans>
          </p>
        </div>
    )
  }

  public renderFlowIdeal() {
    return (
      <IdealBankElement onChange={this.props.onElementChange} style={STRIPE_CSS}  />
    )
  }

  public renderFlowRedirect() {
    return (
      <p className='notice'>
        <Trans>You’ll be redirected to the banking site to complete your payment.</Trans>
      </p>
    )
  }

  public renderFlowReceiver() {
    return (
      <p className='notice'>
        <Trans>Click the button below to generate payment details.</Trans>
      </p>
    )
  }

  public renderFlowWechat() {
    return (
      <div>
        <div id='wechat-qrcode' />
        <p className='notice'>
          <Trans>Click the button below to generate a QR code for WeChat.</Trans>
        </p>
      </div>
    )
  }

  public render() {
    const { paymentMethod, countryCode } = this.props

    return (
      <div className='inputWrapper'>
        <label>
          <Trans>Select payment method</Trans>
        </label>
        <nav className='stripe-payment-methods'>
          <ul>
            {getStripePaymentMethodsByCountryCode(countryCode).map((method) =>
              (
                <li key={method.id} className={paymentMethod && method.id === paymentMethod.id ? 'selected' : ''}>
                  <label>
                    <input
                      name='method'
                      value={method.id}
                      id={`method-${method.id}`}
                      type='radio'
                      onClick={() => this.handleMethodChange(method)}
                    />
                    <span>{method.name}</span>
                  </label>
                </li>
              )
            )}
          </ul>
        </nav>
        {this.renderSelectedMethod()}
      </div>
    )
  }

}

export default withI18n()(DonateStripePaymentMethod)
