import React, { Component } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import { connect } from 'react-redux'
import { Col, Row, Table } from 'reactstrap'
import Page from '../../Elements/Page'
import { ReactComponent as HumanFull } from '../../Common/Assets/human-full.svg'
import { ReactComponent as Donations } from '../../Common/Assets/donations.svg'
import PageHeading from '../../Elements/Page/PageHeading'
import DashboardInfoRow from '../../Components/DashboardInfoRow'
import GenericTableContainer from '../../Components/GenericTableContainer'
import { getEventFinancialDetails, getEventImpactKPIs } from '../../Core/EventDetails/EventDetails.actions'
import { getDonationsPerCurrency, getDonorCountries } from '../../Core/Donations/Donations.actions'
import { OwnPropsDashboardPage } from '../InvestorDashboardPage/types'
import { AppState } from '../../Core/store'
import {
  getActiveSaleCycle,
  getDonationsPerCountryForChart,
  getDonationsPerCurrencyForChart,
  getDonorsProgress,
  getPowerProgress, getEmissionsProgress, getPeoplePoweredProgress
} from '../../Core/selectors'
import { createLoadingSelector } from '../../Core/API/Loading.reducer'
import {
  GET_EVENT_FINANCIAL_DETAILS_REQUEST,
  GET_EVENT_IMPACT_KPI_REQUEST
} from '../../Core/EventDetails/EventDetails.types'
import { GET_DONATIONS_PER_CURRENCY_REQUEST, GET_DONOR_COUNTRIES_REQUEST } from '../../Core/Donations/Donations.types'
import UrlUtil from '../../Common/Utils/Url.utils'
import {
  getEventLeadsCSV,
  getEventTransactions,
  getEventTransactionsCSV
} from '../../Core/Transactions/Transactions.actions'
import { GET_EVENT_TRANSACTIONS_REQUEST } from '../../Core/Transactions/Transactions.types'
import { mapLanguageCodeToFull } from '../../Common/Utils/Mapper.utils'
import './styles.scss'

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnPropsDashboardPage

const isFetchingEvent = createLoadingSelector([
  GET_EVENT_FINANCIAL_DETAILS_REQUEST,
  GET_EVENT_IMPACT_KPI_REQUEST,
  GET_DONOR_COUNTRIES_REQUEST,
  GET_DONATIONS_PER_CURRENCY_REQUEST
])

const isFetchingEventTransactions = createLoadingSelector([
  GET_EVENT_TRANSACTIONS_REQUEST
])

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEventFinancialDetails,
      getEventImpactKPIs,
      getDonorCountries,
      getDonationsPerCurrency,
      getEventTransactions,
      getEventTransactionsCSV,
      getEventLeadsCSV
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  me: state.auth.me,
  eventCurrencies: state.eventDetails.event.currencies,
  numOfInvestors: state.eventDetails.financialDetails.numOfInvestors,
  hardCap: state.eventDetails.event.hardCap,
  collectedBaseCurrency: state.eventDetails.financialDetails.collectedBaseCurrency,
  impactKPIs: state.eventDetails.impactKPIs,
  donorsProgress: getDonorsProgress(state),
  powerProgress: getPowerProgress(state),
  emissionsProgress: getEmissionsProgress(state),
  peoplePoweredProgress: getPeoplePoweredProgress(state),
  donationsPerCountryForChart: getDonationsPerCountryForChart(state),
  donationsPerCurrencyForChart: getDonationsPerCurrencyForChart(state),
  activeSaleCycle: getActiveSaleCycle(state),
  eventTransactions: state.transactions.eventTransactions,
  isFetchingEvent: isFetchingEvent(state),
  isFetchingEventTransactions: isFetchingEventTransactions(state)
})

const URL_QUERY_PARAMS = {
  PAGE_OFFSET: 'offset',
  PAGE_LIMIT: 'limit'
}

class IssuerDashboardPage extends Component<Props> {

  private defaultOffset = {
    name: URL_QUERY_PARAMS.PAGE_OFFSET,
    value: '0'
  }

  private defaultLimit = {
    name: URL_QUERY_PARAMS.PAGE_LIMIT,
    value: '25'
  }

  public async componentDidMount(): Promise<void> {
    const eventId = this.props.match.params.eventId

    const urlUtil = new UrlUtil(this.props.history, this.props.location)
    const offsetQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_OFFSET)
    const limitQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_LIMIT)

    // tslint:disable-next-line:radix
    const offset = parseInt(offsetQueryParam ? offsetQueryParam.value : this.defaultOffset.value)

    // tslint:disable-next-line:radix
    const limit = parseInt(limitQueryParam ? limitQueryParam.value : this.defaultLimit.value)

    await Promise.all([
      this.props.getEventFinancialDetails(eventId),
      this.props.getEventImpactKPIs(eventId),
      this.props.getDonorCountries(eventId),
      this.props.getDonationsPerCurrency(eventId),
      this.fetchEventTransactions(offset, limit)
    ])
  }

  public fetchEventTransactions = async (offset?: number, limit?: number) => {
    const eventId = this.props.match.params.eventId
    await this.props.getEventTransactions(eventId, offset, limit)
  }

  public handlePageChange = async (offset: number, limit: number) => {
    await this.fetchEventTransactions(offset, limit)
  }

  public handlePaginationSetLimit = async (offset: number, limit: number) => {
    await this.fetchEventTransactions(offset, limit)
  }

  public exportDonationsCSV = async () => {
    const eventId = this.props.match.params.eventId
    await this.props.getEventTransactionsCSV(eventId)
  }

  public exportLeadsCSV = async () => {
    const eventId = this.props.match.params.eventId
    await this.props.getEventLeadsCSV(eventId)
  }

  public render() {
    const {
      numOfInvestors,
      hardCap,
      collectedBaseCurrency,
      impactKPIs,
      donorsProgress,
      powerProgress,
      emissionsProgress,
      peoplePoweredProgress,
      donationsPerCountryForChart,
      donationsPerCurrencyForChart,
      activeSaleCycle,
      eventTransactions,
      isFetchingEventTransactions,
      isFetchingEvent
    } = this.props

    // Page URL Query params.
    const urlUtil = new UrlUtil(this.props.history, this.props.location)
    const offsetQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_OFFSET)
    const limitQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_LIMIT)

    return (
      <Page className='issuerDashboardPage'>
        <PageHeading>
          #connect2evolve <Trans description={'#connect2evolve project dashboard'}>project dashboard</Trans>
        </PageHeading>

        <DashboardInfoRow
          isLoading={isFetchingEvent}
          activeSaleCycle={activeSaleCycle}
          totalDonatedAmount={collectedBaseCurrency}
          hardCap={hardCap ? hardCap : '0'}
          numberOfDonors={numOfInvestors}
          impactKPIs={impactKPIs}
          donorsProgress={donorsProgress}
          powerProgress={powerProgress}
          emissionsProgress={emissionsProgress}
          peoplePoweredProgress={peoplePoweredProgress}
          donationsPerCountryForChart={donationsPerCountryForChart}
          donationsPerCurrencyForChart={donationsPerCurrencyForChart}
          enableDonateFeature={false}
        />

        <Row className='donationsTableWrapper'>
          <Col>
            <div className='tableTitleWrapper'>
              <div className='tableTitle'>
                <Trans>Donations</Trans>
              </div>
              <div className='d-flex flex-sm-row flex-column'>
                {eventTransactions.transactions.length > 0 ?
                  <div className='exportCSV' onClick={this.exportDonationsCSV}>
                    <div className='svgWrapper'>
                      <Donations/>
                    </div>
                    <Trans>Donations CSV</Trans>
                  </div>
                  : null
                }

                <div className='exportCSV mt-3 mt-sm-0' onClick={this.exportLeadsCSV}>
                  <div className='svgWrapper'>
                    <HumanFull fill={'#2387AA'}/>
                  </div>
                  <Trans>Leads list CSV</Trans>
                </div>
              </div>
            </div>

            <GenericTableContainer
              className='tableContainer'
              isLoading={isFetchingEventTransactions}
              pagination={{
                offsetQueryParam: offsetQueryParam ? offsetQueryParam : this.defaultOffset,
                limitQueryParam: limitQueryParam ? limitQueryParam : this.defaultLimit,
                options: {
                  limit: eventTransactions.pagination.limit,
                  offset: eventTransactions.pagination.offset,
                  count: eventTransactions.pagination.count
                }
              }}
              onPageChange={this.handlePageChange}
              onSetNumberOfRecords={this.handlePaginationSetLimit}
              emptyState={!eventTransactions.transactions.length}
              render={() => (
                <Table responsive={true}>
                  <thead>
                    <tr className='text-right'>
                      <th className='text-left'>
                        <Trans>User Email</Trans>
                      </th>
                      <th className='text-left'>
                        <Trans>Language</Trans>
                      </th>
                      <th className='text-left'>
                        <Trans>Date</Trans>/
                        <br />
                        <Trans>Status</Trans>
                      </th>
                      <th>
                        <Trans>Donated<br/>amount</Trans>
                      </th>
{/*
                      <th>
                        <Trans>Value<br/>in EUR</Trans>
                      </th>
*/}
                      <th>
                        <Trans>Est. power<br/>produced</Trans>
                      </th>
                      <th>
                        <Trans>Reduced CO₂ emissions</Trans>
                      </th>
                      <th>
                        <Trans>People<br/>powered</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventTransactions.transactions.map((transaction) => {

                      return (
                        <tr key={transaction.transactionId} className='text-right'>
                          <td className='text-left'>{transaction.email ? transaction.email : '-'}</td>
                          <td className='text-left'>{transaction.userLanguage ? mapLanguageCodeToFull(transaction.userLanguage) : '-'}</td>
                          {/* tslint:disable-next-line:radix */}
                          <td className='text-left'>
                            {moment.unix(parseInt(transaction.transactionTime, 10)).format('DD/MM/YYYY')}
                            <div>{transaction.status}</div>
                          </td>
                          <td>
                            <b className='font-weight-bold'>
                              {new BigNumber(transaction.baseCurrencyAmount).toFormat(2)} EUR</b>
                              {transaction.amountCurrency !== 'EUR' ?
                                <div>
                                  {new BigNumber(transaction.amount).toFormat(2)} {transaction.amountCurrency}
                                </div>
                                : null
                              }
                              <div>
                                <Trans>fee</Trans>:{' '}
                                {transaction.status === 'Completed' ?
                                  <span>
                                      {new BigNumber(transaction.fee).toFormat(2)}
                                    </span>
                                  :
                                  <span>N/A</span>
                                }
                              </div>
                          </td>
                          <td>{new BigNumber(transaction.estimatedPower).toFormat(2)} kWh</td>
                          <td>{new BigNumber(transaction.reducedCO2Lifetime).toFormat(2)} kg</td>
                          <td>{new BigNumber(transaction.peoplePowered).toFormat(2)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              )}
            />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuerDashboardPage)
