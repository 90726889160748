import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../Core/store'
import { getMe } from '../../Core/Auth/Auth.actions'
import Spinner from '../../Elements/Spinner'
import { setLanguage } from '../../Core/UserEventSettings/UserEventSettings.actions'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMe,
      setLanguage
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  me: state.auth.me,
  loggedIn: state.auth.loggedIn
})

class EventRedirectPage extends Component<Props> {

  public state = {
    isLoading: true
  }

  public async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    const eventId = params.get('eventId') || undefined
    const language = params.get('language') as SUPPORTED_LANGUAGES | null

    if (language && eventId) {
      await this.props.setLanguage(eventId, language)
    }

    await this.props.getMe(eventId)

    this.setState({
      isLoading: false
    })
  }

  public render() {

    const {
      me,
      loggedIn
    } = this.props

    if (this.state.isLoading) {
      return <Spinner transparentBackground={true}/>
    }

    if (loggedIn) {
      if (me.eventId) {
        if (me.issuer) {
          return (
            <Redirect to={`/event/${me.eventId}/dashboard/issuer`}/>
          )
        }

        if (me.investor) {
          return (
            <Redirect to={`/event/${me.eventId}/dashboard/donor`}/>
          )
        }

        if (!me.issuer && !me.investor) {
          return (
            <Redirect to={'/unauthorized'}/>
          )
        }
      }
    }

    return  (
      <Redirect to={'/not-found'} />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRedirectPage)
