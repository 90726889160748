import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import { LanguageContext } from '../../HOC/LanguageContext'
import './styles.scss'

const TableEmptyState: FC = () => {

  return (
    <LanguageContext.Consumer>
      {({selectedLanguage}) => (
        <div className='transactionEmptyState'>
          <Trans render='span' className='color-1 font-weight-normal fs-14 py-3'>
            Your donations will be displayed here. <br/>
            Learn more about the #connect2evolve project and how your donation will help the community of Bacco Ndieme in Senegal.
          </Trans>
          <a className='siemens' href={`https://www.siemens-energy.com/global/en/news/magazine/2020/blockchain-africa-connect-2-evolve-energy.html`} target='_blank' rel='noopener noreferrer'>
            <Trans>Learn more about #connect2evolve</Trans>
          </a>
        </div>
      )}
    </LanguageContext.Consumer>
  )
}

export default TableEmptyState
