import { Action } from 'redux'
import { CurrencyEntity, UserEventResponseEntity } from '../API/APIResponse.types'

export const GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST = 'GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST'
export const GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS = 'GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS'
export const GET_USER_EVENT_INVESTMENT_DETAILS_ERROR = 'GET_USER_EVENT_INVESTMENT_DETAILS_ERROR'

export const GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST = 'GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST'
export const GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS = 'GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS'
export const GET_USER_EVENT_SUPPORTED_CURRENCIES_ERROR = 'GET_USER_EVENT_SUPPORTED_CURRENCIES_ERROR'

export interface UserEventDetailsState {
  investmentDetails: Partial<UserEventResponseEntity>
  supportedCurrencies: CurrencyEntity[]
}

export interface GetUserEventInvestmentDetailsSuccess extends Action<typeof GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS> {
  payload: UserEventResponseEntity
}

export interface GetUserEventSupportedCurrenciesSuccess extends Action<typeof GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS> {
  payload: {
    supportedCurrencies: CurrencyEntity[]
  }
}

export type UserEventDetailsActionTypes =
  GetUserEventInvestmentDetailsSuccess |
  GetUserEventSupportedCurrenciesSuccess
