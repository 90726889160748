import React, { MouseEvent, FC } from 'react'
import './styles.scss'

interface Props {
  page: number | string
  label: string | number
  changePage?: (page: string | number) => void
  className?: string
}

const NavigationElement: FC<Props> = (props) => {
  const {
    page,
    changePage,
    className = '',
    label
  } = props

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()

    if (changePage) {
      changePage(page)
    }
  }

  return (
    <button
      className={`linkButton ${className}`}
      onClick={handleClick}
    >
      {label}
    </button>
  )
}

export default NavigationElement
