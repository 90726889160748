import React, { FC } from 'react'
import './style.scss'

interface Props {
  size?: 'lg' | 'md' | 'sm'
  transparentBackground?: boolean
  fullPage?: boolean
  className?: string
}

const Spinner: FC<Props> = (props) => {
  const {
    size = 'lg',
    transparentBackground = false,
    fullPage = false,
    className = ''
  } = props

  const style = {
    backgroundColor: ''
  }

  if (transparentBackground) {
    style.backgroundColor = 'transparent'
  }

  const fullPageClass = fullPage ? 'fullPage' : ''

  return (
    <div className={`spinnerContainer ${fullPageClass} ${className}`}>
      <div className={`spinner size-${size}`}/>
      <div className='backdrop' style={{...style}}/>
    </div>
  )
}

export default Spinner
