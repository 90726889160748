import { ThunkResult } from '../types'
import {
  GET_ME_ERROR,
  GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GetMeSuccess,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  LogoutUserSuccess
} from './Auth.types'
import AuthService from '../../Services/Auth.service'
import { MeResponseEntity } from '../API/APIResponse.types'
import { setDisplayCurrencySuccess, setLanguageSuccess } from '../UserEventSettings/UserEventSettings.actions'
import UserEventService from '../../Services/UserEvent.service'
import { NotFoundError } from '../../Common/Utils/Error.utils'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'
import Config from '../../Common/Config'

export function logout(): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const me = getState().auth.me
      const response = await AuthService.getMe(me.eventId)
      const { settings } = response.result
      dispatch(getMeSuccess(response.result))
      dispatch({type: LOGOUT_USER_REQUEST})

      if (me.id) {
        // await AuthService.logout()
      }

      dispatch(logoutSuccess())

      window.location.replace(`${Config.ONBOARDING_BASE_URL}/logout?language=${(settings && settings.LANGUAGE) || 'en'}`)
    } catch (e) {
      console.error(e)

      dispatch({type: LOGOUT_USER_ERROR})
    }
  }
}

export function logoutSuccess(): LogoutUserSuccess {
  return {
    type: LOGOUT_USER_SUCCESS
  }
}

export function getMe(eventId?: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_ME_REQUEST})

      const response = await AuthService.getMe(eventId)
      dispatch(getMeSuccess(response.result))

      const userSettings = response.result.settings

      if (userSettings) {
        if (userSettings.LANGUAGE) {
          dispatch(setLanguageSuccess(userSettings.LANGUAGE))
        }
        if (userSettings.DISPLAY_CURRENCY) {
          dispatch(setDisplayCurrencySuccess(userSettings.DISPLAY_CURRENCY))
        }
      } else {
        try {
          const localStorageLanguage = UserEventService.getLanguage()
          dispatch(setLanguageSuccess(localStorageLanguage))
        } catch (error) {
          if (error instanceof NotFoundError) {
            dispatch(setLanguageSuccess(SUPPORTED_LANGUAGES.en))
          } else {
            console.error(error)
          }
        }
      }

    } catch (error) {
      console.error(error)

      dispatch({type: GET_ME_ERROR})
    }
  }
}

export function getMeSuccess(me: MeResponseEntity): GetMeSuccess {
  return {
    type: GET_ME_SUCCESS,
    payload: me
  }
}
