import React, { Component, ReactNode } from 'react'
import './styles.scss'

interface Props {
  label: string | ReactNode
  value: string | ReactNode
  className?: string
}

class InfoCell extends Component<Props> {

  public static defaultProps = {
    className: ''
  }

  public render() {
    const {
      label,
      value,
      className
    } = this.props

    return (
      <div className={`infoCell ${className}`}>
        <div className='infoLabel'>
          {label}
        </div>

        <div className='infoValue'>
          {value}
        </div>
      </div>
    )
  }
}

export default InfoCell
