import {
  GET_USER_EVENT_INVESTMENT_DETAILS_ERROR,
  GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST,
  GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS,
  GET_USER_EVENT_SUPPORTED_CURRENCIES_ERROR,
  GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST, GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS,
  GetUserEventInvestmentDetailsSuccess, GetUserEventSupportedCurrenciesSuccess
} from './UserEventDetails.types'
import UserEventService from '../../Services/UserEvent.service'
import { ThunkResult } from '../types'
import { CurrencyEntity, UserEventResponseEntity } from '../API/APIResponse.types'

/**
 * @description Get user investment details for event
 * @param userId
 * @param eventId
 */
export function getUserEventInvestmentDetails(userId: string, eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST})

      const response = await UserEventService.getUserEvent(userId, eventId)
      dispatch(getUserEventInvestmentDetailsSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_USER_EVENT_INVESTMENT_DETAILS_ERROR})
      console.error(error)
    }
  }
}

export function getUserEventInvestmentDetailsSuccess(userEventInvestmentDetails: UserEventResponseEntity): GetUserEventInvestmentDetailsSuccess {
  return {
    type: GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS,
    payload: userEventInvestmentDetails
  }
}

/**
 * @description Get user investment details for event
 * @param userId
 * @param eventId
 */
export function getUserEventSupportedCurrencies(userId: string, eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST})

      const response = await UserEventService.getUserSupportedCurrencies(userId, eventId)
      dispatch(getUserEventSupportedCurrenciesSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_USER_EVENT_SUPPORTED_CURRENCIES_ERROR})
      console.error(error)
    }
  }
}

export function getUserEventSupportedCurrenciesSuccess(supportedCurrencies: CurrencyEntity[]): GetUserEventSupportedCurrenciesSuccess {
  return {
    type: GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS,
    payload: {
      supportedCurrencies
    }
  }
}
