import { Reducer } from 'redux'
import {
  GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS, GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS,
  UserEventDetailsActionTypes,
  UserEventDetailsState
} from './UserEventDetails.types'

const initialState: UserEventDetailsState = {
  investmentDetails: {},
  supportedCurrencies: []
}

export const userEventDetailsReducer: Reducer<UserEventDetailsState, UserEventDetailsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_EVENT_INVESTMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        investmentDetails: {
          ...action.payload
        }
      }
    }

    case GET_USER_EVENT_SUPPORTED_CURRENCIES_SUCCESS: {
      const { supportedCurrencies } = action.payload

      return {
        ...state,
        supportedCurrencies
      }
    }

    default: {
      return state
    }
  }
}
