import React, { FC } from 'react'

const DialogHeader: FC = (props) => {

  return (
    <div className='dialogHeader'>
      {props.children}
    </div>
  )
}

export default DialogHeader
