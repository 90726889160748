import { t } from '@lingui/macro'
import {I18n} from '@lingui/core/i18n'

export const countries = (i18n: I18n): {[key: string]: string} => {
  return {
    AF: i18n._(t`Afghanistan`),
    AL: i18n._(t`Albania`),
    DZ: i18n._(t`Algeria`),
    AS: i18n._(t`American Samoa`),
    AD: i18n._(t`Andorra`),
    AO: i18n._(t`Angola`),
    AI: i18n._(t`Anguilla`),
    AQ: i18n._(t`Antarctica`),
    AG: i18n._(t`Antigua and Barbuda`),
    AR: i18n._(t`Argentina`),
    AM: i18n._(t`Armenia`),
    AW: i18n._(t`Aruba`),
    AU: i18n._(t`Australia`),
    AT: i18n._(t`Austria`),
    AZ: i18n._(t`Azerbaijan`),
    BS: i18n._(t`Bahamas`),
    BH: i18n._(t`Bahrain`),
    BD: i18n._(t`Bangladesh`),
    BB: i18n._(t`Barbados`),
    BY: i18n._(t`Belarus`),
    BE: i18n._(t`Belgium`),
    BZ: i18n._(t`Belize`),
    BJ: i18n._(t`Benin`),
    BM: i18n._(t`Bermuda`),
    BT: i18n._(t`Bhutan`),
    BO: i18n._(t`Bolivia`),
    BA: i18n._(t`Bosnia and Herzegovina`),
    BW: i18n._(t`Botswana`),
    BV: i18n._(t`Bouvet Island`),
    BR: i18n._(t`Brazil`),
    IO: i18n._(t`British Indian Ocean Territory`),
    BN: i18n._(t`Brunei Darussalam`),
    BG: i18n._(t`Bulgaria`),
    BF: i18n._(t`Burkina Faso`),
    BI: i18n._(t`Burundi`),
    KH: i18n._(t`Cambodia`),
    CM: i18n._(t`Cameroon`),
    CA: i18n._(t`Canada`),
    CV: i18n._(t`Cape Verde`),
    KY: i18n._(t`Cayman Islands`),
    CF: i18n._(t`Central African Republic`),
    TD: i18n._(t`Chad`),
    CL: i18n._(t`Chile`),
    CN: i18n._(t`China`),
    CX: i18n._(t`Christmas Island`),
    CC: i18n._(t`Cocos (Keeling) Islands`),
    CO: i18n._(t`Colombia`),
    KM: i18n._(t`Comoros`),
    CG: i18n._(t`Congo`),
    CD: i18n._(t`Congo, the Democratic Republic of the`),
    CK: i18n._(t`Cook Islands`),
    CR: i18n._(t`Costa Rica`),
    CI: i18n._(t`Cote D'Ivoire`),
    HR: i18n._(t`Croatia`),
    CU: i18n._(t`Cuba`),
    CY: i18n._(t`Cyprus`),
    CZ: i18n._(t`Czech Republic`),
    DK: i18n._(t`Denmark`),
    DJ: i18n._(t`Djibouti`),
    DM: i18n._(t`Dominica`),
    DO: i18n._(t`Dominican Republic`),
    EC: i18n._(t`Ecuador`),
    EG: i18n._(t`Egypt`),
    SV: i18n._(t`El Salvador`),
    GQ: i18n._(t`Equatorial Guinea`),
    ER: i18n._(t`Eritrea`),
    EE: i18n._(t`Estonia`),
    ET: i18n._(t`Ethiopia`),
    FK: i18n._(t`Falkland Islands (Malvinas)`),
    FO: i18n._(t`Faroe Islands`),
    FJ: i18n._(t`Fiji`),
    FI: i18n._(t`Finland`),
    FR: i18n._(t`France`),
    GF: i18n._(t`French Guiana`),
    PF: i18n._(t`French Polynesia`),
    TF: i18n._(t`French Southern Territories`),
    GA: i18n._(t`Gabon`),
    GM: i18n._(t`Gambia`),
    GE: i18n._(t`Georgia`),
    DE: i18n._(t`Germany`),
    GH: i18n._(t`Ghana`),
    GI: i18n._(t`Gibraltar`),
    GR: i18n._(t`Greece`),
    GL: i18n._(t`Greenland`),
    GD: i18n._(t`Grenada`),
    GP: i18n._(t`Guadeloupe`),
    GU: i18n._(t`Guam`),
    GT: i18n._(t`Guatemala`),
    GN: i18n._(t`Guinea`),
    GW: i18n._(t`Guinea-Bissau`),
    GY: i18n._(t`Guyana`),
    HT: i18n._(t`Haiti`),
    HM: i18n._(t`Heard Island and Mcdonald Islands`),
    VA: i18n._(t`Holy See (Vatican City State)`),
    HN: i18n._(t`Honduras`),
    HK: i18n._(t`Hong Kong`),
    HU: i18n._(t`Hungary`),
    IS: i18n._(t`Iceland`),
    IN: i18n._(t`India`),
    ID: i18n._(t`Indonesia`),
    IR: i18n._(t`Iran, Islamic Republic of`),
    IQ: i18n._(t`Iraq`),
    IE: i18n._(t`Ireland`),
    IL: i18n._(t`Israel`),
    IT: i18n._(t`Italy`),
    JM: i18n._(t`Jamaica`),
    JP: i18n._(t`Japan`),
    JO: i18n._(t`Jordan`),
    KZ: i18n._(t`Kazakhstan`),
    KE: i18n._(t`Kenya`),
    KI: i18n._(t`Kiribati`),
    KP: i18n._(t`North Korea`),
    KR: i18n._(t`South Korea`),
    KW: i18n._(t`Kuwait`),
    KG: i18n._(t`Kyrgyzstan`),
    LA: i18n._(t`Lao People's Democratic Republic`),
    LV: i18n._(t`Latvia`),
    LB: i18n._(t`Lebanon`),
    LS: i18n._(t`Lesotho`),
    LR: i18n._(t`Liberia`),
    LY: i18n._(t`Libya`),
    LI: i18n._(t`Liechtenstein`),
    LT: i18n._(t`Lithuania`),
    LU: i18n._(t`Luxembourg`),
    MO: i18n._(t`Macao`),
    MG: i18n._(t`Madagascar`),
    MW: i18n._(t`Malawi`),
    MY: i18n._(t`Malaysia`),
    MV: i18n._(t`Maldives`),
    ML: i18n._(t`Mali`),
    MT: i18n._(t`Malta`),
    MH: i18n._(t`Marshall Islands`),
    MQ: i18n._(t`Martinique`),
    MR: i18n._(t`Mauritania`),
    MU: i18n._(t`Mauritius`),
    YT: i18n._(t`Mayotte`),
    MX: i18n._(t`Mexico`),
    FM: i18n._(t`Micronesia, Federated States of`),
    MD: i18n._(t`Moldova, Republic of`),
    MC: i18n._(t`Monaco`),
    MN: i18n._(t`Mongolia`),
    MS: i18n._(t`Montserrat`),
    MA: i18n._(t`Morocco`),
    MZ: i18n._(t`Mozambique`),
    MM: i18n._(t`Myanmar`),
    NA: i18n._(t`Namibia`),
    NR: i18n._(t`Nauru`),
    NP: i18n._(t`Nepal`),
    NL: i18n._(t`Netherlands`),
    NC: i18n._(t`New Caledonia`),
    NZ: i18n._(t`New Zealand`),
    NI: i18n._(t`Nicaragua`),
    NE: i18n._(t`Niger`),
    NG: i18n._(t`Nigeria`),
    NU: i18n._(t`Niue`),
    NF: i18n._(t`Norfolk Island`),
    MK: i18n._(t`North Macedonia, Republic of`),
    MP: i18n._(t`Northern Mariana Islands`),
    NO: i18n._(t`Norway`),
    OM: i18n._(t`Oman`),
    PK: i18n._(t`Pakistan`),
    PW: i18n._(t`Palau`),
    PS: i18n._(t`Palestinian Territory, Occupied`),
    PA: i18n._(t`Panama`),
    PG: i18n._(t`Papua New Guinea`),
    PY: i18n._(t`Paraguay`),
    PE: i18n._(t`Peru`),
    PH: i18n._(t`Philippines`),
    PN: i18n._(t`Pitcairn`),
    PL: i18n._(t`Poland`),
    PT: i18n._(t`Portugal`),
    PR: i18n._(t`Puerto Rico`),
    QA: i18n._(t`Qatar`),
    RE: i18n._(t`Reunion`),
    RO: i18n._(t`Romania`),
    RU: i18n._(t`Russian Federation`),
    RW: i18n._(t`Rwanda`),
    SH: i18n._(t`Saint Helena`),
    KN: i18n._(t`Saint Kitts and Nevis`),
    LC: i18n._(t`Saint Lucia`),
    PM: i18n._(t`Saint Pierre and Miquelon`),
    VC: i18n._(t`Saint Vincent and the Grenadines`),
    WS: i18n._(t`Samoa`),
    SM: i18n._(t`San Marino`),
    ST: i18n._(t`Sao Tome and Principe`),
    SA: i18n._(t`Saudi Arabia`),
    SN: i18n._(t`Senegal`),
    SC: i18n._(t`Seychelles`),
    SL: i18n._(t`Sierra Leone`),
    SG: i18n._(t`Singapore`),
    SK: i18n._(t`Slovakia`),
    SI: i18n._(t`Slovenia`),
    SB: i18n._(t`Solomon Islands`),
    SO: i18n._(t`Somalia`),
    ZA: i18n._(t`South Africa`),
    GS: i18n._(t`South Georgia and the South Sandwich Islands`),
    ES: i18n._(t`Spain`),
    LK: i18n._(t`Sri Lanka`),
    SD: i18n._(t`Sudan`),
    SR: i18n._(t`Suriname`),
    SJ: i18n._(t`Svalbard and Jan Mayen`),
    SZ: i18n._(t`Swaziland`),
    SE: i18n._(t`Sweden`),
    CH: i18n._(t`Switzerland`),
    SY: i18n._(t`Syrian Arab Republic`),
    TW: i18n._(t`Taiwan`),
    TJ: i18n._(t`Tajikistan`),
    TZ: i18n._(t`Tanzania, United Republic of`),
    TH: i18n._(t`Thailand`),
    TL: i18n._(t`Timor-Leste`),
    TG: i18n._(t`Togo`),
    TK: i18n._(t`Tokelau`),
    TO: i18n._(t`Tonga`),
    TT: i18n._(t`Trinidad and Tobago`),
    TN: i18n._(t`Tunisia`),
    TR: i18n._(t`Turkey`),
    TM: i18n._(t`Turkmenistan`),
    TC: i18n._(t`Turks and Caicos Islands`),
    TV: i18n._(t`Tuvalu`),
    UG: i18n._(t`Uganda`),
    UA: i18n._(t`Ukraine`),
    AE: i18n._(t`United Arab Emirates`),
    GB: i18n._(t`United Kingdom`),
    US: i18n._(t`United States`),
    UM: i18n._(t`United States Minor Outlying Islands`),
    UY: i18n._(t`Uruguay`),
    UZ: i18n._(t`Uzbekistan`),
    VU: i18n._(t`Vanuatu`),
    VE: i18n._(t`Venezuela`),
    VN: i18n._(t`Viet Nam`),
    VG: i18n._(t`Virgin Islands, British`),
    VI: i18n._(t`Virgin Islands, U.S.`),
    WF: i18n._(t`Wallis and Futuna`),
    EH: i18n._(t`Western Sahara`),
    YE: i18n._(t`Yemen`),
    ZM: i18n._(t`Zambia`),
    ZW: i18n._(t`Zimbabwe`),
    AX: i18n._(t`Åland Islands`),
    BQ: i18n._(t`Bonaire, Sint Eustatius and Saba`),
    CW: i18n._(t`Curaçao`),
    GG: i18n._(t`Guernsey`),
    IM: i18n._(t`Isle of Man`),
    JE: i18n._(t`Jersey`),
    ME: i18n._(t`Montenegro`),
    BL: i18n._(t`Saint Barthélemy`),
    MF: i18n._(t`Saint Martin (French part)`),
    RS: i18n._(t`Serbia`),
    SX: i18n._(t`Sint Maarten (Dutch part)`),
    SS: i18n._(t`South Sudan`),
    XK: i18n._(t`Kosovo`),
    OTHER: i18n._(
      /*i18n: Description: Unknown country (Country not found in our list)*/
      t`Other`
    )
  }
}
