import { Reducer } from 'redux'
import {
  GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS,
  ClaimHistoryActionTypes,
  ClaimHistoryState
} from './ClaimHistory.types'

const initialState: ClaimHistoryState = {
  userClaimHistory: {
    data: [],
    pagination: {
      offset: 0,
      limit: 0,
      count: 0
    }
  }
}

export const claimsReducer: Reducer<ClaimHistoryState, ClaimHistoryActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS: {
      const { data, pagination } = action.payload

      return {
        ...state,
        userClaimHistory: {
          data: [...data],
          pagination
        }
      }
    }

    default: {
      return state
    }
  }
}
