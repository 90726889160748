import { Action } from 'redux'
import {
  EventTransactionsResponseEntity,
  PaginationWithCount,
  TransactionResponseEntity
} from '../API/APIResponse.types'

export const GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST = 'GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST'
export const GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS = 'GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS'
export const GET_USER_TRANSACTIONS_FOR_EVENT_ERROR = 'GET_USER_TRANSACTIONS_FOR_EVENT_ERROR'

export const GET_EVENT_TRANSACTIONS_REQUEST = 'GET_EVENT_TRANSACTIONS_REQUEST'
export const GET_EVENT_TRANSACTIONS_SUCCESS = 'GET_EVENT_TRANSACTIONS_SUCCESS'
export const GET_EVENT_TRANSACTIONS_ERROR = 'GET_EVENT_TRANSACTIONS_ERROR'

export const GET_EVENT_TRANSACTIONS_CSV_REQUEST = 'GET_EVENT_TRANSACTIONS_CSV_REQUEST'
export const GET_EVENT_TRANSACTIONS_CSV_SUCCESS = 'GET_EVENT_TRANSACTIONS_CSV_SUCCESS'
export const GET_EVENT_TRANSACTIONS_CSV_ERROR = 'GET_EVENT_TRANSACTIONS_CSV_ERROR'

export const GET_EVENT_LEADS_CSV_REQUEST = 'GET_EVENT_LEADS_CSV_REQUEST'
export const GET_EVENT_LEADS_CSV_SUCCESS = 'GET_EVENT_LEADS_CSV_SUCCESS'
export const GET_EVENT_LEADS_CSV_ERROR = 'GET_EVENT_LEADS_CSV_ERROR'

export interface TransactionsState {
  userTransactions: {
    transactions: TransactionResponseEntity[],
    pagination: PaginationWithCount
  },
  eventTransactions: {
    transactions: EventTransactionsResponseEntity[],
    pagination: PaginationWithCount
  }
}

export interface GetUserTransactionsForEventSuccess extends Action<typeof GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS> {
  payload: {
    userTransactions: TransactionResponseEntity[],
    pagination: PaginationWithCount
  }
}

export interface GetEventTransactionsSuccess extends Action<typeof GET_EVENT_TRANSACTIONS_SUCCESS> {
  payload: {
    eventTransactions: EventTransactionsResponseEntity[],
    pagination: PaginationWithCount
  }
}

export interface GetEventTransactionsCSVSuccess extends Action<typeof GET_EVENT_TRANSACTIONS_CSV_SUCCESS> {}
export interface GetEventLeadsCSVSuccess extends Action<typeof GET_EVENT_LEADS_CSV_SUCCESS> {}

export type TransactionsActionTypes =
  GetUserTransactionsForEventSuccess |
  GetEventTransactionsSuccess |
  GetEventTransactionsCSVSuccess |
  GetEventLeadsCSVSuccess
