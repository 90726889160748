import { UserSettingsType } from '../types'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'

export interface Response<T> {
  result: T
}

export interface PaginatedResponse<T>
  extends Response<T>,
    PaginationWithCount {}

export interface Pagination {
  offset: number
  limit: number
}

export interface PaginationWithCount extends Pagination {
  count: number
}

export interface Sort {
  sortColumn: string
  sortOrder: SortOrder
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface PagingInfo {
  sort: Sort
  pagination: Pagination
}

type CurrencyType = 'asset' | 'fiat'

export interface SaleCycleResponseEntity {
  active: boolean
  name: string
  endDate: number
  currentPrice: string
  nextPrice: string
  minInvestmentAmount: string
}

export interface CurrencyEntity {
  id: string
  symbol: string
  type: CurrencyType
  baseCurrencyValue: string
  name: string
}

export interface EventResponseEntity {
  id?: string
  name: string
  symbol: string
  baseCurrencySymbol: string
  logo: string
  status: string
  hardCap: string
  softCap: string
  saleCycles: SaleCycleResponseEntity[]
  currencies: CurrencyEntity[]
}

export interface EventFinancialResponseEntity {
  collectedBaseCurrency: string
  avgInvestment: string
  topInvestment: string
  numOfInvestors: string
}

export interface EventImpactKPIsResponseEntity {
  reducedCO2Lifetime: string
  estimatedPower: string
  peoplePowered: string
  lastReportDate: string
  lastDistributionTime: string
  numOfMeters: number
  totalEnergySupplied: number
}

export interface CountriesEventStatistics {
  country: string
  count: number
}

export interface CountriesResponseEntity {
  countries: CountriesEventStatistics[]
  total: number
}

export interface InvestmentsPerCurrency {
  currency: string
  value: string
  type: CurrencyType
}

export interface InvestmentPerCurrencyResponseEntity {
  currencies: InvestmentsPerCurrency[]
  total: string
}

export interface UserDetailsEntity {
  firstName: string
  lastName: string
  country: string
  walletAddress?: string
}

interface UserIdentity {
  mapId: string
  email: string
  id: string
}

interface UserEntity extends UserDetailsEntity, UserIdentity {
  ipfsCID: string
}

export interface MeResponseEntity extends UserEntity {
  issuer: boolean
  investor: boolean
  eventId: string
  settings?: {
    [K in UserSettingsType]?: K extends UserSettingsType.LANGUAGE
      ? SUPPORTED_LANGUAGES
      : string
  }
  event?: { [key: string]: string }
  certificate?: { [key: string]: string } // todo: actual certificate type
}

export interface TransactionResponseEntity extends SiemensContainerKPI {
  eventId: number
  transactionId: string
  transactionTime: string
  status: string
  accepted: boolean
  amount: string
  fee: string
  amountCurrency: string
  baseCurrencyAmount: string
  tokens: string
  fromAddress: string
  toAddress: string
  tagFrom: string
  tagTo: string
  txHash: string
}

export interface ClaimHistoryResponseEntity {
  eventId: number
  userId: number
  amount: string
  walletAddress: string
  transactionHash: string
  createdAt: string
}

export interface EventTransactionsResponseEntity
  extends TransactionResponseEntity {
  email?: string
  userLanguage?: string
}

export interface UserSettingsLanguageResponseEntity {
  key: UserSettingsType.LANGUAGE
  data: SUPPORTED_LANGUAGES
}

export interface UserSettingsDisplayCurrencyResponseEntity {
  key: UserSettingsType.DISPLAY_CURRENCY
  data: string
}

export interface IssuerStakeEntity {
  token: string
  baseCurrency: string
}

export interface UserEventResponseEntity {
  price: string
  symbol: string
  tokenToReceive: string
  investmentValue: string
  issuerStake?: IssuerStakeEntity
}

export interface SiemensContainerKPI {
  estimatedPower: string
  reducedCO2: string
  reducedCO2Lifetime: string
  peoplePowered: string
}

/**
 * @description siemens container KPI calculated regarding to donated amount
 */
export interface SiemensImpactCalculationResponseEntity
  extends SiemensContainerKPI {
  baseCurrencyAmount: string
}

export interface ProxyAddressResponseEntity {
  address: string
  tag?: string
}

export interface BankInstructionsResponseEntity {
  bankInfo: Array<{ title: string; values: string[] }>
  additionalInfo: Array<{ title: string; values: string[] }>
}
