import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import Dialog from '../../Elements/Dialog'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import Button from '../../Elements/Button'
import { AppState } from '../../Core/store'
import { toggleModal } from '../../Core/UI/UI.actions'
import { MODALS } from '../../Common/Constants'
import './styles.scss'

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  modals: state.ui.modals
})

class HelpAndSupportModal extends Component<Props> {

  public closeModal = () => {
    this.props.toggleModal(MODALS.HELP_AND_SUPPORT, false)
  }

  public render() {
    const {
      modals
    } = this.props

    return (
      <Dialog
        className='helpAndSupportModal'
        isOpen={!!modals[MODALS.HELP_AND_SUPPORT]}
      >
        <DialogHeader>
          <Trans>Help and support</Trans>
        </DialogHeader>

        <DialogBody>
          <Trans>
            Contact the #connect2evolve team at:<br/>
            <a
              className='siemens'
              href='mailto: connect2evolve.communications@siemens-energy.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              connect2evolve.communications@siemens-energy.com
            </a> <br/>
            with any questions about the project or your account, including GDPR and privacy-related requests.
          </Trans>
          <div className='actionButtonWrapper'>
            <Button
              onClick={this.closeModal}
              buttonType='outline'
            >
              <Trans>
                Close
              </Trans>
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpAndSupportModal)
