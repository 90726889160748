import React, { Component, ReactChild } from 'react'
import { findDOMNode } from 'react-dom'
import { Transition } from 'react-transition-group'
import { ReactComponent as Hamburger } from '../../Common/Assets/siemens-menu.svg'
import './styles.scss'

interface State {
  isOpen: boolean
}

type Props = {
  render?: (options: {closeMenu: () => void}) => ReactChild
}

class HamburgerMenu extends Component<Props, State> {

  public state = {
    isOpen: false
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  public handleClickOutside = (event: any) => {
    const domNode = findDOMNode(this)

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isOpen: false
      })
    }
  }

  public handleNavMenuClick = () => {
    this.setState({
      isOpen: false
    })
  }

  public toggleHamburger = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }))
  }

  public render() {
    const {
      render
    } = this.props

    return (
      <div className='hamburgerMeu'>
        <div className='cursorPointer' onClick={this.toggleHamburger}>
          <Hamburger/>
        </div>

        <div className='d-none d-md-block'>
          <div className={`navMenuDropdown ${this.state.isOpen ? 'open' : ''}`}>
            {render ?
              render({closeMenu: this.handleNavMenuClick})
              : this.props.children
            }
          </div>
        </div>

        <div className='d-block d-md-none'>
          <Transition timeout={{enter: 100, exit: 300}} in={this.state.isOpen}>
            {(state) => {
              return (
                <div className={`mobileNavMenuContainer animation-${state}`}>
                  <div className={`mobileNavMenu`}>
                    <span className='icon-close' onClick={this.handleNavMenuClick}/>
                    <div className='navItemList'>
                      {render ?
                        render({closeMenu: this.handleNavMenuClick})
                        : this.props.children
                      }
                    </div>
                  </div>
                  <div className='backdrop'/>
                </div>
              )
            }}
          </Transition>
        </div>
      </div>
    )
  }
}
export default HamburgerMenu
