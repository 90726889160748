import {
  SET_DISPLAY_CURRENCY_ERROR,
  SET_DISPLAY_CURRENCY_REQUEST,
  SET_DISPLAY_CURRENCY_SUCCESS,
  SET_LANGUAGE_ERROR,
  SET_LANGUAGE_REQUEST,
  SET_LANGUAGE_SUCCESS,
  SetDisplayCurrencySuccess,
  SetLanguageSuccess
} from './UserEventSettings.types'
import UserEventService from '../../Services/UserEvent.service'
import { ThunkResult } from '../types'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'

/**
 * @description Set preferred language to localStorage
 * @param language
 * @param eventId
 */
export function setLanguage(eventId: string, language: SUPPORTED_LANGUAGES): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SET_LANGUAGE_REQUEST})

      const response = await UserEventService.setLanguage(eventId, language)
      dispatch(setLanguageSuccess(response.result.data))

    } catch (error) {
      dispatch({type: SET_LANGUAGE_ERROR})
      console.error(error)
    }
  }
}

/**
 * @description Change UI language
 * @param language
 */
export function setLanguageSuccess(language: SUPPORTED_LANGUAGES): SetLanguageSuccess {
  return {
    type: SET_LANGUAGE_SUCCESS,
    payload: {
      language
    }
  }
}

export function setDisplayCurrency(eventId: string, currencyCode: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SET_DISPLAY_CURRENCY_REQUEST})

      const response = await UserEventService.setDisplayCurrency(eventId, currencyCode)
      dispatch(setDisplayCurrencySuccess(response.result.data))

    } catch (error) {
      dispatch({type: SET_DISPLAY_CURRENCY_ERROR})
      console.error(error)
    }
  }
}

/**
 * @description Change UI display currency
 * @param currencyCode
 */
export function setDisplayCurrencySuccess(currencyCode: string): SetDisplayCurrencySuccess {
  return {
    type: SET_DISPLAY_CURRENCY_SUCCESS,
    payload: {
      currencyCode
    }
  }
}
