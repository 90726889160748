import BigNumber from 'bignumber.js'
import {SelectInputOption} from '../../Elements/SelectDropdown/types'
import {CurrencyEntity} from '../../Core/API/APIResponse.types'
import {STRIPE_PAYMENT_METHODS} from '../../Common/Constants'
import {StripePaymentMethod} from '../../Core/types'

export function formatDonateAmount(amount: string, currency?: SelectInputOption<CurrencyEntity>): string {
  return `${new BigNumber(amount ? amount : 0).toFormat(2)} ${currency ? currency.value : ''}`
}

export function getStripePaymentMethodsByCountryCode(countryCode: string): StripePaymentMethod[] {
    return STRIPE_PAYMENT_METHODS.filter((method) => !method.countries || method.countries.indexOf(countryCode) !== -1)
}

export function getDefaultPaymentMethod(countryCode: string): StripePaymentMethod | undefined {
  const methods = getStripePaymentMethodsByCountryCode(countryCode)
  if (methods.length > 0) {
    return methods[0]
  } else {
    return undefined
  }
}
