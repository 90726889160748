import { combineReducers } from 'redux'
import { reducer as toastrReducer, ToastrState } from 'react-redux-toastr'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { userEventSettingsReducer } from './UserEventSettings/UserEventSettings.reducer'
import { eventDetailsReducer } from './EventDetails/EventDetails.reducer'
import { donationsReducer } from './Donations/Donations.reducer'
import { loadingReducer, LoadingState } from './API/Loading.reducer'
import { authReducer } from './Auth/Auth.reducer'
import { uiReducer } from './UI/UI.reducer'
import { UserEventSettingsState } from './UserEventSettings/UserEventSettings.types'
import { EventDetailsState } from './EventDetails/EventDetails.types'
import { DonationsState } from './Donations/Donations.types'
import { AuthState } from './Auth/Auth.types'
import { UIState } from './UI/UI.types'
import { userEventDetailsReducer } from './UserEventDetails/UserEventDetails.reducer'
import { UserEventDetailsState } from './UserEventDetails/UserEventDetails.types'
import { transactionsReducer } from './Transactions/Transactions.reducer'
import { TransactionsState } from './Transactions/Transactions.types'
import { claimsReducer } from './ClaimHistory/ClaimHistory.reducer'
import { ClaimHistoryState } from './ClaimHistory/ClaimHistory.types'

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  loading: loadingReducer,
  userEventSettings: userEventSettingsReducer,
  userEventDetails: userEventDetailsReducer,
  eventDetails: eventDetailsReducer,
  donations: donationsReducer,
  auth: authReducer,
  transactions: transactionsReducer,
  claims: claimsReducer,
  ui: uiReducer
})

export interface State {
  router: RouterState,
  toastr: ToastrState,
  loading: LoadingState,
  userEventSettings: UserEventSettingsState,
  userEventDetails: UserEventDetailsState,
  eventDetails: EventDetailsState,
  donations: DonationsState,
  auth: AuthState,
  transactions: TransactionsState,
  claims: ClaimHistoryState,
  ui: UIState
}

export default rootReducer
