import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Catalog, setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { AppState } from '../Core/store'
import Spinner from '../Elements/Spinner'
import { SUPPORTED_LANGUAGES } from '../Common/Constants'
import { defaultLanguage, LanguageContext } from './LanguageContext'

type Props = ReturnType<typeof mapStateToProps>

type State = {
  catalogs: {
    [K in SUPPORTED_LANGUAGES]?: any
  }
  isLoading: boolean
}

const mapStateToProps = (state: AppState) => ({
  language: state.userEventSettings.language ? state.userEventSettings.language : defaultLanguage
})

export const i18n = setupI18n()

class I18nLoader extends Component<Props, State> {

  public state: State = {
    catalogs: {},
    isLoading: true
  }

  public async componentDidMount() {
    await this.loadCatalog(this.props.language)
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    const { language } = nextProps
    const { catalogs } = nextState

    if (language !== this.props.language && !catalogs[language]) {
      this.loadCatalog(language)
      return false
    }

    return true
  }

  public loadCatalog = async (language: SUPPORTED_LANGUAGES) => {
    let catalog: Catalog
    try {
      catalog = await import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
        `../Locales/${language}/messages.js`)
    } catch (e) {
      catalog = await import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
        `../Locales/en/messages.js`)
    }

    i18n.load({[language]: catalog})
    i18n.activate(language)

    this.setState((state) => ({
      catalogs: {
        ...state.catalogs,
        [language]: catalog
      },
      isLoading: false
    }))
  }

  public render() {
    const { children, language } = this.props
    const { isLoading } = this.state

    // Skip rendering when catalog isn't loaded.
    if (isLoading) {
      return <Spinner transparentBackground={true}/>
    }

    return (
      <I18nProvider language={language} i18n={i18n}>
        <LanguageContext.Provider value={{selectedLanguage: language}}>
          {children}
        </LanguageContext.Provider>
      </I18nProvider>

    )
  }
}

export default connect(mapStateToProps)(I18nLoader)
