import React, { FC } from 'react'
import './styles.scss'

interface Props {
  pagingNumber: number
  selectedOption: number | string
  setNumberOfRecords: (pagingNumber: number) => void
}

const PagingNumber: FC<Props> = (props) => {

  const {
    setNumberOfRecords,
    pagingNumber,
    selectedOption
  } = props

  const handleClick = () => {
    setNumberOfRecords(pagingNumber)
  }

  const divClassName = `mx-1 cursorPointer pageNum ${selectedOption === pagingNumber ? 'selected' : ''}`

  return (
    <div className={divClassName} onClick={handleClick}>{pagingNumber}</div>
  )
}

export default PagingNumber
