import { Action } from 'redux'
import {
  PaginationWithCount,
  ClaimHistoryResponseEntity
} from '../API/APIResponse.types'

export const GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST = 'GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST'
export const GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS = 'GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS'
export const GET_USER_CLAIM_HISTORY_FOR_EVENT_ERROR = 'GET_USER_CLAIM_HISTORY_FOR_EVENT_ERROR'

export interface ClaimHistoryState {
  userClaimHistory: {
    data: ClaimHistoryResponseEntity[],
    pagination: PaginationWithCount
  }
}

export interface GetUserClaimHistoryForEventSuccess extends Action<typeof GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS> {
  payload: {
    data: ClaimHistoryResponseEntity[],
    pagination: PaginationWithCount
  }
}

export type ClaimHistoryActionTypes = GetUserClaimHistoryForEventSuccess
