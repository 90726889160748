import {
  CALCULATE_DONATION_IMPACT_ERROR,
  CALCULATE_DONATION_IMPACT_REQUEST, CALCULATE_DONATION_IMPACT_SUCCESS, CalculateDonationImpactSuccess,
  GET_EVENT_ERROR, GET_EVENT_FINANCIAL_DETAILS_ERROR, GET_EVENT_FINANCIAL_DETAILS_REQUEST,
  GET_EVENT_FINANCIAL_DETAILS_SUCCESS, GET_EVENT_IMPACT_KPI_ERROR, GET_EVENT_IMPACT_KPI_REQUEST,
  GET_EVENT_IMPACT_KPI_SUCCESS, GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GetEventFinancialDetailsSuccess,
  GetEventImpactKPISuccess,
  GetEventSuccess
} from './EventDetails.types'
import { ThunkResult } from '../types'
import EventService from '../../Services/Event.service'
import {
  EventFinancialResponseEntity,
  EventImpactKPIsResponseEntity,
  EventResponseEntity, SiemensImpactCalculationResponseEntity
} from '../API/APIResponse.types'

/**
 * @description Thunk for getting event details
 * @param eventId
 */
export function getEvent(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_REQUEST})

      const response = await EventService.getEvent(eventId)
      dispatch(getEventSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_EVENT_ERROR})
      console.error(error)
    }
  }
}

export function getEventSuccess(event: EventResponseEntity): GetEventSuccess {
  return {
    type: GET_EVENT_SUCCESS,
    payload: event
  }
}

/**
 * @description Thunk for getting event financial details
 * @param eventId
 */
export function getEventFinancialDetails(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_FINANCIAL_DETAILS_REQUEST})

      const response = await EventService.getEventFinancialDetails(eventId)
      dispatch(getEventFinancialDetailsSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_EVENT_FINANCIAL_DETAILS_ERROR})
      console.error(error)
    }
  }
}

export function getEventFinancialDetailsSuccess(eventFinancialDetails: EventFinancialResponseEntity): GetEventFinancialDetailsSuccess {
  return {
    type: GET_EVENT_FINANCIAL_DETAILS_SUCCESS,
    payload: eventFinancialDetails
  }
}

/**
 * @description Thunk for getting event impact KPIs
 * @param eventId
 */
export function getEventImpactKPIs(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_IMPACT_KPI_REQUEST})

      const response = await EventService.getEventImpactKPIs(eventId)
      dispatch(getEventImpactKPIsSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_EVENT_IMPACT_KPI_ERROR})
      console.error(error)
    }
  }
}

export function getEventImpactKPIsSuccess(eventImpactKPIs: EventImpactKPIsResponseEntity): GetEventImpactKPISuccess {
  return {
    type: GET_EVENT_IMPACT_KPI_SUCCESS,
    payload: eventImpactKPIs
  }
}

/**
 * @description Calculate donation impact based on currency and amount
 * @param eventId
 * @param currencyId
 * @param amount
 */
export function calculateDonationImpact(eventId: string, currencyId: string, amount: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: CALCULATE_DONATION_IMPACT_REQUEST})

      const response = await EventService.calculateImpact(eventId, currencyId, amount)
      dispatch(calculateDonationImpactSuccess(response.result))

    } catch (error) {
      dispatch({type: CALCULATE_DONATION_IMPACT_ERROR})
      console.error(error)
    }
  }
}

export function calculateDonationImpactSuccess(calculatedImpact: SiemensImpactCalculationResponseEntity): CalculateDonationImpactSuccess {
  return {
    type: CALCULATE_DONATION_IMPACT_SUCCESS,
    payload: {
      calculatedImpact
    }
  }
}
