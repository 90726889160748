import React, { FC } from 'react'
import { BasePropsTypes } from '../../Common/Types/BaseProps.types'
import './styles.scss'

interface Props extends BasePropsTypes {
  activeImgSrc: string
  disabledImgSrc: string
  totalNumberOfImages: number
  numberOfActiveImages: number
}

const ProgressRepresentation: FC<Props> = (props) => {

  const {
    activeImgSrc,
    disabledImgSrc,
    totalNumberOfImages,
    numberOfActiveImages
  } = props

  const total = [...Array(totalNumberOfImages)]

  return (
    <div className='progressRepresentation'>
      {total.map((_, i) => {

        if (numberOfActiveImages > i) {
          return (
            <img
              key={i}
              className='activeImage'
              src={activeImgSrc}
              alt='ON'
            />
          )
        } else {
          return (
            <img
              key={i}
              className='disabledImage'
              src={disabledImgSrc}
              alt='OFF'
            />
          )
        }

      })}
    </div>
  )
}

export default ProgressRepresentation
