import { Reducer } from 'redux'
import {
  CALCULATE_DONATION_IMPACT_SUCCESS,
  EventDetailsActionTypes,
  EventDetailsState,
  GET_EVENT_FINANCIAL_DETAILS_SUCCESS,
  GET_EVENT_IMPACT_KPI_SUCCESS,
  GET_EVENT_SUCCESS
} from './EventDetails.types'

const initialState: EventDetailsState = {
  event: {},
  financialDetails: {
    collectedBaseCurrency: '0',
    avgInvestment: '0',
    topInvestment: '0',
    numOfInvestors: '0'
  },
  impactKPIs: {
    reducedCO2Lifetime: '0',
    estimatedPower: '0',
    peoplePowered: '0',
    numOfMeters: 0,
    totalEnergySupplied: 0,
    lastReportDate: '',
    lastDistributionTime: ''
  },
  calculatedDonationImpact: {
    estimatedPower: '0',
    reducedCO2: '0',
    reducedCO2Lifetime: '0',
    peoplePowered: '0',
    baseCurrencyAmount: '0'
  }
}

export const eventDetailsReducer: Reducer<
  EventDetailsState,
  EventDetailsActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_SUCCESS: {
      const event = action.payload

      return {
        ...state,
        event: {
          ...event
        }
      }
    }

    case GET_EVENT_FINANCIAL_DETAILS_SUCCESS: {
      const eventFinancialDetails = action.payload

      return {
        ...state,
        financialDetails: {
          ...eventFinancialDetails
        }
      }
    }

    case GET_EVENT_IMPACT_KPI_SUCCESS: {
      const eventImpactKPIs = action.payload

      return {
        ...state,
        impactKPIs: {
          ...eventImpactKPIs
        }
      }
    }

    case CALCULATE_DONATION_IMPACT_SUCCESS: {
      const { calculatedImpact } = action.payload

      return {
        ...state,
        calculatedDonationImpact: {
          ...calculatedImpact
        }
      }
    }

    default: {
      return state
    }
  }
}
