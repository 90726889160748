import { Action } from 'redux'
import { MeResponseEntity } from '../API/APIResponse.types'

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR'

export const GET_ME_REQUEST = 'GET_ME_REQUEST'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_ERROR = 'GET_ME_ERROR'

export interface AuthState {
  me: Partial<MeResponseEntity>
  loggedIn: boolean
}

export interface LogoutUserSuccess extends Action<typeof LOGOUT_USER_SUCCESS> {}

export interface GetMeSuccess extends Action<typeof GET_ME_SUCCESS> {
  payload: MeResponseEntity
}

export interface GetMeError extends Action<typeof GET_ME_ERROR> {}

export type AuthActionTypes =
  LogoutUserSuccess |
  GetMeSuccess |
  GetMeError
