import React, {Component, Fragment, ReactElement} from 'react'
import { Trans } from '@lingui/macro'
import {AppState} from '../../Core/store'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {toggleModal} from '../../Core/UI/UI.actions'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import Button from '../../Elements/Button'
import {withI18n, withI18nProps} from '@lingui/react'
import AuthService from '../../Services/Auth.service'

enum ERROR {
  INVALID_EMAIL,
  WRONG_PASSWORD,
  PASSWORD_TOO_SHORT,
  DUPLICATE_EMAIL,
  MISSING_DATA,
  UNKNOWN
}

interface State {
  password: string,
  email: string,
  error: number | null,
  success: boolean
}

type Props =
  withI18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    onClose: () => void
  }

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
})

class SettingsChangeEmail extends Component<Props, State> {
  public state = {
    password: '',
    email: '',
    error: null,
    success: false
  }

  public handleChangeEmail = async () => {
    const { password, email } = this.state

    if (!password || !email) {
      this.setState({error: ERROR.MISSING_DATA})
      return
    }

    if (password.length < 8) {
      this.setState({error: ERROR.PASSWORD_TOO_SHORT})
      return
    }

    if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      this.setState({error: ERROR.INVALID_EMAIL})
      return
    }

    try {
      await AuthService.changeEmail(email, password)
      this.setState({success: true})
    } catch (err) {
      const errParam = err.details[0].param
      if (errParam === 'password') {
        this.setState({error: ERROR.WRONG_PASSWORD})
      } else if (errParam === 'email') {
        this.setState({error: ERROR.DUPLICATE_EMAIL})
      } else {
        this.setState({error: ERROR.UNKNOWN})
      }
      // console.log(err)
    }

  }

  public renderError() {
    const { error } = this.state

    if (error == null) {
      return null
    }

    let err: ReactElement

    if (error === ERROR.INVALID_EMAIL) {
      err = <Trans>Please enter a valid email address</Trans>
    } else if (error === ERROR.WRONG_PASSWORD) {
      err = <Trans>Current password is invalid</Trans>
    } else if (error === ERROR.PASSWORD_TOO_SHORT) {
      err = <Trans>Minimum password length is 8 characters</Trans>
    } else if (error === ERROR.DUPLICATE_EMAIL) {
      err = <Trans>The selected email already exists in the system</Trans>
    } else if (error === ERROR.MISSING_DATA) {
      err = <Trans>Please enter all input fields</Trans>
    } else {
      err = <Trans>Unknown error</Trans>
    }

    return (
      <div className='inputError'>{err}</div>
    )
  }

  public render() {
    const { email, password, success } = this.state
    return (
      <Fragment>
        <DialogHeader>
          <Trans>Change email address</Trans>
        </DialogHeader>
        <DialogBody>
          {success ?
            <Fragment>
              <div className='inputSuccess'>
                <Trans>Your email address has been successfully changed.</Trans>
              </div>
              <div className='actionButtonWrapper'>
                <Button onClick={(e) => this.props.onClose()}>
                  <Trans>Close</Trans>
                </Button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className='inputWrapper'>
                <label>
                  <Trans>New email</Trans>
                </label>
                <div>
                  <input
                    type='text'
                    required
                    onChange={(e) => this.setState({error: null, email: e.target.value})}
                    value={email}
                  />
                </div>
              </div>

              <div className='inputWrapper'>
                <label>
                  <Trans>Your password</Trans>
                </label>
                <div>
                  <input
                    type='password'
                    required
                    onChange={(e) => this.setState({error: null, password: e.target.value})}
                    value={password}
                  />
                </div>
              </div>

              {this.renderError()}

              <div className='actionButtonWrapper'>
                <Button onClick={(e) => this.props.onClose()} buttonType='outline'>
                  <Trans>Cancel</Trans>
                </Button>

                <Button onClick={this.handleChangeEmail}>
                  <Trans>Change Email</Trans>
                </Button>
              </div>
            </Fragment>
          }
        </DialogBody>
      </Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(SettingsChangeEmail))
