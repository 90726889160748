import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import SelectDropdown from '../../Elements/SelectDropdown'
import { SelectInputOption } from '../../Elements/SelectDropdown/types'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'
import { LanguageContext } from '../../HOC/LanguageContext'

type Props = {
  onLanguageChange: (selectedOption: SelectInputOption<SUPPORTED_LANGUAGES>) => void
}

const LanguageSelect: FC<Props> = (props) => {
  const {
    onLanguageChange
  } = props

  const handleLanguageChange = (inputName: string, selectedOption: SelectInputOption<SUPPORTED_LANGUAGES>) => {
    onLanguageChange(selectedOption)
  }

  const options = [
    {label: <Trans>English</Trans>, value: SUPPORTED_LANGUAGES.en},
    {label: <Trans>German</Trans>, value: SUPPORTED_LANGUAGES.de},
    {label: <Trans>French</Trans>, value: SUPPORTED_LANGUAGES.fr}
  ]

  return (
    <LanguageContext.Consumer>
      {({selectedLanguage}) => {
        const mappedSelectedOption = options.filter((options) => options.value === selectedLanguage)[0]

        return (
          <SelectDropdown
            name='languageSelect'
            label={
              <Trans>Language</Trans>
            }
            options={options}
            selectedOption={mappedSelectedOption}
            onOptionSelect={handleLanguageChange}
          />
        )
      }}
      </LanguageContext.Consumer>
    )
}

export default LanguageSelect
