import {
  GET_EVENT_LEADS_CSV_ERROR,
  GET_EVENT_LEADS_CSV_REQUEST, GET_EVENT_LEADS_CSV_SUCCESS,
  GET_EVENT_TRANSACTIONS_CSV_ERROR,
  GET_EVENT_TRANSACTIONS_CSV_REQUEST, GET_EVENT_TRANSACTIONS_CSV_SUCCESS,
  GET_EVENT_TRANSACTIONS_ERROR,
  GET_EVENT_TRANSACTIONS_REQUEST,
  GET_EVENT_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FOR_EVENT_ERROR,
  GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST,
  GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS,
  GetEventTransactionsSuccess,
  GetUserTransactionsForEventSuccess
} from './Transactions.types'
import TransactionsService from '../../Services/Transactions.service'
import { ThunkResult } from '../types'
import {
  EventTransactionsResponseEntity,
  PaginationWithCount,
  PagingInfo,
  SortOrder,
  TransactionResponseEntity
} from '../API/APIResponse.types'
import { downloadFile } from '../../Common/Utils/Common.utils'

/**
 * @description Get user transactions for event
 * @param userId
 * @param eventId
 * @param offset
 * @param limit
 */
export function getUserTransactionsForEvent(userId: string, eventId: string, offset?: number, limit?: number): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST})

      const pagination: PagingInfo = {
        sort: {
          sortColumn: 'txTimestamp',
          sortOrder: SortOrder.DESC
        },
        pagination: {
          offset: offset ? offset : 0,
          limit: limit ? limit : 0
        }
      }

      const response = await TransactionsService.getUserTransactionsForEvent(userId, eventId, pagination)
      const { result, ...paginationInfo } = response
      dispatch(getUserTransactionsForEventSuccess(result, paginationInfo))

    } catch (error) {
      dispatch({type: GET_USER_TRANSACTIONS_FOR_EVENT_ERROR})
      console.error(error)
    }
  }
}

export function getUserTransactionsForEventSuccess(userTransactions: TransactionResponseEntity[], pagination: PaginationWithCount): GetUserTransactionsForEventSuccess {
  return {
    type: GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS,
    payload: {
      userTransactions,
      pagination
    }
  }
}

/**
 * @description Get event transactions
 * @param eventId
 * @param offset
 * @param limit
 */
export function getEventTransactions(eventId: string, offset?: number, limit?: number): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_TRANSACTIONS_REQUEST})

      const pagination: PagingInfo = {
        sort: {
          sortColumn: 'txTimestamp',
          sortOrder: SortOrder.DESC
        },
        pagination: {
          offset: offset ? offset : 0,
          limit: limit ? limit : 0
        }
      }

      const response = await TransactionsService.getEventTransactions(eventId, pagination)
      const { result, ...paginationInfo } = response
      dispatch(getEventTransactionsSuccess(result, paginationInfo))

    } catch (error) {
      dispatch({type: GET_EVENT_TRANSACTIONS_ERROR})
      console.error(error)
    }
  }
}

export function getEventTransactionsSuccess(eventTransactions: EventTransactionsResponseEntity[], pagination: PaginationWithCount): GetEventTransactionsSuccess {
  return {
    type: GET_EVENT_TRANSACTIONS_SUCCESS,
    payload: {
      eventTransactions,
      pagination
    }
  }
}

/**
 * @description Get CSV file with all event transactions
 * @param eventId
 */
export function getEventTransactionsCSV(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_TRANSACTIONS_CSV_REQUEST})
      const response = await TransactionsService.getEventTransactionsCSV(eventId)

      dispatch({type: GET_EVENT_TRANSACTIONS_CSV_SUCCESS})
      downloadFile(response, 'donorTransactions', 'csv')

    } catch (error) {
      dispatch({type: GET_EVENT_TRANSACTIONS_CSV_ERROR})
      console.error(error)
    }
  }
}

/**
 * @description Get CSV file with all leads
 * @param eventId
 */
export function getEventLeadsCSV(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_EVENT_LEADS_CSV_REQUEST})
      const response = await TransactionsService.getEventLeadsCSV(eventId)

      dispatch({type: GET_EVENT_LEADS_CSV_SUCCESS})
      downloadFile(response, 'leads', 'csv')

    } catch (error) {
      dispatch({type: GET_EVENT_LEADS_CSV_ERROR})
      console.error(error)
    }
  }
}
