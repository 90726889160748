import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Col, Row, Table } from 'reactstrap'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { ReactComponent as Power } from '../../Common/Assets/power-full.svg'
import { ReactComponent as InTokens } from '../../Common/Assets/mobile-tokens.svg'
import { setLanguage } from '../../Core/UserEventSettings/UserEventSettings.actions'
import Page from '../../Elements/Page'
import {
  getActiveSaleCycle,
  getDonationsPerCountryForChart,
  getDonationsPerCurrencyForChart,
  getDonorsProgress,
  getPowerProgress, getEmissionsProgress, getPeoplePoweredProgress,
  getUserSharePercentageOfTotalDonatedAmount
} from '../../Core/selectors'
import { AppState } from '../../Core/store'
import { getEventFinancialDetails, getEventImpactKPIs, } from '../../Core/EventDetails/EventDetails.actions'
import { OwnPropsDashboardPage, StateDashboardPage, TabPanelType } from './types'
import { getDonationsPerCurrency, getDonorCountries } from '../../Core/Donations/Donations.actions'
import { GET_EVENT_FINANCIAL_DETAILS_REQUEST, GET_EVENT_IMPACT_KPI_REQUEST } from '../../Core/EventDetails/EventDetails.types'
import { GET_DONATIONS_PER_CURRENCY_REQUEST, GET_DONOR_COUNTRIES_REQUEST } from '../../Core/Donations/Donations.types'
import { createLoadingSelector } from '../../Core/API/Loading.reducer'
import GenericTableContainer from '../../Components/GenericTableContainer'
import Panel from '../../Elements/Panel'
import PanelBody from '../../Elements/Panel/PanelBody'
import PageHeading from '../../Elements/Page/PageHeading'
import { getUserEventInvestmentDetails } from '../../Core/UserEventDetails/UserEventDetails.actions'
import { GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST } from '../../Core/UserEventDetails/UserEventDetails.types'
import { getUserTransactionsForEvent } from '../../Core/Transactions/Transactions.actions'
import { GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST } from '../../Core/Transactions/Transactions.types'
import { getUserClaimHistoryForEvent } from '../../Core/ClaimHistory/ClaimHistory.actions'
import { GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST } from '../../Core/ClaimHistory/ClaimHistory.types'
import DashboardInfoRow from '../../Components/DashboardInfoRow'
import { getBlockExplorerLink, isBlockchainAsset } from '../../Common/Utils/Crypto.utils'
import UrlUtil from '../../Common/Utils/Url.utils'
import './styles.scss'
import WalletPanel from '../../Components/WalletPanel'

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnPropsDashboardPage

const isFetchingEvent = createLoadingSelector([
  GET_EVENT_FINANCIAL_DETAILS_REQUEST,
  GET_EVENT_IMPACT_KPI_REQUEST,
  GET_DONOR_COUNTRIES_REQUEST,
  GET_DONATIONS_PER_CURRENCY_REQUEST,
  GET_USER_EVENT_INVESTMENT_DETAILS_REQUEST
])

const isFetchingUserTransactions = createLoadingSelector([
  GET_USER_TRANSACTIONS_FOR_EVENT_REQUEST
])

const isFetchingUserClaimHistory = createLoadingSelector([
  GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST
])

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLanguage,
      getEventFinancialDetails,
      getEventImpactKPIs,
      getDonorCountries,
      getDonationsPerCurrency,
      getUserEventInvestmentDetails,
      getUserTransactionsForEvent,
      getUserClaimHistoryForEvent
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  me: state.auth.me,
  eventCurrencies: state.eventDetails.event.currencies,
  numOfInvestors: state.eventDetails.financialDetails.numOfInvestors,
  hardCap: state.eventDetails.event.hardCap,
  collectedBaseCurrency: state.eventDetails.financialDetails.collectedBaseCurrency,
  userInvestments: state.userEventDetails.investmentDetails.investmentValue,
  impactKPIs: state.eventDetails.impactKPIs,
  donorsProgress: getDonorsProgress(state),
  powerProgress: getPowerProgress(state),
  emissionsProgress: getEmissionsProgress(state),
  peoplePoweredProgress: getPeoplePoweredProgress(state),
  donationsPerCountryForChart: getDonationsPerCountryForChart(state),
  donationsPerCurrencyForChart: getDonationsPerCurrencyForChart(state),
  activeSaleCycle: getActiveSaleCycle(state),
  userShareOfDonatedAmount: getUserSharePercentageOfTotalDonatedAmount(state),
  userTransactions: state.transactions.userTransactions,
  userClaimHistory: state.claims.userClaimHistory,
  isFetchingEvent: isFetchingEvent(state),
  isFetchingUserTransactions: isFetchingUserTransactions(state),
  isFetchingUserClaimHistory: isFetchingUserClaimHistory(state)
})

const URL_QUERY_PARAMS = {
  PAGE_OFFSET: 'offset',
  PAGE_LIMIT: 'limit'
}

class InvestorDashboardPage extends Component<Props, StateDashboardPage> {

  public state: StateDashboardPage = {
    activeTabPanel: 1
  }

  private defaultOffset = {
    name: URL_QUERY_PARAMS.PAGE_OFFSET,
    value: '0'
  }

  private defaultLimit = {
    name: URL_QUERY_PARAMS.PAGE_LIMIT,
    value: '25'
  }

  public async componentDidMount(): Promise<void> {
    const eventId = this.props.match.params.eventId
    const userId = this.props.me.id

    const urlUtil = new UrlUtil(this.props.history, this.props.location)
    const offsetQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_OFFSET)
    const limitQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_LIMIT)

    // tslint:disable-next-line:radix
    const offset = parseInt(offsetQueryParam ? offsetQueryParam.value : this.defaultOffset.value)

    // tslint:disable-next-line:radix
    const limit = parseInt(limitQueryParam ? limitQueryParam.value : this.defaultLimit.value)

    if (userId) {
      await Promise.all([
        this.props.getEventFinancialDetails(eventId),
        this.props.getEventImpactKPIs(eventId),
        this.props.getDonorCountries(eventId),
        this.props.getDonationsPerCurrency(eventId),
        this.props.getUserEventInvestmentDetails(userId, eventId)
      ])

      await Promise.all([
        this.fetchUserTransactions(offset, limit),
        this.fetchUserClaimHistory(offset, limit)
      ])
    }
  }

  public changeTab = (tab: TabPanelType) => {
    this.setState({
      activeTabPanel: tab
    })
  }

  public fetchUserTransactions = async (offset?: number, limit?: number) => {
    const eventId = this.props.match.params.eventId
    const userId = this.props.me.id

    if (userId) {
      await this.props.getUserTransactionsForEvent(userId, eventId, offset, limit)
    }
  }

  public handleTransactionsPageChange = async (offset: number, limit: number) => {
    await this.fetchUserTransactions(offset, limit)
  }

  public handleTransactionsPaginationSetLimit = async (offset: number, limit: number) => {
    await this.fetchUserTransactions(offset, limit)
  }

  public fetchUserClaimHistory = async (offset?: number, limit?: number) => {
    const eventId = this.props.match.params.eventId
    const userId = this.props.me.id

    if (userId) {
      await this.props.getUserClaimHistoryForEvent(userId, eventId, offset, limit)
    }
  }

  public handleClaimHistoryPageChange = async (offset: number, limit: number) => {
    await this.fetchUserClaimHistory(offset, limit)
  }

  public handleClaimHistoryPaginationSetLimit = async (offset: number, limit: number) => {
    await this.fetchUserClaimHistory(offset, limit)
  }

  public render() {
    const {
      me,
      numOfInvestors,
      hardCap,
      collectedBaseCurrency,
      userInvestments,
      impactKPIs,
      donorsProgress,
      powerProgress,
      emissionsProgress,
      peoplePoweredProgress,
      donationsPerCountryForChart,
      donationsPerCurrencyForChart,
      activeSaleCycle,
      userShareOfDonatedAmount,
      userTransactions,
      isFetchingEvent,
      isFetchingUserTransactions,
      isFetchingUserClaimHistory,
      userClaimHistory
    } = this.props

    const {
      activeTabPanel
    } = this.state

    // Page URL Query params.
    const urlUtil = new UrlUtil(this.props.history, this.props.location)
    const offsetQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_OFFSET)
    const limitQueryParam = urlUtil.getQueryParam(URL_QUERY_PARAMS.PAGE_LIMIT)

    return (
      <Page className='dashboardPage'>
        <PageHeading>
          #connect2evolve <Trans description={'#connect2evolve project dashboard'}>project dashboard</Trans>
        </PageHeading>

        <DashboardInfoRow
          activeSaleCycle={activeSaleCycle}
          totalDonatedAmount={collectedBaseCurrency}
          userInvestments={userInvestments ? userInvestments : '0'}
          hardCap={hardCap ? hardCap : '0'}
          numberOfDonors={numOfInvestors}
          impactKPIs={impactKPIs}
          donorsProgress={donorsProgress}
          powerProgress={powerProgress}
          emissionsProgress={emissionsProgress}
          peoplePoweredProgress={peoplePoweredProgress}
          donationsPerCountryForChart={donationsPerCountryForChart}
          donationsPerCurrencyForChart={donationsPerCurrencyForChart}
          isLoading={isFetchingEvent}
          enableDonateFeature={true}
        />

        <Row className='tabbedPanelWrapper'>
          <Col>
            <div className='tabItems'>
              <div className={`tab ${activeTabPanel === 1 ? 'active' : ''}`} onClick={() => this.changeTab(1)}>
                <Trans>My Wallet</Trans>
              </div>
              <div className={`tab ${activeTabPanel === 2 ? 'active' : ''}`} onClick={() => this.changeTab(2)}>
                <Trans>My Donations</Trans>
              </div>
              <div className={`tab ${activeTabPanel === 3 ? 'active' : ''}`} onClick={() => this.changeTab(3)}>
                <Trans>Claim History</Trans>
              </div>
            </div>

            <div className={`tabContent ${activeTabPanel === 1 ? 'd-block' : 'd-none'}`}>
              <Row className='walletView'>
                <Col lg={6}>
                  <WalletPanel onClaim={this.fetchUserClaimHistory} userShareOfDonatedAmount={userShareOfDonatedAmount} user={me} />
                </Col>
                <Col lg={6} className='mt-3 mt-lg-0'>
                  <Panel className='distributed'>
                    <PanelBody className='d-flex flex-column h-100'>
                      <div className='values'>
                        <div className='d-flex flex-column align-items-center'>
                          <div className='svgImgWrap'>
                            <Power/>
                          </div>
                          <div className='value'>
                            1 kWh
                          </div>
                        </div>
                        <div className='d-flex align-items-end'>
                          <div className='value'>
                            =
                          </div>
                        </div>
                        <div className='d-flex flex-column align-items-center'>
                          <div className='svgImgWrap'>
                            <InTokens/>
                          </div>
                          <div className='value'>
                            1 c2e
                          </div>
                        </div>
                      </div>
                      <div className='distributedDescription'>
                        <Trans>
                          For each 1 kWh of electricity distributed to the network, you will receive 1 c2e token, representing your share of reduced CO₂ emissions.
                        </Trans>
                      </div>
                    </PanelBody>
                  </Panel>
                </Col>
              </Row>
            </div>

            <div className={`tabContent ${activeTabPanel === 2 ? 'd-block' : 'd-none'}`}>
              <GenericTableContainer
                isLoading={isFetchingUserTransactions}
                pagination={{
                  offsetQueryParam: offsetQueryParam ? offsetQueryParam : this.defaultOffset,
                  limitQueryParam: limitQueryParam ? limitQueryParam : this.defaultLimit,
                  options: {
                    limit: userTransactions.pagination.limit,
                    offset: userTransactions.pagination.offset,
                    count: userTransactions.pagination.count
                  }
                }}
                onPageChange={this.handleTransactionsPageChange}
                onSetNumberOfRecords={this.handleTransactionsPaginationSetLimit}
                emptyState={!userTransactions.transactions.length}
                render={() => (
                  <Table className='myDonationsTable' responsive={true}>
                    <thead>
                      <tr className='text-right'>
                        <th className='text-left'>
                          <Trans>Date</Trans>/
                          <br />
                          <Trans>Status</Trans>
                        </th>
                        <th className='text-left'>
                          <Trans>Payment method</Trans>/
                          <br/>
                          <Trans>ID</Trans>
                        </th>
                        <th>
                          <Trans>Donated<br/>amount</Trans>
                        </th>
                        <th>
                          <Trans>Thereof fee for financial<br />service provider</Trans>
                        </th>
                        <th>
                          <Trans>Est. power<br/>produced</Trans>
                        </th>
                        <th>
                          <Trans>Reduced CO₂ emissions</Trans>
                        </th>
                        <th>
                          <Trans>People<br/>powered</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userTransactions.transactions.map((transaction) => {
                        const eventCurrencies = this.props.eventCurrencies ? this.props.eventCurrencies : []
                        const blockchainAsset = isBlockchainAsset(eventCurrencies, transaction.amountCurrency)

                        return (
                          <tr key={transaction.transactionId} className='text-right'>
                            {/* tslint:disable-next-line:radix */}
                            <td className='text-left'>
                              {moment.unix(parseInt(transaction.transactionTime, 10)).format('DD/MM/YYYY')}
                              <div>{
                                transaction.status === 'Completed' ?
                                  <Trans>Completed</Trans> :
                                  <Trans>Pending</Trans>
                              }</div>
                            </td>
                            <td className='text-left'>
                              {blockchainAsset ?
                                <a
                                  href={getBlockExplorerLink(transaction.amountCurrency, transaction.txHash)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='grayLink'
                                >
                                  Crypto ({transaction.amountCurrency})<br/>
                                  {transaction.txHash.substr(0, 12)}...{transaction.txHash.substr(transaction.txHash.length - 12, 12)}
                                </a>
                                :
                                <div>
                                  {transaction.tagFrom}<br/>
                                  {transaction.tagTo}
                                </div>
                              }
                            </td>
                            <td>
                              <strong>
                                {new BigNumber(transaction.amount).toFormat(2)} {transaction.amountCurrency}
                              </strong>
                            </td>
                            <td className='font-weight-bold'>
                              {!blockchainAsset && transaction.status === 'Completed' && new BigNumber(transaction.fee).toNumber() > 0 ? new BigNumber(transaction.fee).toFormat(2) + ' ' + transaction.amountCurrency : 'N/A'}
                            </td>
                            <td>{new BigNumber(transaction.estimatedPower).toFormat(2)} kWh</td>
                            <td>{new BigNumber(transaction.reducedCO2Lifetime).toFormat(2)} kg</td>
                            <td>{new BigNumber(transaction.peoplePowered).toFormat(2)}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                )}
              />
            </div>

            <div className={`tabContent ${activeTabPanel === 3 ? 'd-block' : 'd-none'}`}>
              <GenericTableContainer
                isLoading={isFetchingUserClaimHistory}
                pagination={{
                  offsetQueryParam: offsetQueryParam ? offsetQueryParam : this.defaultOffset,
                  limitQueryParam: limitQueryParam ? limitQueryParam : this.defaultLimit,
                  options: {
                    limit: userClaimHistory.pagination.limit,
                    offset: userClaimHistory.pagination.offset,
                    count: userClaimHistory.pagination.count
                  }
                }}
                onPageChange={this.handleClaimHistoryPageChange}
                onSetNumberOfRecords={this.handleClaimHistoryPaginationSetLimit}
                emptyState={!userClaimHistory.data.length}
                render={() => (
                  <Table className='myDonationsTable' responsive={true}>
                    <thead>
                      <tr className='text-right'>
                        <th className='text-left'>
                          <Trans>Date</Trans>
                        </th>
                        <th className='text-left'>
                          <Trans>Wallet Address</Trans>
                        </th>
                        <th>
                          <Trans>Amount</Trans>
                        </th>
                        <th>
                          <Trans>Transaction</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userClaimHistory.data.map((claim) => {
                        return (
                          <tr key={claim.transactionHash} className='text-right'>
                            <td className='text-left'>
                              {moment(claim.createdAt).format('DD/MM/YYYY')}
                            </td>
                            <td className='text-left'>
                              {claim.walletAddress}
                            </td>
                            <td>
                              <strong>
                                {claim.amount} c2e
                              </strong>
                            </td>
                          <td><a href={`https://etherscan.io/tx/${claim.transactionHash}`} target='_blank' rel='noopener noreferrer'><Trans>View on Etherscan</Trans></a></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                )}
              />
            </div>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestorDashboardPage)
