import React, { Component, Fragment } from 'react'
import { Plural } from '@lingui/macro'
import moment from 'moment'
import Spinner from '../../Elements/Spinner'
import './styles.scss'

interface Props {
  endDate: number | undefined
  countdownEnded?: () => void
}

interface State {
  days: string
  hours: string
  minutes: string
  seconds: string
  isLoading: boolean
}

class Countdown extends Component<Props> {

  public static defaultProps = {
    countdownEnded: () => ({})
  }

  public state: State = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
    isLoading: true
  }

  private intervalId?: any

  public async componentDidMount() {
    const now  = moment.utc()
    const endDate = moment.unix(this.props.endDate ? this.props.endDate : now.milliseconds())

    const difference = moment(endDate).diff(moment(now))
    let duration = moment.duration(difference)

    const interval = 1000

    const countdownLeft = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
    if (countdownLeft.asMilliseconds() <= 0) {
      if (this.props.countdownEnded) {
        this.props.countdownEnded()
      }

      this.setState({
        isLoading: false
      })

      return
    }

    const intervalId = setInterval(() => {
      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')

      if (duration.asMilliseconds() <= 0) {
        clearInterval(this.intervalId)

        this.setState({
          isLoading: false
        }, () => {
          if (this.props.countdownEnded) {
            this.props.countdownEnded()
          }
        })

        return
      }

      const days = Math.floor(duration.asDays())
      const hours = duration.hours()
      const minutes = duration.minutes()
      const seconds = duration.seconds()

      this.setState({
        days: days < 10 ? `0${days}` : days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
        isLoading: false
      })
    }, interval)

    this.intervalId = intervalId
  }

  public componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  public render() {
    const {
      days,
      hours,
      minutes,
      seconds,
      isLoading
    } = this.state

    return (
      <div className='d-flex justify-content-between countdown'>
        {!isLoading ?
          <Fragment>
            <div className='d-flex flex-column align-items-center'>
              <span className='timeValue'>{days}</span>
              <Plural
                render='span'
                className='timeLabel'
                value={+days}
                one='DAY'
                other='DAYS'
              />
            </div>
            <div className='d-flex flex-column align-items-center'>
              <span className='timeValue'>{hours}</span>
              <Plural
                render='span'
                className='timeLabel'
                value={+hours}
                one={'HOUR'}
                other={'HOURS'}
              />
            </div>
            <div className='d-flex flex-column align-items-center'>
              <span className='timeValue'>{minutes}</span>
              <Plural
                render='span'
                className='timeLabel'
                value={+minutes}
                one={'MIN'}
                other={'MINS'}
              />
            </div>
            <div className='d-flex flex-column align-items-center'>
              <span className='timeValue'>{seconds}</span>
              <Plural
                render='span'
                className='timeLabel'
                value={+seconds}
                one={'SEC'}
                other={'SECS'}
              />
            </div>
          </Fragment>
          : <Spinner size='md'/>
        }
      </div>
    )
  }
}

export default Countdown
