import { Api } from '../Common/Utils/Api.utils'

import Stripe from 'stripe'

class StripeService {
  public static createPaymentIntent = (eventId: string, data: Stripe.PaymentIntentCreateParams): Promise<Stripe.PaymentIntent> => {
    return Api.post(`/events/${eventId}/stripe/payment-intents/`, data).then((response) => response.data)
  }

  public static getPaymentIntent = (eventId: string, paymentIntentId: string): Promise<Stripe.PaymentIntent> => {
    return Api.get(`/events/${eventId}/stripe/payment-intents/${paymentIntentId}`).then((response) => response.data)
  }

  public static getSource = (eventId: string, sourceId: string): Promise<Stripe.Source> => {
    return Api.get(`/events/${eventId}/stripe/sources/${sourceId}`).then((response) => response.data)
  }
}

export default StripeService
