import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

interface Props {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  to?: string
}

const NavItem: FC<Props> = (props) => {
  const {
    onClick = () => ({}),
    to,
    children
  } = props

  if (to) {
    return (
      <div onClick={onClick}>
        <Link to={to} className='navItem'>
          <span className='label'>{children}</span>
        </Link>
      </div>
    )
  }

  return (
    <div onClick={onClick} className='navItem'>
      <span className='label'>{children}</span>
    </div>
  )
}

export default NavItem
