import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import NavigationElement from './NavigationElement'
import UrlUtil from '../../Common/Utils/Url.utils'
import './styles.scss'

/**
 * There is 5 possible scenarios
 *
 * 1. There is 5 pages. In that case all 5 numbers are printed. Arrow are printed if selected page is not first or last.
 *
 * 2, 3. When selected page is in first 3 or in last 3 scenario is very similar. 1 ... 4 5 6 where selected is in last 3 or 1 2 3 ... X where selected is in last 3
 */

interface Props extends DefaultProps, RouteComponentProps {
  changePage?: (offset: number, limit: number) => void
}

interface DefaultProps {
  disabled: boolean,
  count: number,
  offset: number,
  limit: number,
  offsetQueryParam?: string,
}

class Pagination extends Component<Props> {

  public static defaultProps: DefaultProps = {
    disabled: true,
    count: 0,
    offset: 0,
    limit: 0,
    offsetQueryParam: 'offset'
  }

  constructor(props: Props) {
    super(props)

    this.handleNavigationClick = this.handleNavigationClick.bind(this)
  }

  public leftArrow(selected: number) {
    return selected !== 1 ?
      <li>
        <NavigationElement
          label='&lt;'
          className='paginationArrowElement'
          page={selected - 1}
          changePage={this.handleNavigationClick}
        />
      </li>
      : null
  }

  public rightArrow(selected: number, total: number) {
    return selected !== total ?
      <li>
        <NavigationElement
          label='&gt;'
          className='paginationArrowElement'
          page={selected + 1}
          changePage={this.handleNavigationClick}
        />
      </li>
      : null
  }

  public firstElement(selected: number, total: number) {
    if (this.firstPartSelected(selected) || total <= 5) {
      return null
    } else {
      return (
        <li>
          <NavigationElement
            label='1'
            page='1'
            changePage={this.handleNavigationClick}
          />
        </li>
      )
    }
  }

  public leftDots(selected: number, total: number) {
    if (this.firstPartSelected(selected) || total <= 5) {
      return null
    } else {
      // return <li><Link className='paginationDotsElement' to={'?p=' + selected}>...</Link></li>
      return (
        <li>
          <NavigationElement
            label='...'
            className='paginationDotsElement'
            page={selected}
          />
        </li>
      )
    }
  }

  public rightDots(selected: number, total: number) {
    if (this.lastPartSelected(selected, total) || (total <= 5)) {
      return null
    } else {
      // return <li><Link className='paginationDotsElement' to={'?p=' + selected}>...</Link></li>
      return (
        <li>
          <NavigationElement
            label='...'
            className='paginationDotsElement'
            page={selected}
          />
        </li>
      )
    }
  }

  public lastElement(selected: number, total: number) {
    if (this.lastPartSelected(selected, total) || (total <= 5)) {
      return null
    } else {
      // return <li><Link to={'?p=' + total}>{total}</Link></li>
      return (
        <li>
          <NavigationElement
            label={total}
            page={total}
            changePage={this.handleNavigationClick}
          />
        </li>
      )
    }
  }

  /**
   * @description First part are first 3 pagination elements
   *
   * @param {number} selected
   * @return {boolean}
   */
  public firstPartSelected(selected: number) {
    return selected <= 3
  }

  /**
   * @description Last part are last 3 pagination elements
   *
   * @param {number} selected
   * @param {number} total
   * @return {boolean}
   */
  public lastPartSelected(selected: number, total: number) {
    return selected > (total - 3)
  }

  public calculateTotalNumOfPages(totalNumOfRows: number, rowsPerPage: number) {
    let quotient = Math.floor(totalNumOfRows / rowsPerPage)
    const remainder = new BigNumber(totalNumOfRows).modulo(rowsPerPage)
    if (remainder.gt(0)) {
      quotient++
    }
    return quotient
  }

  public calculateCurrentPage(offset: number, rowsPerPage: number) {
    return ((offset / rowsPerPage) + 1)
  }

  public setUrlQueryParams = (offset: number) => {
    const {
      history,
      location,
      offsetQueryParam
    } = this.props

    const urlUtil = new UrlUtil(history, location)

    if (offsetQueryParam) {
      urlUtil.setQueryParamValue(offsetQueryParam, offset)
    }
  }

  public handleNavigationClick(page: number | string) {
    const {
      limit,
      changePage
    } = this.props

    const offset = new BigNumber(limit).multipliedBy(new BigNumber(page).minus(1)).toNumber()

    if (changePage) {
      changePage(offset, limit)
    }

    this.setUrlQueryParams(offset)
  }

  public render() {
    const {
      count,
      limit,
      offset,
      disabled
    } = this.props

    // Total is number of pages.
    const total = this.calculateTotalNumOfPages(count, limit)

    if (total > 1) {
      const selected = this.calculateCurrentPage(offset, limit)

      let middleElements = []
      if (total <= 5) {
        // Show all elements
        middleElements = [...Array(total)].map((v, i) => ++i)
      } else {
        if (this.firstPartSelected(selected)) {
          middleElements = [...Array(4)].map((v, i) => ++i)

        } else if (this.lastPartSelected(selected, total)) {
          for (let i = total - 3; i <= total; i++) {
            middleElements.push(i)
          }

        } else {
          if (selected <= 3) {
            middleElements = [selected, (selected + 1), (selected + 2)]
          } else if (selected >= (total - 2)) {
            middleElements = [(selected - 2), (selected - 1), selected]

          } else {
            middleElements = [(selected - 1), selected, (selected + 1)]
          }

          // With 5 buttons in the middle

          // if (selected === 4 || selected === 5) {
          //     middleElements = [selected, (selected + 1), (selected + 2)]

          // } else if (selected === (total - 3) || selected === (total - 4)) {
          //     middleElements = [(selected - 2), (selected - 1), selected]

          // } else if (total > 5 && total <= 9) {
          //     middleElements = [(selected - 1), selected, (selected + 1)]

          // } else {
          //     middleElements = [(selected - 2), (selected - 1), selected, (selected + 1), (selected + 2)]
          // }
        }
      }

      return (
        <div className={`${disabled ? 'cursorNotAllowed' : ''}`}>
          <ul className={`paginationContainer ${disabled ? 'disabledElement' : ''}`}>
            {this.leftArrow(selected)}
            {this.firstElement(selected, total)}
            {this.leftDots(selected, total)}
            {
              middleElements.map(
                (i) => i === selected
                  ?
                  <li key={i}>
                    <NavigationElement
                      className='paginationSelectedElement'
                      label={i}
                      page={i}
                      changePage={this.handleNavigationClick}
                    />
                  </li>
                  :
                  <li key={i}>
                    <NavigationElement
                      label={i}
                      page={i}
                      changePage={this.handleNavigationClick}
                    />
                  </li>
              )
            }
            {this.rightDots(selected, total)}
            {this.lastElement(selected, total)}
            {this.rightArrow(selected, total)}
          </ul>
        </div>
      )
    } else {
      return  null
    }
  }
}

export default withRouter(Pagination)
