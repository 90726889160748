import React from 'react'
import ReactDOM from 'react-dom'
import './Common/Styles/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: 'GTM-5LL8B99'
  })
}

ReactDOM.render(<App/>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
