import { applyMiddleware, createStore } from 'redux'
// tslint:disable-next-line:no-submodule-imports
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootReducer, { State } from './reducers'

export const history = createBrowserHistory()

export type AppState = State

const middlewares = [thunkMiddleware, routerMiddleware(history)]
const middleWareEnhancer = applyMiddleware(...middlewares)

const store = createStore (
  rootReducer(history),
  composeWithDevTools(middleWareEnhancer)
)

export default store
