import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from './store'

export type ThunkResult<R> = ThunkAction<R, AppState, undefined, Action>

export interface ChartData {
  title: string,
  value: string,
  color: string
}

export enum UserSettingsType {
  LANGUAGE = 'LANGUAGE',
  DISPLAY_CURRENCY = 'DISPLAY_CURRENCY'
}

export interface CountryEntity {
  code: string,
  name: string
}

export interface StripePaymentMethod {
  id: string,
  name: string,
  flow: string,
  countries?: string[],
  currencies?: string[],
  element: boolean,
  poll: boolean
}

export interface StripePaymentIntentData {
  amount: number,
  currency: string,
}
