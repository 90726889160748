import React, { FC } from 'react'
import { Trans } from '@lingui/macro'
import NotFoundImage from '../../Common/Assets/not-found-img.svg'
import Page from '../../Elements/Page'
import Button from '../../Elements/Button'
import './style.scss'

const NotFoundPage: FC = () => {
  const handleGoBack = () => {
    window.history.back()
  }

  return (
    <Page className='notFoundPage'>
      <Trans render='div' className='pageHeadLine'>Oops! Page not found</Trans>
      <div style={{fontSize: '36px'}} className=' ml-md-4 mr-md-4'>
        <img src={NotFoundImage} alt='' className='notFoundImg' />
      </div>
      <div className='text-center warningMessage'>
        <Trans render='div'>It looks like the page you were looking for no longer exists.</Trans>
      </div>
      <Button onClick={handleGoBack}>
        <Trans>GO BACK</Trans>
      </Button>
    </Page>
  )
}

export default NotFoundPage
