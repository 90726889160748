import React, {Component, Fragment, ReactElement} from 'react'
import {Trans} from '@lingui/macro'
import {AppState} from '../../Core/store'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {toggleModal} from '../../Core/UI/UI.actions'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import Button from '../../Elements/Button'
import {withI18n, withI18nProps} from '@lingui/react'
import AuthService from '../../Services/Auth.service'

enum ERROR {
  PASSWORDS_DONT_MATCH,
  WRONG_PASSWORD,
  PASSWORD_TOO_SHORT
}

interface State {
  currentPassword: string,
  newPassword: string,
  newPassword2: string,
  error: number | null,
  success: boolean
}

type Props =
  withI18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    onClose: () => void
  }

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
})

class SettingsChangePassword extends Component<Props, State> {
  public state = {
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
    error: null,
    success: false
  }

  public handleChangePassword = async () => {
    const { currentPassword, newPassword, newPassword2 } = this.state

    if (newPassword !== newPassword2) {
      this.setState({error: ERROR.PASSWORDS_DONT_MATCH})
      return
    }

    if (currentPassword.length < 8 || newPassword.length < 8) {
      this.setState({error: ERROR.PASSWORD_TOO_SHORT})
      return
    }

    try {
      await AuthService.changePassword(currentPassword, newPassword)
      this.setState({success: true})
    } catch (err) {
      this.setState({error: ERROR.WRONG_PASSWORD})
      // console.log(err)
    }

  }

  public renderError() {
    const { error } = this.state

    if (error == null) {
      return null
    }

    let err: ReactElement

    if (error === ERROR.PASSWORDS_DONT_MATCH) {
      err = <Trans>Password confirmation doesn't match</Trans>
    } else if (error === ERROR.WRONG_PASSWORD) {
      err = <Trans>Current password is invalid</Trans>
    } else if (error === ERROR.PASSWORD_TOO_SHORT) {
      err = <Trans>Minimum password length is 8 characters</Trans>
    } else {
      err = <Trans>Unknown error</Trans>
    }

    return (
      <div className='inputError'>{err}</div>
    )
  }

  public render() {
    const { currentPassword, newPassword, newPassword2, success } = this.state
    return (
      <Fragment>
        <DialogHeader>
          <Trans>Change password</Trans>
        </DialogHeader>
        <DialogBody>
          {success ?
            <Fragment>
              <div className='inputSuccess'>
                <Trans>Your password has been successfully changed.</Trans>
              </div>
              <div className='actionButtonWrapper'>
                <Button onClick={(e) => this.props.onClose()}>
                  <Trans>Close</Trans>
                </Button>
              </div>
            </Fragment>
            :
            <Fragment>
              <div className='inputWrapper'>
                <label>
                  <Trans>Current password</Trans>
                </label>
                <div>
                  <input
                    type='password'
                    required
                    onChange={(e) => this.setState({error: null, currentPassword: e.target.value})}
                    value={currentPassword}
                  />
                </div>
              </div>

              <div className='inputWrapper'>
                <label>
                  <Trans>New password</Trans>
                </label>
                <div>
                  <input
                    type='password'
                    required
                    onChange={(e) => this.setState({error: null, newPassword: e.target.value})}
                    value={newPassword}
                  />
                </div>
              </div>

              <div className='inputWrapper'>
                <label>
                  <Trans>Confirm new password</Trans>
                </label>
                <div>
                  <input
                    type='password'
                    required
                    onChange={(e) => this.setState({error: null, newPassword2: e.target.value})}
                    value={newPassword2}
                  />
                </div>
              </div>

              {this.renderError()}

              <div className='actionButtonWrapper'>
                <Button onClick={(e) => this.props.onClose()} buttonType='outline'>
                  <Trans>Cancel</Trans>
                </Button>

                <Button onClick={this.handleChangePassword}>
                  <Trans>Change Password</Trans>
                </Button>
              </div>
            </Fragment>
          }
        </DialogBody>
      </Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(SettingsChangePassword))
