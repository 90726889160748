import React, { Component, Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import BigNumber from 'bignumber.js'
import { withI18n, withI18nProps } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import PanelTitle from '../../Elements/Panel/PanelTitle'
import PanelBody from '../../Elements/Panel/PanelBody'
import Countdown from '../Countdown'
import InfoCell from '../../Elements/InfoCell'
import ProgressBar from '../ProgressBar'
import { toPercentage } from '../../Common/Utils/Math.utils'
import Panel from '../../Elements/Panel'
import {
  EventImpactKPIsResponseEntity,
  SaleCycleResponseEntity
} from '../../Core/API/APIResponse.types'
import ProgressRepresentation from '../../Elements/ProgressRepresentation'
import humanFull from '../../Common/Assets/human-full.svg'
import humanEmpty from '../../Common/Assets/human-empty.svg'
import powerFull from '../../Common/Assets/power-full.svg'
import powerEmpty from '../../Common/Assets/power-empty.svg'
import treeFull from '../../Common/Assets/tree-full.svg'
import treeEmpty from '../../Common/Assets/tree-empty.svg'
import { ReactComponent as Globe } from '../../Common/Assets/globe-icon.svg'
import InvestmentChart from '../InvestmentChart'
import { ChartData } from '../../Core/types'
import Donate from '../Donate'
import './styles.scss'

interface Props extends withI18nProps {
  activeSaleCycle?: SaleCycleResponseEntity
  totalDonatedAmount: string
  userInvestments?: string
  hardCap: string
  isLoading: boolean
  impactKPIs: EventImpactKPIsResponseEntity
  numberOfDonors: string
  donorsProgress: number
  powerProgress: number
  emissionsProgress: number
  peoplePoweredProgress: number
  donationsPerCurrencyForChart: ChartData[]
  donationsPerCountryForChart: ChartData[]
  enableDonateFeature: boolean
}

interface State {
  isSaleCycleActive: boolean
}

class DashboardInfoRow extends Component<Props, State> {
  public state = {
    isSaleCycleActive: !!this.props.activeSaleCycle
  }

  public handleCountDownEnded = () => {
    this.setState({
      isSaleCycleActive: false
    })
  }

  public computeActiveCycleEndDate = (
    isCurrentlyActive: boolean,
    saleCycle?: SaleCycleResponseEntity
  ): number | undefined => {
    if (isCurrentlyActive && saleCycle) {
      return saleCycle.endDate
    }
  }

  public renderProjectStatusPanel = () => {
    const {
      activeSaleCycle,
      isLoading,
      hardCap,
      totalDonatedAmount,
      userInvestments,
      enableDonateFeature
    } = this.props

    const projectStatus = this.state.isSaleCycleActive
      ? this.props.i18n._(t`Donations Open`)
      : this.props.i18n._(t`Donation Period Ended`)

    const activeCycleEndDate = this.computeActiveCycleEndDate(
      this.state.isSaleCycleActive,
      activeSaleCycle
    )

    return (
      <Panel className='projectStatusPanel' isLoading={isLoading}>
        {this.state.isSaleCycleActive ? (
          // Open donations
          <Fragment>
            <PanelTitle>
              <Trans>Time left to donate</Trans>
            </PanelTitle>

            <PanelBody>
              <Countdown
                endDate={activeCycleEndDate}
                countdownEnded={this.handleCountDownEnded}
              />
              <InfoCell
                className='progressBarCell'
                label={this.props.i18n._(t`Donated so far`)}
                value={
                  <Fragment>
                    <ProgressBar max={hardCap} current={totalDonatedAmount} />
                    <div className='progressValue'>
                      {new BigNumber(totalDonatedAmount).toFormat(2)} EUR&nbsp;(
                      {toPercentage(totalDonatedAmount, hardCap)}%)
                    </div>
                  </Fragment>
                }
              />
              <InfoCell
                label={this.props.i18n._(t`Project status`)}
                value={projectStatus}
                className='mt-3'
              />

              {enableDonateFeature && (
                <Donate userInvestmentAmount={userInvestments} />
              )}
            </PanelBody>
          </Fragment>
        ) : (
          // Closed donations
          <PanelBody className='donationsClosedBody'>
            <div className='label'>
              <Trans>Total donated:</Trans>
            </div>
            {/* TOTAL DONATION IS HARDCODED PER CUSTOMERS REQUEST */}
            {/* <div className='infoValue'>{new BigNumber(totalDonatedAmount).toFormat(2)} EUR</div> */}
            <div className='infoValue'>122.298,88 EUR</div>
            <div className='label'>{projectStatus}</div>
            <div className='label thx'>
              <strong>
                <Trans>Thank you!</Trans>
              </strong>
            </div>
          </PanelBody>
        )}
      </Panel>
    )
  }

  public renderProjectImpactPanel = () => {
    const {
      isLoading,
      impactKPIs,
      donorsProgress,
      powerProgress,
      emissionsProgress,
      peoplePoweredProgress,
      numberOfDonors
    } = this.props

    return (
      <Panel className='projectImpactPanel' isLoading={isLoading}>
        <PanelTitle>
          <Trans>Current collective impact of #c2e Solartainer®:</Trans>
        </PanelTitle>

        <PanelBody>
          <div className='itemRow'>
            <div className='item'>
              <ProgressRepresentation
                activeImgSrc={humanFull}
                disabledImgSrc={humanEmpty}
                totalNumberOfImages={5}
                numberOfActiveImages={donorsProgress}
              />
              <InfoCell
                label={this.props.i18n._(t`Number of donors`)}
                value={new BigNumber(numberOfDonors).toFormat(0)}
              />
            </div>

            <div className='item'>
              <ProgressRepresentation
                activeImgSrc={powerFull}
                disabledImgSrc={powerEmpty}
                totalNumberOfImages={5}
                numberOfActiveImages={powerProgress}
              />
              <InfoCell
                label={this.props.i18n._(t`Electricity delivered`)}
                value={`${new BigNumber(
                  impactKPIs.totalEnergySupplied
                ).toFormat(0)} kWh`}
              />
            </div>
          </div>

          <div className='itemRow'>
            <div className='item'>
              <ProgressRepresentation
                activeImgSrc={treeFull}
                disabledImgSrc={treeEmpty}
                totalNumberOfImages={5}
                numberOfActiveImages={emissionsProgress}
              />
              <InfoCell
                label={
                  <div>
                    <Trans>Reduced CO₂ emissions</Trans>
                  </div>
                }
                value={`${new BigNumber(impactKPIs.totalEnergySupplied * 0.8474576).toFormat(
                  0
                )} kg`}
              />
            </div>

            <div className='item'>
              <ProgressRepresentation
                activeImgSrc={humanFull}
                disabledImgSrc={humanEmpty}
                totalNumberOfImages={5}
                numberOfActiveImages={peoplePoweredProgress}
              />
              <InfoCell
                label={this.props.i18n._(t`People powered`)}
                value={`${new BigNumber(impactKPIs.numOfMeters * 11).toFormat(
                  0
                )}`}
              />
            </div>
          </div>
          <div className='itemRow'>
            <div className='last-report-update'>
              <Trans>Last update:</Trans>
              {new Date(impactKPIs.lastReportDate).toLocaleString()}{' '}
              <Trans> local time.</Trans>
            </div>
          </div>
        </PanelBody>
      </Panel>
    )
  }

  public renderProjectDonationPanel = () => {
    const {
      isLoading,
      donationsPerCurrencyForChart,
      donationsPerCountryForChart
    } = this.props

    return (
      <Panel className='projectDonationsPanel' isLoading={isLoading}>
        {!donationsPerCurrencyForChart.length ? (
          <div className='h-100 py-2 d-flex justify-content-center align-items-center flex-column text-center'>
            <span className='color-1 font-weight-300 fs-14 w-100'>
              <Trans>Donation country data will soon be available.</Trans>
            </span>
            <div className='py-3'>
              <Globe />
            </div>
          </div>
        ) : (
          <Fragment>
            <PanelTitle>
              <Trans>Donor Countries (top 5)</Trans>
            </PanelTitle>

            <PanelBody>
              <div>
                <InvestmentChart legendList={donationsPerCountryForChart} />

                <div className='currencyGraphWrapper'>
                  <PanelTitle>
                    <Trans>Donation currencies</Trans>
                  </PanelTitle>
                  <InvestmentChart legendList={donationsPerCurrencyForChart} />
                </div>
              </div>
            </PanelBody>
          </Fragment>
        )}
      </Panel>
    )
  }

  public render() {
    return (
      <Row className='infoRowContainer'>
        <Col className='column' xs={12} lg={6} xl={4}>
          {this.renderProjectStatusPanel()}
        </Col>

        <Col className='column' xs={12} lg={6} xl={4}>
          {this.renderProjectImpactPanel()}
        </Col>

        <Col className='column' xs={12} lg={12} xl={4}>
          {this.renderProjectDonationPanel()}
        </Col>
      </Row>
    )
  }
}

export default withI18n()(DashboardInfoRow)
