import { Api } from '../Common/Utils/Api.utils'
import {
  EventTransactionsResponseEntity,
  PaginatedResponse,
  PagingInfo,
  TransactionResponseEntity
} from '../Core/API/APIResponse.types'

class TransactionsService {
  public static getUserTransactionsForEvent = (userId: string, eventId: string, pagination: PagingInfo): Promise<PaginatedResponse<TransactionResponseEntity[]>> => {
    return Api.get(`/users/${userId}/events/${eventId}/siemens/transactions`, {
      params: {
        sortColumn: pagination.sort.sortColumn,
        sortOrder: pagination.sort.sortOrder,
        limit: pagination.pagination.limit,
        offset: pagination.pagination.offset
      }
    }).then((response) => response.data)
  }

  public static getEventTransactions = (eventId: string, pagination: PagingInfo): Promise<PaginatedResponse<EventTransactionsResponseEntity[]>> => {
    return Api.get(`/events/${eventId}/siemens/transactions`, {
      params: {
        sortColumn: pagination.sort.sortColumn,
        sortOrder: pagination.sort.sortOrder,
        limit: pagination.pagination.limit,
        offset: pagination.pagination.offset
      }
    }).then((response) => response.data)
  }

  public static getEventTransactionsCSV = (eventId: string): Promise<string> => {
    return Api.get(`/events/${eventId}/siemens/transactions/csv`).then((response) => response.data)
  }

  public static getEventLeadsCSV = (eventId: string): Promise<string> => {
    return Api.get(`/events/${eventId}/users-without-transfer/csv`).then((response) => response.data)
  }
}

export default TransactionsService
