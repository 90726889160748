import { Api } from '../Common/Utils/Api.utils'
import {
  CountriesResponseEntity,
  EventFinancialResponseEntity,
  EventImpactKPIsResponseEntity,
  EventResponseEntity,
  InvestmentPerCurrencyResponseEntity,
  Response,
  SiemensImpactCalculationResponseEntity
} from '../Core/API/APIResponse.types'

class EventService {
  public static getEvent = (
    id: string
  ): Promise<Response<EventResponseEntity>> => {
    return Api.get(`/events/${id}`).then((response) => response.data)
  }

  public static getEventFinancialDetails = (
    id: string
  ): Promise<Response<EventFinancialResponseEntity>> => {
    return Api.get(`/events/${id}/info/financial-details`).then(
      (response) => response.data
    )
  }

  public static getEventImpactKPIs = (
    id: string
  ): Promise<Response<EventImpactKPIsResponseEntity>> => {
    return Api.get(`/events/${id}/siemens/collective-impact`).then(
      (response) => response.data
    )
  }

  public static getEventDonorCountries = (
    id: string,
    limit: number
  ): Promise<Response<CountriesResponseEntity>> => {
    return Api.get(`/events/${id}/info/countries`, {
      params: {
        limit
      }
    }).then((response) => response.data)
  }

  public static getEventDonationsPerCurrency = (
    id: string
  ): Promise<Response<InvestmentPerCurrencyResponseEntity>> => {
    return Api.get(`/events/${id}/info/currencies`).then(
      (response) => response.data
    )
  }

  public static calculateImpact = (
    eventId: string,
    currencyId: string,
    amount: string
  ): Promise<Response<SiemensImpactCalculationResponseEntity>> => {
    return Api.post(`/events/${eventId}/siemens/calculate-impact`, {
      currencyId,
      amount
    }).then((response) => response.data)
  }
}

export default EventService
