import { CurrencyEntity } from '../../Core/API/APIResponse.types'

export function getBlockExplorerLink(symbol: string, transactionHash: string): string {
  if (!symbol) {
    return '#'
  }

  switch (symbol.toLowerCase()) {
    case 'eth':
    case 'bnb':
    case 'dai': {
      return `https://etherscan.io/tx/${transactionHash}`
    }

    case 'ltc': {
      return `https://blockchair.com/litecoin/transaction/${transactionHash}`
    }

    case 'btc': {
      return `https://blockchair.com/bitcoin/transaction/${transactionHash}`
    }

    case 'xrp': {
      return `https://xrpcharts.ripple.com/#/transactions/${transactionHash}`
    }

    case 'bch': {
      return `https://blockchair.com/bitcoin-cash/transaction/${transactionHash}`
    }

    default: {
      return `https://etherscan.io/tx/${transactionHash}`
    }
  }
}

export function isBlockchainAsset(supportedCurrencies: CurrencyEntity[], currency: string): boolean {
  for (let i = 0; i < supportedCurrencies.length ; i++) {
    if (supportedCurrencies[i].symbol === currency && supportedCurrencies[i].type === 'asset') {
      return true
    }
  }
  return false
}
