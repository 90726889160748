import { NotFoundError } from '../Common/Utils/Error.utils'
import { Api } from '../Common/Utils/Api.utils'
import { UserSettingsType } from '../Core/types'
import {
  BankInstructionsResponseEntity,
  CurrencyEntity, ProxyAddressResponseEntity,
  Response, UserEventResponseEntity,
  UserSettingsDisplayCurrencyResponseEntity,
  UserSettingsLanguageResponseEntity
} from '../Core/API/APIResponse.types'
import { SUPPORTED_LANGUAGES } from '../Common/Constants'

class UserEventService {
  public static readonly languageLocalStorageKey = 'language'

  public static setLanguage = (id: string, language: SUPPORTED_LANGUAGES): Promise<Response<UserSettingsLanguageResponseEntity>> => {
    localStorage.setItem(UserEventService.languageLocalStorageKey, language)

    return Api.put(`/me/events/${id}`, {
      key: UserSettingsType.LANGUAGE,
      data: language
    }).then((response) => response.data)
  }

  public static setWalletAddress = (walletAddress: string): Promise<Response<{}>> => {
    return Api.put('/me/walletAddress', {
      walletAddress
    }).then((response) => response.data)
  }

  public static removeLanguage = (): boolean => {
    localStorage.removeItem(UserEventService.languageLocalStorageKey)

    return true
  }

  public static getLanguage = (): SUPPORTED_LANGUAGES => {
    const language = localStorage.getItem(UserEventService.languageLocalStorageKey) as SUPPORTED_LANGUAGES

    if (language) {
      return language
    }

    throw new NotFoundError('Language preference not found.')
  }

  public static setDisplayCurrency = (id: string, currencyCode: string): Promise<Response<UserSettingsDisplayCurrencyResponseEntity>> => {
    return Api.put(`/me/events/${id}`, {
      key: UserSettingsType.DISPLAY_CURRENCY,
      data: currencyCode
    }).then((response) => response.data)
  }

  public static getUserEvent = (userId: string, eventId: string): Promise<Response<UserEventResponseEntity>> => {
    return Api.get(`/users/${userId}/events/${eventId}`).then((response) => response.data)
  }

  public static getUserSupportedCurrencies = (userId: string, eventId: string): Promise<Response<CurrencyEntity[]>> => {
    return Api.get(`/users/${userId}/events/${eventId}/currencies`).then((response) => response.data)
  }

  public static getProxyAddress = (userId: string, eventId: string, currencyId: string): Promise<Response<ProxyAddressResponseEntity>> => {
    return Api.get(`/users/${userId}/events/${eventId}/currency/${currencyId}/address`).then((response) => response.data)
  }

  public static getBankInstructions = (userId: string, eventId: string, currencyId: string): Promise<Response<BankInstructionsResponseEntity>> => {
    return Api.get(`/users/${userId}/events/${eventId}/currencies/${currencyId}/fiat-instructions`).then((response) => response.data)
  }

  public static getBankReferenceNumber = (userId: string, eventId: string, currencyId: string): Promise<Response<string>> => {
    return Api.get(`/users/${userId}/events/${eventId}/currencies/${currencyId}/reference`).then((response) => response.data)
  }
}

export default UserEventService
