import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import NotFoundPage from './ErrorPage/NotFoundPage'
import UnauthorizedAccessPage from './ErrorPage/UnauthorizedAccessPage'
// import TemporaryLoginPage from './TemporaryLoginPage'
import PrivateRoute from './PrivateRoute'
import EventRedirectPage from './EventRedirectPage'
import EventPage from './EventPage'

const Pages: React.FC<any> = () => (
  <Switch>
    {/*<Route path={'/login'} exact={true} component={TemporaryLoginPage} />*/}
    <PrivateRoute path={'/event'} exact={true} component={EventRedirectPage}/>
    <PrivateRoute path={'/event/:eventId(\\d+)/'} strict={true} component={EventPage}/>

    <Route path='/unauthorized' exact={true} component={UnauthorizedAccessPage}/>
    <Route path='/not-found' component={NotFoundPage}/>
    <Redirect to={'/not-found'}/>
  </Switch>
)

export default Pages
