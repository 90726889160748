import React, { Component, Fragment } from 'react'
import { toastr } from 'react-redux-toastr'
import { RouteComponentProps } from 'react-router-dom'
import { t, Trans } from '@lingui/macro'
import { Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { i18n } from '../../HOC/I18nLoader'
import Page from '../../Elements/Page'
import PageHeading from '../../Elements/Page/PageHeading'
import Panel from '../../Elements/Panel'
import PanelTitle from '../../Elements/Panel/PanelTitle'
import PanelBody from '../../Elements/Panel/PanelBody'
import LanguageSelect from '../../Components/LanguageSelect'
import { SelectInputOption } from '../../Elements/SelectDropdown/types'
import { SET_DISPLAY_CURRENCY_REQUEST, SET_LANGUAGE_REQUEST } from '../../Core/UserEventSettings/UserEventSettings.types'
import { setDisplayCurrency, setLanguage } from '../../Core/UserEventSettings/UserEventSettings.actions'
import { AppState } from '../../Core/store'
import { createLoadingSelector } from '../../Core/API/Loading.reducer'
import Button from '../../Elements/Button'
import { MODALS, SUPPORTED_LANGUAGES } from '../../Common/Constants'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import Dialog from '../../Elements/Dialog'
import { toggleModal } from '../../Core/UI/UI.actions'
import AuthService from '../../Services/Auth.service'
import './styles.scss'
import SettingsChangePassword from './SettingsChangePassword'
import SettingsChangeEmail from './SettingsChangeEmail'

interface RouteParams {
  eventId: string
}

interface State {
  deleteRequestInProgress: boolean
  deleteRequestSent: boolean
}

type Props =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<RouteParams>

const settingLanguageInProgress = createLoadingSelector([
  SET_LANGUAGE_REQUEST
])

const settingDisplayCurrencyInProgress = createLoadingSelector([
  SET_DISPLAY_CURRENCY_REQUEST
])

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLanguage,
      setDisplayCurrency,
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  language: state.userEventSettings.language,
  displayCurrency: state.userEventSettings.displayCurrency,
  modals: state.ui.modals,
  settingLanguageInProgress: settingLanguageInProgress(state),
  settingDisplayCurrencyInProgress: settingDisplayCurrencyInProgress(state)
})

class SettingsPage extends Component<Props, State> {
  public state = {
    deleteRequestInProgress: false,
    deleteRequestSent: false
  }

  public handleLanguageChange = async (selectedOption: SelectInputOption<SUPPORTED_LANGUAGES>) => {
    const eventId = this.props.match.params.eventId
    await this.props.setLanguage(eventId, selectedOption.value)
  }

  public handleCurrencyChange = async (selectedOption: SelectInputOption<string>) => {
    const eventId = this.props.match.params.eventId
    await this.props.setDisplayCurrency(eventId, selectedOption.value)
  }

  public handleEmailChange = () => {
    // window.location.assign(`${Config.ONBOARDING_BASE_URL}/change-email`)
  }

  public handlePasswordChange = () => {
    // window.location.assign(`${Config.ONBOARDING_BASE_URL}/change-password`)
  }

  public closeModal = (modal: MODALS) => {
    this.props.toggleModal(modal, false)
  }

  public requestDeleteData = async () => {
    const eventId = this.props.match.params.eventId

    try {
      this.setState({
        deleteRequestInProgress: true
      })
      await AuthService.requestPersonalDataRemoval(eventId)
      this.setState({
        deleteRequestSent: true
      })

    } catch (error) {
      if (error.response.status === 409) {
        toastr.info(i18n._(t`Info`), i18n._(t`Your request has been received. You will receive a response soon. Thank you.`))
      } else {
        toastr.error(i18n._(t`Error`), i18n._(t`Failed to send request, please try again`))
      }

      this.props.toggleModal(MODALS.DELETE_MY_DATA, false)
      console.debug(error)

    } finally {
      this.setState({
        deleteRequestInProgress: false
      })
    }
  }

  public render() {
    const {
      settingLanguageInProgress,
      settingDisplayCurrencyInProgress,
      modals,
      toggleModal
    } = this.props

    return (
      <Page className='settingsPage' isLoading={settingLanguageInProgress || settingDisplayCurrencyInProgress}>
        <PageHeading>
          <Trans>
            My Account
          </Trans>
        </PageHeading>

        <Row>
          <Col lg={4} md={6} className='column'>
            <Panel className='platformSettings'>
              <PanelTitle>
                <Trans>
                  Platform settings
                </Trans>
              </PanelTitle>

              <PanelBody>
                <LanguageSelect
                  onLanguageChange={this.handleLanguageChange}
                />

                {/*<CurrencySelect*/}
                {/*  className='currencySelectWrapper'*/}
                {/*  onCurrencyChange={this.handleCurrencyChange}*/}
                {/*  selectedCurrencyValue={displayCurrency}*/}
                {/*/>*/}
              </PanelBody>
            </Panel>
          </Col>
          <Col lg={4} md={6} className='column'>
            <Panel className='privacySettings'>
              <PanelTitle>
                <Trans>
                  Privacy settings
                </Trans>
              </PanelTitle>

              <PanelBody>
                <div className='description'>
                  <Trans>
                    We value your privacy. You can request removal of your personal data from our platform at any time.
                  </Trans>
                </div>
                <Button
                  className='w-100'
                  onClick={() => toggleModal(MODALS.DELETE_MY_DATA, true)}
                >
                  <Trans>
                    Delete my data
                  </Trans>
                </Button>
              </PanelBody>
            </Panel>
          </Col>
          <Col lg={4} className='column'>
            <Panel className='changeCredentials'>
              <PanelTitle>
                <Trans>
                  Change credentials
                </Trans>
              </PanelTitle>

              <PanelBody>
                <div className='actionButtonWrapper'>
                  <Button onClick={() => toggleModal(MODALS.CHANGE_PASSWORD, true)}>
                    <Trans>
                      Change password
                    </Trans>
                  </Button>
                </div>
                <div className='actionButtonWrapper'>
                  <Button onClick={() => toggleModal(MODALS.CHANGE_EMAIL, true)}>
                    <Trans>
                      Change email address
                    </Trans>
                  </Button>
                </div>
              </PanelBody>
            </Panel>
          </Col>
        </Row>

        <Dialog
          className='deleteMyDataModal'
          isOpen={!!modals[MODALS.DELETE_MY_DATA]}
        >
          {!this.state.deleteRequestSent ?
            <Fragment>
              <DialogHeader>
                <Trans>Delete my data</Trans>
              </DialogHeader>

              <DialogBody>
                <Trans>
                  To delete your data, click the button below to send a request to the #connect2evolve team.
                </Trans>
                <div className='actionButtonWrapper'>
                  <Button
                    onClick={() => this.closeModal(MODALS.DELETE_MY_DATA)}
                    buttonType='outline'
                  >
                    <Trans>Discard</Trans>
                  </Button>

                  <Button
                    onClick={this.requestDeleteData}
                    buttonTheme='danger'
                  >
                    <Trans>Request</Trans>
                  </Button>
                </div>
              </DialogBody>
            </Fragment>
            :
            <Fragment>
              <DialogHeader>
                <Trans>
                  Request received
                </Trans>
              </DialogHeader>

              <DialogBody>
                <Trans>
                  Your request has been received. You will receive a response soon. Thank you.
                </Trans>
                <div className='actionButtonWrapper'>
                  <Button
                    onClick={() => this.closeModal(MODALS.DELETE_MY_DATA)}
                    buttonType='outline'
                  >
                    <Trans>Close</Trans>
                  </Button>
                </div>
              </DialogBody>
            </Fragment>
          }
        </Dialog>

        <Dialog
          className='changePasswordModal'
          isOpen={!!modals[MODALS.CHANGE_PASSWORD]}
        >
          <SettingsChangePassword onClose={() => this.closeModal(MODALS.CHANGE_PASSWORD)} />
        </Dialog>

        <Dialog
          className='changeEmailModal'
          isOpen={!!modals[MODALS.CHANGE_EMAIL]}
        >
          <SettingsChangeEmail onClose={() => this.closeModal(MODALS.CHANGE_EMAIL)} />
        </Dialog>
      </Page>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage)
