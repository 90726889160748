import { Action } from 'redux'
import {
  BankInstructionsResponseEntity,
  CountriesResponseEntity,
  InvestmentPerCurrencyResponseEntity,
  ProxyAddressResponseEntity
} from '../API/APIResponse.types'

export const GET_DONOR_COUNTRIES_REQUEST = 'GET_DONOR_COUNTRIES_REQUEST'
export const GET_DONOR_COUNTRIES_SUCCESS = 'GET_DONOR_COUNTRIES_SUCCESS'
export const GET_DONOR_COUNTRIES_ERROR = 'GET_DONOR_COUNTRIES_ERROR'

export const GET_DONATIONS_PER_CURRENCY_REQUEST = 'GET_DONATIONS_PER_CURRENCY_REQUEST'
export const GET_DONATIONS_PER_CURRENCY_SUCCESS = 'GET_DONATIONS_PER_CURRENCY_SUCCESS'
export const GET_DONATIONS_PER_CURRENCY_ERROR = 'GET_DONATIONS_PER_CURRENCY_ERROR'

export const GET_CRYPTO_PROXY_ADDRESS_REQUEST = 'GET_CRYPTO_PROXY_ADDRESS_REQUEST'
export const GET_CRYPTO_PROXY_ADDRESS_SUCCESS = 'GET_CRYPTO_PROXY_ADDRESS_SUCCESS'
export const GET_CRYPTO_PROXY_ADDRESS_ERROR = 'GET_CRYPTO_PROXY_ADDRESS_ERROR'

export const GET_BANK_INSTRUCTIONS_REQUEST = 'GET_BANK_INSTRUCTIONS_REQUEST'
export const GET_BANK_INSTRUCTIONS_SUCCESS = 'GET_BANK_INSTRUCTIONS_SUCCESS'
export const GET_BANK_INSTRUCTIONS_ERROR = 'GET_BANK_INSTRUCTIONS_ERROR'

export const GET_BANK_REFERENCE_NUMBER_REQUEST = 'GET_BANK_REFERENCE_NUMBER_REQUEST'
export const GET_BANK_REFERENCE_NUMBER_SUCCESS = 'GET_BANK_REFERENCE_NUMBER_SUCCESS'
export const GET_BANK_REFERENCE_NUMBER_ERROR = 'GET_BANK_REFERENCE_NUMBER_ERROR'

export interface DonationsState {
  perCountries: CountriesResponseEntity
  perCurrencies: InvestmentPerCurrencyResponseEntity
  cryptoInstructions: Partial<ProxyAddressResponseEntity>
  bankInstructions: BankInstructionsResponseEntity
  bankReferenceNumber?: string
}

export interface GetDonorCountriesSuccess extends Action<typeof GET_DONOR_COUNTRIES_SUCCESS> {
  payload: CountriesResponseEntity
}

export interface GetDonationsPerCurrencySuccess extends Action<typeof GET_DONATIONS_PER_CURRENCY_SUCCESS> {
  payload: InvestmentPerCurrencyResponseEntity
}

export interface GetCryptoProxyAddressSuccess extends Action<typeof GET_CRYPTO_PROXY_ADDRESS_SUCCESS> {
  payload: {
    addressData: ProxyAddressResponseEntity
  }
}

export interface GetBankInstructionsSuccess extends Action<typeof GET_BANK_INSTRUCTIONS_SUCCESS> {
  payload: {
    bankInstructions: BankInstructionsResponseEntity
  }
}

export interface GetBankReferenceNumberSuccess extends Action<typeof GET_BANK_REFERENCE_NUMBER_SUCCESS> {
  payload: {
    bankReferenceNumber: string
  }
}

export type DonationsActionTypes =
  GetDonorCountriesSuccess |
  GetDonationsPerCurrencySuccess |
  GetCryptoProxyAddressSuccess |
  GetBankInstructionsSuccess |
  GetBankReferenceNumberSuccess
