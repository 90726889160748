export function downloadFile(text: string, fileName: string, type: string) {
  const el = document.createElement('a')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const file = new Blob([text])
  el.href = URL.createObjectURL(file)
  el.download = `${fileName}.${type}`
  el.click()

  document.body.removeChild(el)
}

export function copy(str: string) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)

  const isIOS = navigator.userAgent.match(/ipad|iphone/i)

  if (isIOS) {
    const range = document.createRange()
    range.selectNodeContents(el)
    const selection = window.getSelection()

    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)
    }
    el.setSelectionRange(0, str.length)
  } else {
    el.select()
  }

  document.execCommand('copy')
  document.body.removeChild(el)
}
