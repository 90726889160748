import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import PagingNumber from './PagingNumber'
import UrlUtil from '../../Common/Utils/Url.utils'
import './styles.scss'

interface Props extends DefaultProps, RouteComponentProps {
  setNumberOfRecords?: (offset: number, limit: number) => void
  title: string
  pagingOptions: number[]
  limitQueryParam: string
  offsetQueryParam: string
}

interface DefaultProps {
  disabled: boolean
  selectedOption: string | number
  offset: number
}

class Paging extends Component<Props> {

  public static defaultProps: DefaultProps = {
    disabled: true,
    selectedOption: '',
    offset: 0
  }

  public setUrlQueryParams = (limit: number) => {
    const {
      history,
      location,
      limitQueryParam,
      offsetQueryParam,
      offset
    } = this.props

    const urlUtil = new UrlUtil(history, location)

    urlUtil.setQueryParamValues({
      [limitQueryParam]: limit,
      [offsetQueryParam]: new BigNumber(offset).minus(new BigNumber(offset).modulo(limit)).toFixed()
    })
  }

  public handleSetRecords = (limit: number) => {
    const {
      setNumberOfRecords,
      offset
    } = this.props

    this.setUrlQueryParams(limit)
    if (setNumberOfRecords) {
      setNumberOfRecords(offset, limit)
    }
  }

  public render() {
    const {
      title,
      pagingOptions,
      selectedOption,
      disabled
    } = this.props

    if (selectedOption == null) {
      return null
    }

    const pagingOptionsHtml = []
    for (let i = 0; i < pagingOptions.length; i++) {
      pagingOptionsHtml.push(
        <PagingNumber
          key={i}
          setNumberOfRecords={this.handleSetRecords}
          pagingNumber={pagingOptions[i]}
          selectedOption={selectedOption}
        />
      )
    }
    const disabledClass = disabled ? 'disabledElement' : ''
    return (
      <div className={`d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0`}>
        <div className={`${disabled ? 'cursorNotAllowed' : ''}`}>
          <div className={`pagingNumber ${disabledClass}`}>
        {title ? <div className='mr-1 title'>{title}</div> : null}
        {pagingOptionsHtml}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Paging)
