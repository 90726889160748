import { Action } from 'redux'
import { SUPPORTED_LANGUAGES } from '../../Common/Constants'

export const SET_LANGUAGE_REQUEST = 'SET_LANGUAGE_REQUEST'
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS'
export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR'

export const SET_DISPLAY_CURRENCY_REQUEST = 'SET_DISPLAY_CURRENCY_REQUEST'
export const SET_DISPLAY_CURRENCY_SUCCESS = 'SET_DISPLAY_CURRENCY_SUCCESS'
export const SET_DISPLAY_CURRENCY_ERROR = 'SET_DISPLAY_CURRENCY_ERROR'

export interface UserEventSettingsState {
  language?: SUPPORTED_LANGUAGES
  displayCurrency: string
}

export interface SetLanguageSuccess extends Action<typeof SET_LANGUAGE_SUCCESS> {
  payload: {
    language: SUPPORTED_LANGUAGES
  }
}

export interface SetDisplayCurrencySuccess extends Action<typeof SET_DISPLAY_CURRENCY_SUCCESS> {
  payload: {
    currencyCode: string
  }
}

export type LocalizationActionTypes =
  SetLanguageSuccess |
  SetDisplayCurrencySuccess
