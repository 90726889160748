import { History, Location } from 'history'

class UrlUtil {
  private history: History
  private location: Location

  /**
   * @param history React router history
   * @param location React router location
   */
  constructor(history: History, location: Location) {
    this.history = history
    this.location = location
  }

  /**
   * @typedef {Object} ParamValue
   * @property {string} name
   * @property {string} value
   *
   * @description Returns value of query param based on param name
   *
   * @param paramName
   */
  public getQueryParam = (paramName: string): {name: string, value: string} | undefined => {
    const searchHistory = new URLSearchParams(this.location.search)
    const value = searchHistory.get(paramName)

    if (value === undefined || value === null) {
      return undefined
    } else {
      return {
        name: paramName,
        value
      }
    }
  }

  /**
   * @description Sets value of query param based on param name
   *
   * @param paramName
   * @param paramValue
   */
  public setQueryParamValue = (paramName: string, paramValue: string | number) => {
    const searchHistory = new URLSearchParams(this.location.search)
    searchHistory.set(paramName, paramValue.toString())
    this.history.push({search: searchHistory.toString()})
  }

  /**
   * @description Updates query param values in the url
   *
   * @param {Object.<string, string>} paramValues PARAMETER_NAME: VALUE
   */
  public setQueryParamValues = (paramValues: {[key: string]: string | number}) => {
    const searchHistory = new URLSearchParams(this.location.search)

    const paramNames = Object.keys(paramValues)
    paramNames.forEach((param) => {
      searchHistory.set(param, paramValues[param].toString())
    })

    this.history.push({search: searchHistory.toString()})
  }
}

export default UrlUtil
