const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_VERSION,
  REACT_APP_ONBOARDING_BASE_URL,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_COUNTRIES,
  REACT_APP_TESTING
} = process.env

if (!REACT_APP_API_BASE_URL) {
  throw Error('REACT_APP_API_BASE_URL property not found in .env')
}

if (!REACT_APP_API_VERSION) {
  throw Error('REACT_APP_API_VERSION property not found in .env')
}

if (!REACT_APP_ONBOARDING_BASE_URL) {
  throw Error('REACT_APP_ONBOARDING_BASE_URL property not found in .env')
}

export default {
  API_BASE_URL: REACT_APP_API_BASE_URL,
  API_VERSION: REACT_APP_API_VERSION,
  ONBOARDING_BASE_URL: REACT_APP_ONBOARDING_BASE_URL,
  STRIPE_PUBLIC_KEY: REACT_APP_STRIPE_PUBLIC_KEY!,
  COUNTRIES: (REACT_APP_COUNTRIES || '').split(',').map((x) => x.trim()),
  TESTING: REACT_APP_TESTING || false
}
