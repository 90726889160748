import React, { Component } from 'react'

class DonateTosEn extends Component {
  public render() {
    return (
      <div>
        <p>You are making a donation to <strong>Africa GreenTec Help e.V.</strong> to support the project #connect2evolve in Senegal. Your credit card/billing statement will show your donation was made to Africa GreenTec Help e.V.</p>
        <p>Please refer to website of Africa GreenTec Help e.V. regarding the purpose of the non-profit organization. (<a href='https://www.agthelpev.de' target='_blank' rel='noopener noreferrer'>www.agthelpev.de</a>)</p>
        <p>Once a donation is made, the transaction is final and cannot be disputed or reversed.</p>
        <p>In case the total aspired amount is not collected and the project cannot be implemented as described, the money collected will be used for other projects within the purpose of Africa GreenTec Help e.V.</p>
        <p>Africa GreenTec Help e.V. reserves the right for the final decision as to the site of the Solartainer®, in case unforeseen circumstances prevent its installation in Bacco Ndieme.</p>
        <p><strong>Payment modalities</strong></p>
        <p>All donations made through this site involve processing fees. The average amount of our donation receipts is approximately 95% of the original amount. Please consider this when making your donation to limit transaction fees.</p>
        <p>When you make a donation, it may be necessary due to legal requirements (e.g. for prevention of money laundering) to verify your identity or the details which you have provided, in particular in case of donations greater than EUR 10 000. You undertake to provide the necessary information or documents. In case proof of identity is not sufficiently provided, the donation will be returned to the donor, short of any transaction and exchange fees.</p>
        <p>Africa GreenTec Help e.V. will keep donor information for General Data Protection Regulation (GDPR) purposes from any donor required to go through the KYC/AML process (Know Your Customer/Anti- Money Laundering). This information shall be kept with Sum and Substance, the service provider for these verifications, for at least five (5) years.</p>
        <p>IP (internet protocol) data will be collected for statistical purposes only. It will not be linked to any name or e-mail address or any other information gathered on these pages.</p>
        <p>Tax receipts can be requested for all donations greater than EUR 200. Please contact Africa GreenTec Help e.V. (info@africagreentec.help).</p>
        <p>In Germany, any donations under EUR 200 do not require a specific receipt; a copy of the transaction on the account used for the donation is enough proof of donation.</p>
        <p>Siemens Gas and Power GmbH & Co. KG Version 0, January 2020</p>
      </div>
    )
  }
}

export default DonateTosEn
