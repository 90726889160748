import React, { Component } from 'react'
import { Container } from 'reactstrap'
import { BasePropsTypes } from '../../Common/Types/BaseProps.types'
import Spinner from '../Spinner'
import './styles.scss'

interface Props extends BasePropsTypes {
  isLoading?: boolean
}

class Page extends Component<Props> {

  public static defaultProps = {
    className: '',
    isLoading: false
  }

  public render() {
    const {
      className,
      isLoading,
      children
    } = this.props

    const classes = `page ${className}`

    return (
      <Container className={classes}>
        {isLoading &&
          <Spinner fullPage={true}/>
        }

        {children}
      </Container>
    )
  }
}

export default Page
