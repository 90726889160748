import { Reducer } from 'redux'
import { AuthActionTypes, AuthState, GET_ME_ERROR, GET_ME_SUCCESS, LOGOUT_USER_SUCCESS } from './Auth.types'

const initialState: AuthState = {
  loggedIn: false,
  me: {}
}

export const authReducer: Reducer<AuthState, AuthActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_ME_SUCCESS: {
      const me = action.payload

      return {
        ...state,
        loggedIn: true,
        me
      }
    }

    case LOGOUT_USER_SUCCESS:
    case GET_ME_ERROR: {
      return {
        ...state,
        loggedIn: initialState.loggedIn,
        me: {
          ...initialState.me
        }
      }
    }

    default: {
      return state
    }
  }
}
