import React, { Component } from 'react'
import facebookImg from '../../Common/Assets/facebook.svg'
import twitterImg from '../../Common/Assets/twitter.svg'
import linkedinImg from '../../Common/Assets/linkedin.svg'
import xangImg from '../../Common/Assets/xing.svg'
import wechatImg from '../../Common/Assets/wechat.svg'
import './styles.scss'

enum NETWORKS {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  XING = 'XING',
  WECHAT = 'WECHAT'
}

interface ShareProvider {
  width: number
  height: number
  sharerUrl: string
  icon: string
}

function getProvider(network: NETWORKS, url: string, hashTags: string, text: string): ShareProvider | undefined {
  const commonProps = {
    width: 640,
    height: 480
  }

  const encodedUrl = encodeURIComponent(url)
  const encodedHashTags = encodeURIComponent(hashTags)
  const encodedText = encodeURIComponent(text)

  switch (network) {
    case NETWORKS.LINKEDIN: {
      return {
        ...commonProps,
        sharerUrl: `https://www.linkedin.com/shareArticle?url=${encodedUrl}&text=${encodedText}&mini=true`,
        icon: linkedinImg
      }
    }
    case NETWORKS.TWITTER: {
      return {
        ...commonProps,
        sharerUrl: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}&hashtags=${encodedHashTags}`,
        icon: twitterImg
      }
    }
    case NETWORKS.FACEBOOK: {
      return {
        ...commonProps,
        sharerUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
        icon: facebookImg
      }
    }
    case NETWORKS.XING: {
      return {
        ...commonProps,
        sharerUrl: `https://www.xing.com/spi/shares/new?url=${encodedUrl}`,
        icon: xangImg
      }
    }
    case NETWORKS.WECHAT: {
      return {
        ...commonProps,
        sharerUrl: `https://chart.apis.google.com/chart?cht=qr&chs=240x240&chld=Q%7C0&chl=${encodedUrl}`,
        icon: wechatImg
      }
    }
    default: {
      return undefined
    }
  }
}

type Props = {
  url: string
  text: string
  hashTags: string
}

class ShareButtons extends Component<Props> {

  public openPopup = (sharerUrl: string, width: number, height: number) => {
    const left = Math.round(window.screen.width / 2 - width / 2)
    const top = Math.round(window.screen.height / 2 - height / 2)
    const windowConfig = `width=${width},height=${height},left=${left},top=${top}`

    return window.open(
      sharerUrl,
      'sharer',
      `${windowConfig},toolbar=no,menubar=no,scrollbars=no`
    )
  }

  public render() {
    const {
      url,
      text,
      hashTags
    } = this.props
    return (
      <div className='shareButtonsWrapper'>
        {Object.keys(NETWORKS).map((key) => {
          const network = key as NETWORKS
          const provider = getProvider(network, url, hashTags, text)

          if (provider) {
            return (
              <img
                key={key}
                alt={key}
                onClick={() => this.openPopup(provider.sharerUrl, provider.width, provider.height)}
                src={provider.icon}
              />
            )
          }

          return null
        })}
      </div>
    )
  }
}

export default ShareButtons
