import React, { Component } from 'react'
import { ethers } from 'ethers'
import { MetaMaskButton, Loader, Button, Tooltip } from 'rimble-ui'
import { Col, Row } from 'reactstrap'
import { Trans, t } from '@lingui/macro'
import { i18n } from '../../HOC/I18nLoader'

import Panel from '../../Elements/Panel'
import PanelTitle from '../../Elements/Panel/PanelTitle'
import UserEventService from '../../Services/UserEvent.service'
import PanelBody from '../../Elements/Panel/PanelBody'
import InfoCell from '../../Elements/InfoCell'
import { MeResponseEntity } from '../../Core/API/APIResponse.types'
import BigNumber from 'bignumber.js'
import ClaimHistoryService from '../../Services/ClaimHistory.service'

declare global {
  interface Window {
    ethereum?: any
    toastProvider: any
  }
}

interface IProps {
  userShareOfDonatedAmount: string
  user: Partial<MeResponseEntity>
  onClaim: () => {}
}

interface IState {
  connectedAccount: string
  balance: string
  unclaimed: string
  balanceLoading: boolean
  unclaimedBalanceLoading: boolean
  claimInProgress: boolean
}

class WalletPanel extends Component<IProps, IState> {
  public state: IState = {
    connectedAccount: '',
    balance: '0.0',
    unclaimed: '0.0',
    balanceLoading: false,
    unclaimedBalanceLoading: true,
    claimInProgress: false
  }

  public async componentDidMount() {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_C2E_TOKEN_NODE_URL
    )
    const c2eTokenContract = new ethers.Contract(
      process.env.REACT_APP_C2E_TOKEN_CONTRACT_ADDRESS || '',
      [
        'function decimals() view returns (uint8)',
        'function unclaimedOf(string memory userId) public view returns (uint256)'
      ],
      provider
    )

    const decimals = await c2eTokenContract.decimals()
    const unclaimed = ethers.utils.formatUnits(
      await c2eTokenContract.unclaimedOf(this.props.user.mapId),
      decimals
    )

    this.setState({ unclaimed, unclaimedBalanceLoading: false })
  }

  public toastMetamaskError = async (ex: Error) => {
    window.toastProvider.addMessage(i18n._(t`MetaMask`), {
      secondaryMessage: ex.message,
      variant: 'failure',
      colorTheme: 'light'
    })
  }

  public setupNetworkProvider = async () => {
    const provider = new ethers.providers.Web3Provider(
      window.ethereum,
      process.env.REACT_APP_NETWORK_ID === '1' ? 'mainnet' : 'rinkeby'
    )

    const chainId = await provider.send('eth_chainId', [])
    if (chainId !== process.env.REACT_APP_CHAIN_ID) {
      try {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: process.env.REACT_APP_CHAIN_ID }
        ])
      } catch (ex) {
        this.toastMetamaskError(ex)
      }
    }

    return provider
  }

  public getC2eTokenContract = async (
    provider: ethers.providers.Web3Provider
  ) => {
    const signer = provider.getSigner()
    const c2eTokenContract = new ethers.Contract(
      process.env.REACT_APP_C2E_TOKEN_CONTRACT_ADDRESS || '',
      [
        'function decimals() view returns (uint8)',
        'function balanceOf(address account) public view returns (uint256)',
        'function claim(string memory userId) public',
        'function unclaimedOf(string memory userId) public view returns (uint256)',
        'function getAccount(string memory userId) public view returns (address)',
        'function setAccount(string memory userId, address account) public'
      ],
      signer
    )

    const connectedContract = await c2eTokenContract.connect(signer)
    return connectedContract
  }

  public getConnectedAccount = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      return accounts[0]
    } catch {
      return null
    }
  }

  public displayConfirmationDialog = (
    currentAddress: string,
    newAddress: string
  ) => {
    const connectMessage = i18n._(
      t`You are connecting with a different address than previously. If you continue, your token balance will be transferred to your new address: ${newAddress}.`
    )

    if (!window.confirm(connectMessage)) {
      alert(
        `Your address has not been changed. Connect with ${currentAddress} to see your balances.`
      )
      return false
    }
    return true
  }

  public setUserAddressOnChainAndBackend = async (newAddress: string) => {
    try {
      const provider = await this.setupNetworkProvider()
      const c2eTokenContract = await this.getC2eTokenContract(provider)

      const setAccTx = await c2eTokenContract.setAccount(
        this.props.user.mapId,
        newAddress
      )
      await setAccTx.wait(1)
      await UserEventService.setWalletAddress(newAddress)

      this.onMetamaskConnected(newAddress)
    } catch (ex) {
      this.toastMetamaskError(ex)
    }
  }

  public onMetamaskConnected = async (newAddress: string) => {
    const provider = await this.setupNetworkProvider()
    const c2eTokenContract = await this.getC2eTokenContract(provider)

    const decimals = await c2eTokenContract.decimals()
    const balance = ethers.utils.formatUnits(
      await c2eTokenContract.balanceOf(newAddress),
      decimals
    )
    const unclaimed = ethers.utils.formatUnits(
      await c2eTokenContract.unclaimedOf(this.props.user.mapId),
      decimals
    )

    this.setState({ connectedAccount: newAddress, balance, unclaimed })
    window.toastProvider.addMessage(i18n._(t`MetaMask Account connected`), {
      secondaryMessage: newAddress,
      variant: 'success',
      colorTheme: 'light'
    })
  }

  public handleMetamaskConnectClick = async () => {
    // TODO: make this function shorter
    if (typeof window.ethereum === 'undefined') {
      window.open('https://metamask.io/download')
      return
    }
    this.setState({ balanceLoading: true })

    const connectedAddress = await this.getConnectedAccount()
    if (!connectedAddress) {
      this.toastMetamaskError(
        new Error(
          i18n._(t`MetaMask is not connected. Please connect your account.`)
        )
      )
      this.setState({ balanceLoading: false })
      return
    }

    const user = this.props.user
    if (!user.walletAddress) {
      await this.setUserAddressOnChainAndBackend(connectedAddress)
      this.setState({ balanceLoading: false })
      return
    }

    if (connectedAddress !== user.walletAddress) {
      if (
        this.displayConfirmationDialog(user.walletAddress, connectedAddress)
      ) {
        await this.setUserAddressOnChainAndBackend(connectedAddress)
      }
      this.setState({ balanceLoading: false })
      return
    }

    this.onMetamaskConnected(connectedAddress)
    this.setState({ balanceLoading: false })
  }

  public handleAddTokenToMetamaskClick = async () => {
    const tokenAddress = process.env.REACT_APP_C2E_TOKEN_CONTRACT_ADDRESS
    const tokenSymbol = 'C2E'
    const tokenDecimals = 12

    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals // The number of decimals in the token
          }
        }
      })
    } catch (ex) {
      this.toastMetamaskError(ex)
    }
  }

  public handleClaimButtonClick = async () => {
    this.setState({ claimInProgress: true })

    const provider = await this.setupNetworkProvider()

    const c2eTokenContract = await this.getC2eTokenContract(provider)

    try {
      const claimTx = await c2eTokenContract.claim(this.props.user.mapId)
      await claimTx.wait(1)

      ClaimHistoryService.createUserClaimHistoryItemForEvent(
        this.props.user.id,
        '3',
        this.state.unclaimed,
        this.state.connectedAccount,
        claimTx.hash
      ).then(this.props.onClaim)

      const decimals = await c2eTokenContract.decimals()
      const balance = ethers.utils.formatUnits(
        await c2eTokenContract.balanceOf(this.state.connectedAccount),
        decimals
      )
      const unclaimed = ethers.utils.formatUnits(
        await c2eTokenContract.unclaimedOf(this.props.user.mapId),
        decimals
      )

      this.setState({ balance, unclaimed })
      window.toastProvider.addMessage(i18n._(t`Claim Transaction`), {
        secondaryMessage: i18n._(t`Successfully executed and confirmed`),
        variant: 'success',
        colorTheme: 'light'
      })
    } catch (err) {
      window.toastProvider.addMessage(i18n._(t`Claim Transaction`), {
        secondaryMessage: i18n._(t`Can't process the transaction`),
        variant: 'failure',
        colorTheme: 'light'
      })
    } finally {
      this.setState({ claimInProgress: false })
    }
  }

  public render() {
    const { userShareOfDonatedAmount } = this.props

    const {
      balance,
      unclaimed,
      balanceLoading,
      unclaimedBalanceLoading,
      connectedAccount,
      claimInProgress
    } = this.state

    return (
      <Panel>
        <PanelTitle>
          <div style={{ fontSize: '16px' }}>
            <Trans>Current balance</Trans>
            <Tooltip
              message={i18n._(
                t`Amount of #c2e tokens claimed in your connected ethereum address`
              )}
              placement='top'
            >
              <span style={{ textDecoration: 'underline', marginLeft: '4px' }}>
                ?
              </span>
            </Tooltip>
          </div>
        </PanelTitle>
        <PanelBody>
          {!balanceLoading ? (
            <div className='balance'>
              {connectedAccount ? (
                <div className='amount'>
                  {new BigNumber(balance).toFormat(3)} c2e
                </div>
              ) : (
                <>
                  <Trans>Connect a wallet to see your balance</Trans>
                  <MetaMaskButton.Outline
                    marginLeft='5px'
                    marginTop='10px'
                    marginBottom='10px'
                    size='small'
                    onClick={this.handleMetamaskConnectClick}
                  >
                    {typeof window.ethereum !== 'undefined' ? (
                      <Trans>Connect with MetaMask</Trans>
                    ) : (
                      <Trans>Install MetaMask</Trans>
                    )}
                  </MetaMaskButton.Outline>
                </>
              )}
            </div>
          ) : (
            <Loader size='40px' marginTop='10px' color='#005578' />
          )}
        </PanelBody>

        <Row style={{ marginBottom: '15px' }}>
          <Col lg={12}>
            <Col style={{ padding: 0, marginTop: '15px' }}>
              <Trans>Unclaimed</Trans>
              <Tooltip
                message={i18n._(t`Amount of #c2e tokens available to claim `)}
                placement='top'
              >
                <span
                  style={{ textDecoration: 'underline', marginLeft: '4px' }}
                >
                  ?
                </span>
              </Tooltip>
              <div className='balance'>
                {!unclaimedBalanceLoading ? (
                  <>
                    <div className='amount-unclaimed'>
                      {new BigNumber(unclaimed).toFormat(3)} c2e
                    </div>
                    {connectedAccount && (
                      <Tooltip
                        message={i18n._(
                          t`Transfer your allocated c2e tokens to your wallet.`
                        )}
                        placement='top'
                      >
                        <Button
                          disabled={Number(unclaimed) === 0 || claimInProgress}
                          onClick={this.handleClaimButtonClick}
                          mainColor='#F08100'
                          size='small'
                          className='claim-button'
                        >
                          {claimInProgress ? (
                            <Loader size='20px' color='#005578' />
                          ) : (
                            <Trans>Claim</Trans>
                          )}
                        </Button>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Loader margin='10px' size='24px' color='#005578' />
                )}
              </div>
            </Col>
          </Col>
        </Row>

        <div className='share'>
          <InfoCell
            label={<Trans>My share of total amount donated</Trans>}
            value={`${userShareOfDonatedAmount}%`}
          />
          <MetaMaskButton.Outline
            marginTop='10px'
            size='small'
            onClick={this.handleAddTokenToMetamaskClick}
          >
            <Trans>Add C2E Token to Metamask</Trans>
          </MetaMaskButton.Outline>
        </div>
      </Panel>
    )
  }
}

export default WalletPanel
