import React, { FC } from 'react'
import './styles.scss'

const PanelTitle: FC = (props) => {
  return (
    <div className='panelTitle'>
      {props.children}
    </div>
  )
}

export default PanelTitle
