import React, {Component} from 'react'
import { findDOMNode } from 'react-dom'
import { Trans, t } from '@lingui/macro'
import { SelectDropdownProps, SelectDropdownState, SelectInputOption } from './types'
import './styles.scss'
import {withI18n} from '@lingui/react'

class SelectDropdown extends Component<SelectDropdownProps, SelectDropdownState> {

  public static defaultProps = {
    className: ''
  }

  public state: SelectDropdownState = {
    isDropdownOpen: false,
    search: ''
  }

  private input: HTMLInputElement | null = null

  public componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  public handleClickOutside = (event: any) => {
    const domNode = findDOMNode(this)

    if (!domNode || !domNode.contains(event.target)) {
      this.toggleDropdown(false)
    }
  }

  public toggleDropdown = (open: boolean) => {
    this.setState((prevState) => ({
      isDropdownOpen: open
    }))
    if (open && this.input) {
      this.input.focus()
    }
  }

  public handleOptionSelect = (option: SelectInputOption) => {
    const {
      onOptionSelect,
      name
    } = this.props

    this.setState({search: ''})
    onOptionSelect(name, option)

    this.toggleDropdown(false)
  }

  public render() {
    const {
      className,
      label,
      options,
      selectedOption,
      disabled,
      i18n
    } = this.props

    const {
      isDropdownOpen,
      search
    } = this.state

    const hasSearch = search.trim() !== ''

    const optionsFiltered = options.filter((option) => {
      if (!hasSearch || !option.labelString) {
        return true
      } else {
        return option.labelString.match(new RegExp(search, 'i'))
      }
    })

    const dropDownClassName = [
      'dropdownMenu',
      disabled ? 'disabledElement' : '',
      isDropdownOpen ? 'open' : ''
    ].join(' ')

    return (
      <div className={`selectInput ${className}`}>
        {label ?
          <label>
            {label}
          </label>
          : null
        }

        <div className={dropDownClassName}>
          <div className='input' onClick={() => this.toggleDropdown(!isDropdownOpen)}>
            {options.filter((option) => !option.header).length === 0 ?
              <div className='selectOption'>
                <Trans>Loading...</Trans>
              </div> :
              <div>
                {selectedOption ?
                <div className='selectedOption'>
                  {selectedOption.label}
                </div> : null}
                <div className='selectSearch' style={{opacity: isDropdownOpen ? 1 : 0}}>
                  <input
                    ref={(input) => this.input = input}
                    value={search}
                    placeholder={i18n._(t`Select option or start typing`)}
                    onChange={(e) => this.setState({search: e.target.value})}
                    onKeyDown={(e) => this.toggleDropdown(true)}
                  />
                </div>
              </div>
              }
            <div className='arrowIndicator'>
              {isDropdownOpen ? <div className='icon-arrow-up-large'/> : <div className='icon-arrow-down-large'/>}
            </div>
          </div>

          <div className='dropdownList'>
            {optionsFiltered
              .map((option) => {
              if (option.header) {
                return (
                  <div className='option-header' key={option.value}>
                    {option.label}
                  </div>
                )
              } else {
                return (
                  <div
                    className='option'
                    key={option.value}
                    onClick={() => this.handleOptionSelect(option)}
                  >
                    {option.label}
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default (withI18n()(SelectDropdown))
