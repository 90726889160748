import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import { BasePropsTypes } from '../../Common/Types/BaseProps.types'
import './styles.scss'

interface Props extends BasePropsTypes {
  isOpen: boolean
  toggleFunction?: any
}

const Dialog: FC<Props> = (props) => {
    const {
      isOpen = false,
      toggleFunction,
      className = ''
    } = props

    return (
      <Modal
        toggle={toggleFunction}
        className={className}
        contentClassName='dialog'
        isOpen={isOpen}
        centered={true}
        modalTransition={{
          timeout: 150
        }}
      >
        {props.children}
      </Modal>
    )
}

export default Dialog
