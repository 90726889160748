class NotFoundError extends Error {
}

class ToasterError extends Error {
}

export {
  NotFoundError,
  ToasterError
}
