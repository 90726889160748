import { Api } from '../Common/Utils/Api.utils'
import { MeResponseEntity, Response } from '../Core/API/APIResponse.types'

class AuthService {
  public static logout = (): Promise<void> => {
    return Api.post(`/me/logout`).then((response) => response.data)
  }

  public static getMe = (eventId?: string): Promise<Response<MeResponseEntity>> => {
    return Api.post(`/me`, {
      eventId
    }).then((response) => response.data)
  }

  public static requestPersonalDataRemoval = (eventId: string): Promise<Response<void>> => {
    return Api.put(`/me/events/${eventId}/delete`).then((response) => response.data)
  }

  public static changePassword = (oldPassword: string, newPassword: string): Promise<Response<void>> => {
    return Api.put(`/me/password/`, {
      oldPassword,
      newPassword
    }).then((response) => response.data)
      .catch((err) => {
        throw err.response.data
      })
  }

  public static changeEmail = (email: string, password: string): Promise<Response<void>> => {
    return Api.put(`/me/email/`, {
      email,
      password
    }).then((response) => response.data)
      .catch((err) => {
        throw err.response.data
      })
  }

}

export default AuthService
