import { t } from '@lingui/macro'
import { SUPPORTED_LANGUAGES } from '../Constants'
import { i18n } from '../../HOC/I18nLoader'

export function mapLanguageCodeToFull(code: string): string | null {

  switch (code) {
    case SUPPORTED_LANGUAGES.en: {
      return i18n._(t`English`)
    }
    case SUPPORTED_LANGUAGES.de: {
      return i18n._(t`German`)
    }
    case SUPPORTED_LANGUAGES.fr: {
      return i18n._(t`French`)
    }
    default: {
      return null
    }
  }
}
