import { Reducer } from 'redux'
import { TOGGLE_MODAL, UIActionTypes, UIState } from './UI.types'

const initialState: UIState = {
  modals: {}
}

export const uiReducer: Reducer<UIState, UIActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL: {
      const { name, open } = action.payload

      return {
        modals: {
          ...state.modals,
          [name]: open
        }
      }
    }

    default: {
      return state
    }
  }
}
