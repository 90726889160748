import { TOGGLE_MODAL, ToggleModal } from './UI.types'
import { MODALS } from '../../Common/Constants'

export function toggleModal(name: MODALS, open: boolean): ToggleModal {
  return {
    type: TOGGLE_MODAL,
    payload: {
      name,
      open
    }
  }
}
