import React, { FC } from 'react'
import { InvestmentChartProps } from './types'
import './styles.scss'

const InvestmentChart: FC<InvestmentChartProps> = (props) => {

  const {
    legendList = []
  } = props

  const legendHtml = []
  const chartHtml = []

  for (let i = 0; i < legendList.length; i++) {
    const legendColor = legendList[i].color

    legendHtml.push(
      <div key={`legend-${i}`} className='chartItem'>
        <div className='d-flex align-items-center overflow-hidden'>
          <div className='legendColor' style={{backgroundColor: legendColor}}/>
          <div className='legendTitle' title={`${legendList[i].title} (${legendList[i].value}%)`}>
            {legendList[i].title}
          </div>
        </div>

        <div className='legendValue'>
          {legendList[i].value}%
        </div>
      </div>
    )

    chartHtml.push(
      <div key={`chart-${i}`} className='data' style={{backgroundColor: legendColor, width: `${legendList[i].value}%`}}/>
    )
  }

  return (
    <div className='investmentChart'>
      <div className='chart'>
        {chartHtml}
      </div>
      <div>
        {legendHtml}
      </div>
    </div>
  )
}

export default InvestmentChart
