import { Reducer } from 'redux'
import {
  LocalizationActionTypes,
  UserEventSettingsState,
  SET_LANGUAGE_SUCCESS,
  SET_DISPLAY_CURRENCY_SUCCESS
} from './UserEventSettings.types'

const initialState: UserEventSettingsState = {
  displayCurrency: 'EUR'
}

export const userEventSettingsReducer: Reducer<UserEventSettingsState, LocalizationActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE_SUCCESS: {
      const { language } = action.payload

      return {
        ...state,
        language
      }
    }

    case SET_DISPLAY_CURRENCY_SUCCESS: {
      const { currencyCode } = action.payload

      return {
        ...state,
        displayCurrency: currencyCode
      }
    }

    default: {
      return state
    }
  }
}
