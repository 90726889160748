import React, { FC } from 'react'

const PageHeading: FC = (props) => {

  return (
    <div className='pageHeading'>
      {props.children}
    </div>
  )
}

export default PageHeading
