import React, { FC } from 'react'

const DialogBody: FC = (props) => {

  return (
    <div className='dialogBody'>
      {props.children}
    </div>
  )
}

export default DialogBody
