import { Api } from '../Common/Utils/Api.utils'
import {
  PaginatedResponse,
  ClaimHistoryResponseEntity,
  PagingInfo
} from '../Core/API/APIResponse.types'

class ClaimHistoryService {
  public static getUserClaimHistoryForEvent = (userId: string, eventId: string, pagination: PagingInfo): Promise<PaginatedResponse<ClaimHistoryResponseEntity[]>> => {
    return Api.get(`/users/${userId}/events/${eventId}/siemens/claim-history`, {
      params: {
        sortColumn: pagination.sort.sortColumn,
        sortOrder: pagination.sort.sortOrder,
        limit: pagination.pagination.limit,
        offset: pagination.pagination.offset
      }
    }).then((response) => response.data)
  }

  public static createUserClaimHistoryItemForEvent = (userId: string | undefined, eventId: string, amount: string, walletAddress: string, transactionHash: string): Promise<ClaimHistoryResponseEntity> => {
    return Api.post(`/users/${userId}/events/${eventId}/siemens/claim-history/item`, {
      amount,
      walletAddress,
      transactionHash
    }).then((response) => response.data)
  }
}

export default ClaimHistoryService
