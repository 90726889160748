import { Action } from 'redux'
import {
  EventFinancialResponseEntity,
  EventImpactKPIsResponseEntity,
  EventResponseEntity, SiemensImpactCalculationResponseEntity
} from '../API/APIResponse.types'

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST'
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const GET_EVENT_ERROR = 'GET_EVENT_ERROR'

export const GET_EVENT_FINANCIAL_DETAILS_REQUEST = 'GET_EVENT_FINANCIAL_DETAILS_REQUEST'
export const GET_EVENT_FINANCIAL_DETAILS_SUCCESS = 'GET_EVENT_FINANCIAL_DETAILS_SUCCESS'
export const GET_EVENT_FINANCIAL_DETAILS_ERROR = 'GET_EVENT_FINANCIAL_DETAILS_ERROR'

export const GET_EVENT_IMPACT_KPI_REQUEST = 'GET_EVENT_IMPACT_KPI_REQUEST'
export const GET_EVENT_IMPACT_KPI_SUCCESS = 'GET_EVENT_IMPACT_KPI_SUCCESS'
export const GET_EVENT_IMPACT_KPI_ERROR = 'GET_EVENT_IMPACT_KPI_ERROR'

export const CALCULATE_DONATION_IMPACT_REQUEST = 'CALCULATE_DONATION_IMPACT_REQUEST'
export const CALCULATE_DONATION_IMPACT_SUCCESS = 'CALCULATE_DONATION_IMPACT_SUCCESS'
export const CALCULATE_DONATION_IMPACT_ERROR = 'CALCULATE_DONATION_IMPACT_ERROR'

export interface EventDetailsState {
  event: Partial<EventResponseEntity>
  financialDetails: EventFinancialResponseEntity
  impactKPIs: EventImpactKPIsResponseEntity

  calculatedDonationImpact: SiemensImpactCalculationResponseEntity
}

export interface GetEventSuccess extends Action<typeof GET_EVENT_SUCCESS> {
  payload: EventResponseEntity
}

export interface GetEventFinancialDetailsSuccess extends Action<typeof GET_EVENT_FINANCIAL_DETAILS_SUCCESS> {
  payload: EventFinancialResponseEntity
}

export interface GetEventImpactKPISuccess extends Action<typeof GET_EVENT_IMPACT_KPI_SUCCESS> {
  payload: EventImpactKPIsResponseEntity
}

export interface CalculateDonationImpactSuccess extends Action<typeof CALCULATE_DONATION_IMPACT_SUCCESS> {
  payload: {
    calculatedImpact: SiemensImpactCalculationResponseEntity
  }
}

export type EventDetailsActionTypes =
  GetEventSuccess |
  GetEventFinancialDetailsSuccess |
  GetEventImpactKPISuccess |
  CalculateDonationImpactSuccess
