import React, { Component } from 'react'
import './styles.scss'

type TButtonTypes = 'outline' | 'ghost' | 'link'
type TButtonTheme = 'accent' | 'danger'

interface Props {
  buttonType?: TButtonTypes
  buttonTheme?: TButtonTheme
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
}

class Button extends Component<Props> {

  public static defaultProps = {
    onClick: () => ({}),
    buttonTheme: 'accent',
    buttonType: '',
    className: '',
    disabled: false
  }

  public render() {
    const {
      buttonType,
      buttonTheme,
      className,
      children,
      onClick,
      disabled
    } = this.props

    const btnClassName = [
      'appButton',
      className,
      disabled ? 'disabledElement' : '',
      buttonType ? `btn-${buttonType}-${buttonTheme}` : `btn-${buttonTheme}`
    ].join(' ')

    return (
      <button
        className={btnClassName}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }
}

export default Button
