import axios from 'axios'
import Config from '../Config'
import store from '../../Core/store'
import { logout } from '../../Core/Auth/Auth.actions'

export const Api = axios.create({
  baseURL: `${Config.API_BASE_URL}/api/${Config.API_VERSION}`,
  withCredentials: true
})

export const PublicApi = axios.create({
  baseURL: `${Config.API_BASE_URL}`
})

export const initInterceptors = () => {
  Api.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          // dispatch logout action
          // @ts-ignore
          await store.dispatch(logout())
        }

        if (error.response.status === 403) {
          // await store.dispatch(replace('/unauthorized'))
        }

        // if (error.response.status === 500) {
        //   toastr.error(i18n._(t`Error`), i18n._(t`Something went wrong.`))
        // }
        //
        // if (error.response.status === 422) {
        //   // Validation errors
        //   // Here we can dispatch action to set form errors
        // }
      }

      return Promise.reject(error)
    })
}
