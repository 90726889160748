import { t } from '@lingui/macro'
import { ThunkResult } from '../types'
import EventService from '../../Services/Event.service'
import {
  BankInstructionsResponseEntity,
  CountriesResponseEntity,
  InvestmentPerCurrencyResponseEntity,
  ProxyAddressResponseEntity
} from '../API/APIResponse.types'
import {
  GET_BANK_INSTRUCTIONS_ERROR,
  GET_BANK_INSTRUCTIONS_REQUEST,
  GET_BANK_INSTRUCTIONS_SUCCESS,
  GET_BANK_REFERENCE_NUMBER_ERROR,
  GET_BANK_REFERENCE_NUMBER_REQUEST,
  GET_BANK_REFERENCE_NUMBER_SUCCESS,
  GET_CRYPTO_PROXY_ADDRESS_ERROR,
  GET_CRYPTO_PROXY_ADDRESS_REQUEST,
  GET_CRYPTO_PROXY_ADDRESS_SUCCESS,
  GET_DONATIONS_PER_CURRENCY_ERROR,
  GET_DONATIONS_PER_CURRENCY_REQUEST,
  GET_DONATIONS_PER_CURRENCY_SUCCESS,
  GET_DONOR_COUNTRIES_ERROR,
  GET_DONOR_COUNTRIES_REQUEST,
  GET_DONOR_COUNTRIES_SUCCESS,
  GetBankInstructionsSuccess, GetBankReferenceNumberSuccess,
  GetCryptoProxyAddressSuccess,
  GetDonationsPerCurrencySuccess,
  GetDonorCountriesSuccess
} from './Donations.types'
import UserEventService from '../../Services/UserEvent.service'
import { i18n } from '../../HOC/I18nLoader'

/**
 * @description Thunk for getting donor countries
 * @param eventId
 * @param limit
 */
export function getDonorCountries(eventId: string, limit: number = 5): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_DONOR_COUNTRIES_REQUEST})

      const response = await EventService.getEventDonorCountries(eventId, limit)
      dispatch(getDonorCountriesSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_DONOR_COUNTRIES_ERROR})
      console.error(error)
    }
  }
}

export function getDonorCountriesSuccess(donorCountries: CountriesResponseEntity): GetDonorCountriesSuccess {
  return {
    type: GET_DONOR_COUNTRIES_SUCCESS,
    payload: donorCountries
  }
}

/**
 * @description Thunk for getting donations per currency
 * @param eventId
 */
export function getDonationsPerCurrency(eventId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_DONATIONS_PER_CURRENCY_REQUEST})

      const response = await EventService.getEventDonationsPerCurrency(eventId)
      dispatch(getDonationsPerCurrencySuccess(response.result))

    } catch (error) {
      dispatch({type: GET_DONATIONS_PER_CURRENCY_ERROR})
      console.error(error)
    }
  }
}

export function getDonationsPerCurrencySuccess(donationsPerCurrency: InvestmentPerCurrencyResponseEntity): GetDonationsPerCurrencySuccess {
  return {
    type: GET_DONATIONS_PER_CURRENCY_SUCCESS,
    payload: donationsPerCurrency
  }
}

/**
 * @description Thunk for getting crypto proxy address for specific currency
 * @param userId
 * @param eventId
 * @param currencyId
 */
export function getCryptoProxyAddress(userId: string, eventId: string, currencyId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_CRYPTO_PROXY_ADDRESS_REQUEST})

      const response = await UserEventService.getProxyAddress(userId, eventId, currencyId)
      dispatch(getCryptoProxyAddressSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_CRYPTO_PROXY_ADDRESS_ERROR})
      console.error(error)
      throw new Error(i18n._(t`Failed to fetch proxy address.`))
    }
  }
}

export function getCryptoProxyAddressSuccess(addressData: ProxyAddressResponseEntity): GetCryptoProxyAddressSuccess {
  return {
    type: GET_CRYPTO_PROXY_ADDRESS_SUCCESS,
    payload: {
      addressData
    }
  }
}

/**
 * @description Thunk for getting bank instructions
 * @param userId
 * @param eventId
 * @param currencyId
 */
export function getBankInstructions(userId: string, eventId: string, currencyId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_BANK_INSTRUCTIONS_REQUEST})

      const response = await UserEventService.getBankInstructions(userId, eventId, currencyId)
      dispatch(getBankInstructionsSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_BANK_INSTRUCTIONS_ERROR})
      console.error(error)
      throw new Error(i18n._(t`Failed to fetch bank instructions.`))
    }
  }
}

export function getBankInstructionsSuccess(bankInstructions: BankInstructionsResponseEntity): GetBankInstructionsSuccess {
  return {
    type: GET_BANK_INSTRUCTIONS_SUCCESS,
    payload: {
      bankInstructions
    }
  }
}

/**
 * @description Thunk for getting bank reference number
 * @param userId
 * @param eventId
 * @param currencyId
 */
export function getBankReferenceNumber(userId: string, eventId: string, currencyId: string): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_BANK_REFERENCE_NUMBER_REQUEST})

      const response = await UserEventService.getBankReferenceNumber(userId, eventId, currencyId)
      dispatch(getBankReferenceNumberSuccess(response.result))

    } catch (error) {
      dispatch({type: GET_BANK_REFERENCE_NUMBER_ERROR})
      console.error(error)
      throw new Error(i18n._(t`Failed to fetch bank reference number.`))
    }
  }
}

export function getBankReferenceNumberSuccess(bankReferenceNumber: string): GetBankReferenceNumberSuccess {
  return {
    type: GET_BANK_REFERENCE_NUMBER_SUCCESS,
    payload: {
      bankReferenceNumber
    }
  }
}
