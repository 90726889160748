import React, {Component, Fragment} from 'react'
import {withI18n, withI18nProps} from '@lingui/react'
import {Trans} from '@lingui/macro'
import {injectStripe, PaymentRequestButtonElement, ReactStripeElements} from 'react-stripe-elements'
import QRCode from 'qrcode.react'
import StripeService from '../../Services/Stripe.service'
import Stripe from 'stripe'
import BigNumber from 'bignumber.js'

import {CountryEntity, StripePaymentMethod} from '../../Core/types'
import SelectDropdown from '../../Elements/SelectDropdown'
import DonateStripePaymentMethod from './DonateStripePaymentMethod'
import {countries} from '../../Common/Data/countries'
import {SelectInputOption} from '../../Elements/SelectDropdown/types'
import {getDefaultPaymentMethod} from './utils'
import {STRIPE_PAYMENT_METHODS} from '../../Common/Constants'
import Button from '../../Elements/Button'
import {MeResponseEntity} from '../../Core/API/APIResponse.types'
import Config from '../../Common/Config'

type Props =
  withI18nProps &
  ReactStripeElements.InjectedStripeProps &
  {
    amount: number,
    me: Partial<MeResponseEntity>,
    onBack: () => void,
    onDone: () => void
  }

interface State {
  name: string,
  country?: SelectInputOption<CountryEntity>,
  paymentMethod?: StripePaymentMethod,
  processing: boolean,
  stripeError?: stripe.Error,
  invalidName: boolean,
  bankName?: string,
  source?: Stripe.Source,
  // payment request related
  paymentRequest?: stripe.paymentRequest.StripePaymentRequest,
  canMakePayment: boolean,
  redirecting: boolean,
  paymentIntent?: Stripe.PaymentIntent,
}

class DonateStripeForm extends Component<Props, State> {

  public state: State = {
    // todo: remove this
    name: Config.TESTING ? 'succeeding_charge' : '',
    processing: false,
    canMakePayment: false,
    invalidName: false,
    redirecting: false
  }

  constructor(props: Props) {
    super(props)
    const { stripe } = props
    if (!stripe) {
      return
    }

    // this is specially for the googlepay/applepay button
    const paymentRequest = stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      total: {
        label: '#connect2evolve donation',
        amount: this.stripeAmount()
      },
      requestPayerName: true,
      requestPayerEmail: true
    })
    // console.log({paymentRequest})

    paymentRequest.on('paymentmethod', (event) => this.processPaymentRequest(event))

    paymentRequest.canMakePayment().then((result) => {
      // console.log('Can make payment', result)
      this.setState({canMakePayment: !!result, paymentRequest})
    })
  }

  public processPaymentRequest = async (event: stripe.paymentRequest.StripePaymentMethodPaymentResponse) => {
    const {stripe, onDone} = this.props
    const {paymentIntent} = this.state

    if (!stripe || !paymentIntent || !paymentIntent.client_secret) {
      return false
    }

    const data = {
      payment_method: event.paymentMethod.id
    }
    const response = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      data
      // {handleActions: false}
    )

    if (response.error) {
      event.complete('fail')
      this.setState({stripeError: response.error})
    } else {
      event.complete('success')
      onDone()
    }

  }

  public stripeAmount() {
    return Math.round(this.props.amount * 100)
  }

  public async componentDidMount() {
    const {me} = this.props
    if (me.eventId) {
      const data = {
        amount: this.stripeAmount(),
        currency: 'eur'
      }
      const paymentIntent = await StripeService.createPaymentIntent(me.eventId, data)
      this.setState({paymentIntent})
    }
  }

  public handleValidate = () => {
    const { name } = this.state
    this.setState({
      stripeError: undefined, // it's ugly to put it here, but it's on blur...
      invalidName: name !== '' && name.length < 3
    })
  }

  public handlePaymentMethodChange = (paymentMethod: StripePaymentMethod) => {
    this.setState({paymentMethod})
  }

  public handleElementChange = (response: ReactStripeElements.ElementChangeResponse) => {
    this.setState({stripeError: response.error, bankName: response.bankName})
  }

  public handlePaymentResponse(response: stripe.PaymentIntentResponse) {
    const { error, paymentIntent } = response

    if (error) {
      this.setState({stripeError: error, processing: false})
      return
    } else {
      // type conversion from elements definiton to Stripe definition
      this.setState({processing: false, paymentIntent: paymentIntent as unknown as Stripe.PaymentIntent})
    }
  }

  public getElement(elementType: string) {
    const {elements} = this.props
    if (!elements) {
      return null
    }
    // type elementsType = 'card' | 'cardNumber' | 'cardExpiry' | 'cardCvc' | 'postalCode' | 'paymentRequestButton' | 'iban' | 'idealBank';
    switch (elementType) {
      case 'card': return elements.getElement('card')
      case 'sepa_debit': return elements.getElement('iban')
      case 'ideal': return elements.getElement('idealBank')
    }
    return null
  }

  public async handleSubmit() {
    const { name, invalidName, country, paymentMethod, paymentIntent, source } = this.state
    const { stripe, elements, onDone, me } = this.props
    const { email } = me

    if (!stripe || !elements || !country || !paymentMethod || !email || !name) {
      return false
    }

    if (invalidName) {
      return false
    }

    if (!paymentIntent || !paymentIntent.client_secret) {
      return false
    }

    if (this.paymentSuccess() || this.paymentProcessing()) {
      onDone()
      return
    }

    // clicking wechat after initiated does nothing
    if (source && source.wechat && source.wechat.qr_code_url) {
      return
    }

    if (source && source.multibanco && source.multibanco.reference) {
      return
    }

    this.setState({stripeError: undefined, processing: true})

    if (paymentMethod.element) {
      const element = this.getElement(paymentMethod.id)
      if (!element) {
        return
      }

      let response
      switch (paymentMethod.id) {
        case 'card':
          response = await stripe.confirmCardPayment(
            paymentIntent.client_secret,
            {payment_method: {card: element, billing_details: {name, email}}}
          )
          break
        case 'sepa_debit':
          response = await stripe.confirmSepaDebitPayment(
            paymentIntent.client_secret,
            {payment_method: {sepa_debit: element, billing_details: {name, email}}}
          )
          break
/*
        case 'ideal':
          response = stripe.confirmIdealPayment(
            paymentIntent.client_secret,
            {payment_method: {ideal: element}, return_url: window.location.href}
          )
*/
        default:
          return
      }
      this.handlePaymentResponse(response)

    } else {
      const sourceOptions: stripe.SourceOptions = {
        type: paymentMethod.id,
        amount: this.stripeAmount(),
        currency: 'EUR',
        owner: {name, email},
        redirect: {
          return_url: window.location.href
        },
        statement_descriptor: '#connect2evolve donation',
        metadata: {
          paymentIntent: paymentIntent.id
        }
      }

      // custom additional options for various payment types
      if (paymentMethod.id === 'sofort') {
        sourceOptions.sofort = {country: country.code}
      }

      const {error, source}: stripe.SourceResponse = await stripe.createSource(sourceOptions)
      if (error) {
        this.setState({stripeError: error, processing: false})
        return
      }

      if (source) {
        // @ts-ignore Diffent source definition in stripe-v3 and stripe
        this.setState({source})
        this.processSourceActivation(source)
      }

      this.setState({processing: false})
    }

  }

  public async pollPaymentIntent() {
    const {me, onDone} = this.props
    const {paymentIntent} = this.state

    if (me.eventId && paymentIntent && paymentIntent.id) {
      const newPaymentIntent = await StripeService.getPaymentIntent(me.eventId, paymentIntent.id)
      this.setState({paymentIntent: newPaymentIntent})
      if (newPaymentIntent.status === 'succeeded') {
        onDone()
      } else {
        window.setTimeout(() => this.pollPaymentIntent(), 5000)
      }
    }
  }

  public processSourceActivation(source: stripe.Source) {
    // @ts-ignore: source props (flow, type, wechat) not defined in @types
    const { flow, redirect, type } = source
    if (flow === 'redirect' && redirect) {
      this.setState({redirecting: true})
      window.location.replace(redirect.url)
    } else if (flow === 'code_verification') {
      // todo: display code, see demo
      // there's no such flow though
    } else if (type === 'wechat' || type === 'multibanco') {
      this.pollPaymentIntent()
    }
  }

  public handleCountrySelect = (name: string, option: SelectInputOption<CountryEntity>) => {
    this.setState({
      country: option,
      paymentMethod: getDefaultPaymentMethod(option.code)
    })
  }

  public renderDonateAmount = () => {
    const {amount} = this.props

    return `${new BigNumber(amount).toFixed(2)} EUR`
  }

  public paymentSuccess = () => {
    const {paymentIntent} = this.state
    return paymentIntent && paymentIntent.status === 'succeeded'
  }

  public paymentProcessing = () => {
    const {paymentIntent} = this.state
    return paymentIntent && paymentIntent.status === 'processing'
  }

  public renderSubmitButtonText() {
    const { processing, redirecting, paymentMethod, bankName, source } = this.state
    const type = paymentMethod && paymentMethod.id

    const renderDonateAmount = this.renderDonateAmount

    if (processing || redirecting) {
      return <Trans>Processing...</Trans>
    } else if (this.paymentSuccess()) {
      return <Trans>Payment successful. Click to finish.</Trans>
    } else if (this.paymentProcessing()) {
      return <Trans>Payment is being processed. Click to finish.</Trans>
    } else if (type === 'sepa_debit' && bankName) {
      return (
        <span>
          <Trans>Debit</Trans>&nbsp;{renderDonateAmount()}&nbsp;<Trans>from</Trans>&nbsp;{bankName}
        </span>
      )
    } else if (type === 'multibanco') {
      if (source && source.multibanco && source.multibanco.reference) {
        return (
          <Trans>Waiting for the payment</Trans>
        )
      } else {
        return (
          <Trans>Generate payment details</Trans>
        )
      }
    } else if (type === 'wechat') {
      if (source && source.wechat && source.wechat.qr_code_url) {
        return (
          <span>
            <Trans>Scan the barcode to pay</Trans>&nbsp;{renderDonateAmount()}
          </span>
        )
      } else {
        return (
          <span>
            <Trans>Generate barcode to pay</Trans>&nbsp;{renderDonateAmount()}
          </span>
        )
      }
    } else {
      return (
        <span>
          <Trans>Pay</Trans>
          &nbsp;
          {renderDonateAmount()}
        </span>
      )
    }
  }

  public renderSourceWechat() {
    const {source, paymentMethod} = this.state
    if (!paymentMethod || paymentMethod.id !== 'wechat') {
      return null
    }
    if (!source || !source.wechat || !source.wechat.qr_code_url || source.type !== 'wechat') {
      return null
    }

    return (
      <div className='stripe-qr-code'>
        <QRCode value={source.wechat.qr_code_url} size={192} />
      </div>
    )
  }

  public renderSourceMultibanco() {
    const {source, paymentMethod} = this.state
    if (!paymentMethod || paymentMethod.id !== 'multibanco') {
      return null
    }
    // @ts-ignore
    if (!source || !source.multibanco || source.type !== 'multibanco') {
      return null
    }
    // @ts-ignore
    const {multibanco} = source
    return (
      <Fragment>
        <p>
          <Trans>Use the details below to make your Multibanco payment.</Trans>
        </p>
        <ul>
          <li><Trans>Amount</Trans> (Montante): <strong>{this.renderDonateAmount()}</strong></li>
          <li><Trans>Entity</Trans> (Entidade): <strong>{multibanco.entity}</strong></li>
          <li><Trans>Reference</Trans> (Referencia): <strong>{multibanco.reference}</strong></li>
        </ul>
      </Fragment>
    )

  }

  public render() {
    const { stripeError, invalidName, name, country, processing, redirecting, paymentMethod, source, canMakePayment, paymentRequest } = this.state
    const { onBack } = this.props

    const submitDisabled =
      !paymentMethod || !name || !country // some required input empty
      || invalidName
      || processing || redirecting || !!stripeError // wrong state

    const initiated = !!source

    const backDisabled = processing || this.paymentSuccess() || this.paymentProcessing()

    let relevantCountries: string[] = []
    for (const method of STRIPE_PAYMENT_METHODS) {
      relevantCountries = [...relevantCountries, ...method.countries || [], 'OTHER']
    }

    // const countriesArr = Object.entries(allCountries).map((item) =>

    const allowedCountries = Config.COUNTRIES
    const countryOptions: Array<SelectInputOption<CountryEntity>> = Object.entries(countries(this.props.i18n))
      .filter((country) => {
        return allowedCountries.indexOf(country[0].toUpperCase()) !== -1
      })
      .map((country) => {
        const code = country[0].toUpperCase()
        return {
          code,
          name: country[1],
          labelString: `${code} ${country[1]}`,
          label: <Fragment><strong>{code}</strong> - {country[1]}</Fragment>,
          value: code
        }
    })

    return (
      <div className='donateForm'>
        <div className='instructions'>
          <div>
            {canMakePayment ?
              <div className='inputWrapper paymentApiWrapper'>
                <p>
                  <Trans>Pay directly with Google Pay or Apple Pay</Trans>
                </p>
                <PaymentRequestButtonElement paymentRequest={paymentRequest} />
                <hr />
              </div>
              :
              null
            }
            <div className='inputWrapper'>
              <label>
                <Trans>Name</Trans>&nbsp;(<Trans>required</Trans>)
              </label>
              <div>
                <input
                  required
                  onChange={(e) => this.setState({name: e.target.value})}
                  onBlur={(e) => this.handleValidate()}
                  value={this.state.name}
                />
              </div>
              {invalidName ?
                <div className='validation-error'>
                  <Trans>Name too short</Trans>
                </div> : null
              }
            </div>

            <div className='inputWrapper'>
              <SelectDropdown
                name='country'
                label={<Trans>Country</Trans>}
                onOptionSelect={this.handleCountrySelect}
                selectedOption={country}
                options={countryOptions}
              />
            </div>

            {country ?
              <div className='inputWrapper'>
                <DonateStripePaymentMethod
                  countryCode={country.value}
                  paymentMethod={paymentMethod}
                  onMethodChange={this.handlePaymentMethodChange}
                  onElementChange={this.handleElementChange}
                  initiated={initiated}
                />
              </div>
              : null
            }

            {this.renderSourceWechat()}
            {this.renderSourceMultibanco()}

            {
              stripeError ?
                <div className='inputError'>
                  {stripeError.message}
                </div>
                : null
             }
          </div>
        </div>

        <div className='actionButtonWrapper'>
          <Button onClick={() => this.handleSubmit()} disabled={submitDisabled}>
            {this.renderSubmitButtonText()}
          </Button>
        </div>
        <div className='actionButtonWrapper'>
          <Button onClick={onBack} buttonType='outline' disabled={backDisabled}>
            <Trans>
              Back
            </Trans>
          </Button>
        </div>

      </div>
    )
  }
}

export default withI18n()(injectStripe(DonateStripeForm))
