import React, { Component } from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import InvestorDashboardPage from '../InvestorDashboardPage'
import EventHeader from '../../Components/EventHeader'
import { getEvent } from '../../Core/EventDetails/EventDetails.actions'
import { AppState } from '../../Core/store'
import Spinner from '../../Elements/Spinner'
import { getMe } from '../../Core/Auth/Auth.actions'
import SettingsPage from '../SettingsPage'
import IssuerDashboardPage from '../IssuerDashboardPage'
import './styles.scss'
import PrivateRoute, { Roles } from '../PrivateRoute'

interface RouteParams {
  eventId: string
}

type Props =
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<RouteParams>

type State = {
  isLoading: boolean
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEvent,
      getMe
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  event: state.eventDetails.event
})

class EventPage extends Component<Props, State> {

  public state: State = {
    isLoading: true
  }

  public async componentDidMount() {
    const eventId = this.props.match.params.eventId

    await Promise.all([
      this.props.getMe(eventId),
      this.props.getEvent(eventId)
    ])

    this.setState({
      isLoading: false
    })
  }

  public render() {
    const eventId = this.props.match.params.eventId

    if (this.state.isLoading) {
      return (
        <Spinner transparentBackground={true} fullPage={true}/>
      )
    }

    if (!this.props.event.id) {
      return (
        <Redirect to={'/unauthorized'}/>
      )
    }

    return (
      <div className='eventPages'>
        <EventHeader eventId={eventId}/>

        <Switch>
          <PrivateRoute
            pathAllowedUsers={[Roles.ISSUER]}
            path={'/event/:eventId(\\d+)/dashboard/issuer'}
            exact={true}
            component={IssuerDashboardPage}
          />
          <PrivateRoute
            pathAllowedUsers={[Roles.INVESTOR]}
            path={'/event/:eventId(\\d+)/dashboard/donor'}
            exact={true}
            component={InvestorDashboardPage}
          />
          <Route
            path={'/event/:eventId(\\d+)/settings'}
            exact={true}
            component={SettingsPage}
          />

          <Redirect to={'/not-found'}/>
        </Switch>
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventPage)
