import React, { Component, ReactChild, Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import { t } from '@lingui/macro'
import { Pagination, Paging } from '../Pagination'
import Spinner from '../../Elements/Spinner'
import TableEmptyState from '../TableEmptyState'
import { i18n } from '../../HOC/I18nLoader'
import './styles.scss'

interface Props {
  render: () => ReactChild
  onPageChange?: (offset: number, limit: number) => void
  onSetNumberOfRecords?: (offset: number, limit: number) => void
  emptyState: boolean
  isLoading: boolean
  pagination?: {
    disabled?: boolean,
    options: {
      limit: number
      offset: number
      count: number
    }
    offsetQueryParam: {
      name: string
      value: string
    }
    limitQueryParam: {
      name: string
      value: string
    }
  }
  className?: string
}

class GenericTableContainer extends Component<Props> {

  private paginationLimitOptions = [25, 50, 100]

  public render() {
    const {
      isLoading,
      render,
      emptyState,
      pagination,
      onPageChange,
      onSetNumberOfRecords,
      className = ''
    } = this.props

    return (
      <div className={`transactionsTable ${className}`}>
        {isLoading && <Spinner transparentBackground={false} className='position-absolute'/>}

        {!emptyState ?
          <Fragment>

            {render()}

            {pagination &&
              <Fragment>
                <Row className='mt-4 d-flex justify-content-center justify-content-lg-end'>
                  <Col md={12} lg={4} className='d-flex justify-content-center'>
                    <Pagination
                      disabled={!!pagination.disabled}
                      count={pagination.options.count}
                      offset={pagination.options.offset}
                      limit={pagination.options.limit}
                      changePage={onPageChange}
                    />
                  </Col>
                  <Col md={12} lg={4}>
                    <Paging
                      disabled={!!pagination.disabled}
                      title={i18n._(t`Records per page: `)}
                      pagingOptions={this.paginationLimitOptions}
                      selectedOption={pagination.options.limit}
                      offset={pagination.options.offset}
                      limitQueryParam={pagination.limitQueryParam.name}
                      offsetQueryParam={pagination.offsetQueryParam.name}
                      setNumberOfRecords={onSetNumberOfRecords}
                    />
                  </Col>
                </Row>
              </Fragment>
            }
          </Fragment>
          :
          <TableEmptyState/>
        }
      </div>
    )
  }
}

export default GenericTableContainer
