import { Action } from 'redux'
import { MODALS } from '../../Common/Constants'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export type UIState = {
  modals: {
    [K in MODALS]?: boolean
  }
}

export interface ToggleModal extends Action<typeof TOGGLE_MODAL> {
  payload: {
    name: MODALS,
    open: boolean
  }
}

export type UIActionTypes = ToggleModal
