import { Reducer } from 'redux'
import {
  GET_EVENT_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS,
  TransactionsActionTypes,
  TransactionsState
} from './Transactions.types'

const initialState: TransactionsState = {
  userTransactions: {
    transactions: [],
    pagination: {
      offset: 0,
      limit: 0,
      count: 0
    }
  },
  eventTransactions: {
    transactions: [],
    pagination: {
      offset: 0,
      limit: 0,
      count: 0
    }
  }
}

export const transactionsReducer: Reducer<TransactionsState, TransactionsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TRANSACTIONS_FOR_EVENT_SUCCESS: {
      const { userTransactions, pagination } = action.payload

      return {
        ...state,
        userTransactions: {
          transactions: [...userTransactions],
          pagination
        }
      }
    }

    case GET_EVENT_TRANSACTIONS_SUCCESS: {
      const { eventTransactions, pagination } = action.payload

      return {
        ...state,
        eventTransactions: {
          transactions: [...eventTransactions],
          pagination
        }
      }
    }

    default: {
      return state
    }
  }
}
