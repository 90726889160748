import React, { Component } from 'react'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import {ToastMessage} from 'rimble-ui'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './Core/store'
import I18nLoader from './HOC/I18nLoader'
import Pages from './Pages'
import HelpAndSupportModal from './Components/HelpAndSupportModal'
import { getMe } from './Core/Auth/Auth.actions'
import { initInterceptors } from './Common/Utils/Api.utils'

initInterceptors()

interface State {
  isLoading: boolean
}

class App extends Component<{}, State> {

  public state: State = {
    isLoading: true
  }

  public async componentDidMount() {
    // load stripe
    const node = document.createElement('script')
    node.async = true
    node.src = 'https://js.stripe.com/v3/'
    node.id = 'stripe-js'
    document.getElementsByTagName('body')[0].appendChild(node)

    // @ts-ignore
    await store.dispatch(getMe())

    this.setState({
      isLoading: false
    })
  }

  public render() {

    if (this.state.isLoading) {
      return null
    }

    return (
      <Provider store={store}>
        <ToastMessage.Provider ref={(node: any) => (window.toastProvider = node)} />
        <ConnectedRouter history={history}>
          <I18nLoader>
            <div className='app'>
                <div className='pages'>
                  <Pages/>
                </div>
              </div>

              <ReduxToastr
                timeOut={5000}
                progressBar
                newestOnTop
                preventDuplicates
                position='bottom-right'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                closeOnToastrClick
              />

              <HelpAndSupportModal />
          </I18nLoader>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App
