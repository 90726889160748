import React, {Component} from 'react'
import {bindActionCreators, Dispatch} from 'redux'
import {connect} from 'react-redux'
import {withI18n, withI18nProps} from '@lingui/react'
import {Trans} from '@lingui/macro'
import {StripeProvider, Elements} from 'react-stripe-elements'
import BigNumber from 'bignumber.js'

import {MODALS} from '../../Common/Constants'
import Config from '../../Common/Config'
import {toggleModal} from '../../Core/UI/UI.actions'
import {AppState} from '../../Core/store'

import Dialog from '../../Elements/Dialog'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import DonateStripeForm from './DonateStripeForm'

type Props =
  withI18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  {
    amount: number,
    onBack: () => void,
    onDone: () => void
  }

interface State {
  stripe: any, // todo ?? rly any?

  // donateAmountInput: string
  // selectedDonateCurrency?: SelectInputOption<CurrencyEntity>
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleModal
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  language: state.userEventSettings.language,
  me: state.auth.me,
  modals: state.ui.modals
})

class DonateStripe extends Component<Props, State> {

  public state: State = {
    stripe: undefined
  }

  public render() {
    const { modals, onBack, onDone, amount, me } = this.props

    return (
      <Dialog
        className='donateModal bankInstructions'
        isOpen={!!modals[MODALS.STRIPE]}
      >
        <DialogHeader>
          <Trans>Make a donation with Stripe</Trans>
        </DialogHeader>

        <DialogBody>
          <div>
            <Trans>Your donation</Trans>
          </div>
          <div className='donateAmountLabel'>
            <strong>
              {new BigNumber(amount).toFixed(2)} EUR
            </strong>
          </div>
          <div style={{lineHeight: 1}}>
            <small>
              <Trans>
                Please note there is a fee for financial transaction depending on payment provider
              </Trans>
            </small>
          </div>

          <StripeProvider apiKey={Config.STRIPE_PUBLIC_KEY}>
            <Elements locale={this.props.language}>
              <DonateStripeForm amount={amount} me={me} onBack={onBack} onDone={onDone} />
            </Elements>
          </StripeProvider>

        </DialogBody>
      </Dialog>
    )
  }

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withI18n()(DonateStripe))
