import React, { Component } from 'react'
import BigNumber from 'bignumber.js'
import './styles.scss'

interface Props {
  max: string
  current: string
  className?: string
}

class ProgressBar extends Component<Props> {

  public static defaultProps = {
    className: ''
  }

  public render() {
    const {
      max,
      current,
      className
    } = this.props

    const bigMax = new BigNumber(max ? max : 1)
    const bigCurrent = new BigNumber(current ? current : 0)

    let currentPointerWidth =  bigCurrent.multipliedBy(100).dividedBy(bigMax).toString()
    currentPointerWidth = new BigNumber(currentPointerWidth).gt(100) ? '100' : currentPointerWidth.toString()

    return (
      <div className={`progressBar position-relative ${className}`}>
        <div className='currentPointer' style={{width: `${currentPointerWidth}%`}}/>
      </div>
    )
  }
}

export default ProgressBar
