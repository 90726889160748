import React, { Component } from 'react'

class DonateTosFr extends Component {
  public render() {
    return (
      <div>
        <p>Vous faites un don à <strong>Africa GreenTec Help e.V.</strong> pour soutenir le projet "#connect2evolve" au Sénégal. Votre relevé bancaire ou de votre carte de crédit indiquera que votre don a été fait à Africa GreenTec Help e.V.</p>
        <p>Veuillez-vous référer au site web de Africa GreenTec Help e.V. concernant l'objectif de l'organisation à but non lucratif. (<a href='https://www.agthelpev.de' target='_blank' rel='noopener noreferrer'>www.agthelpev.de</a>)</p>
        <p>Une fois qu'un don est effectué, la transaction est définitive et ne peut être contestée ou annulée.</p>
        <p>Si le montant total souhaité n'est pas collecté et que le projet ne peut être mis en œuvre comme décrit, l'argent collecté sera utilisé pour d'autres projets dans le cadre de l'objectif de Africa GreenTec Help e.V.</p>
        <p>Africa GreenTec Help e.V. se réserve le droit de prendre la décision finale quant à l'emplacement du Solartainer®, au cas où des circonstances imprévues empêcheraient son installation à Bacco Ndieme.</p>
        <p><strong>Modalités de paiement</strong></p>
        <p>Tous les dons effectués sur ce site impliquent des frais de transaction. Le montant moyen de nos reçus de dons est d'environ 95 % du montant initial. Veuillez en tenir compte lorsque vous faites votre don afin de réduire les frais de transaction.</p>
        <p>Lorsque vous faites un don, il peut être nécessaire, en raison d'exigences légales (par exemple pour la prévention du blanchiment d'argent), de vérifier votre identité ou les informations que vous avez fournies, en particulier dans le cas de dons supérieurs à 10 000 euros. Vous vous engagez à fournir les informations ou les documents nécessaire. Si la preuve d'identité n'est pas suffisamment fournie, le don sera restitué au donateur, à l'exception des frais de transaction et de change.</p>
        <p>Africa GreenTec Help e.V. conservera les informations relatives aux donateurs pour les besoins de la réglementation générale sur la protection des données (GDPR) de tout donateur devant passer par le processus KYC/AML (Know Your Customer/Anti-Money Laundering). Ces informations seront conservées auprès de Sum and Substance, le fournisseur de services pour ces vérifications, pendant au moins cinq (5) ans.</p>
        <p>Les données IP (protocole internet) seront collectées à des fins statistiques uniquement. Elles ne seront pas liées à un nom ou à une adresse électronique ou à toute autre information recueillie sur ces pages.</p>
        <p>Un reçu fiscal peut être demandé pour tout don supérieur à 200 euros. Veuillez contacter Africa GreenTec Help e.V. (info@africagreentec.help) à cette fin.</p>
        <p>En Allemagne, tout don inférieur à 200 euros ne nécessite pas de reçu spécifique ; une copie de la transaction sur le compte utilisé pour le don suffit comme preuve du don.</p>
        <p>Siemens Gas and Power GmbH & Co. KG Version 0, Janvier 2020</p>
      </div>
    )
  }
}

export default DonateTosFr
