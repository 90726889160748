import {
  GET_USER_CLAIM_HISTORY_FOR_EVENT_ERROR,
  GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST,
  GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS,
  GetUserClaimHistoryForEventSuccess
} from './ClaimHistory.types'
import ClaimHistoryService from '../../Services/ClaimHistory.service'
import { ThunkResult } from '../types'
import {
  ClaimHistoryResponseEntity,
  PaginationWithCount,
  PagingInfo,
  SortOrder,
} from '../API/APIResponse.types'

/**
 * @description Get user claim history for event
 * @param userId
 * @param eventId
 * @param offset
 * @param limit
 */
export function getUserClaimHistoryForEvent(userId: string, eventId: string, offset?: number, limit?: number): ThunkResult<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: GET_USER_CLAIM_HISTORY_FOR_EVENT_REQUEST})

      const pagination: PagingInfo = {
        sort: {
          sortColumn: 'createdAt',
          sortOrder: SortOrder.DESC
        },
        pagination: {
          offset: offset ? offset : 0,
          limit: limit ? limit : 0
        }
      }

      const response = await ClaimHistoryService.getUserClaimHistoryForEvent(userId, eventId, pagination)
      const { result, ...paginationInfo } = response
      dispatch(getUserClaimHistoryForEventSuccess(result, paginationInfo))

    } catch (error) {
      dispatch({type: GET_USER_CLAIM_HISTORY_FOR_EVENT_ERROR})
      console.error(error)
    }
  }
}

export function getUserClaimHistoryForEventSuccess(data: ClaimHistoryResponseEntity[], pagination: PaginationWithCount): GetUserClaimHistoryForEventSuccess {
  return {
    type: GET_USER_CLAIM_HISTORY_FOR_EVENT_SUCCESS,
    payload: {
      data,
      pagination
    }
  }
}
