import { Reducer } from 'redux'
import {
  DonationsActionTypes,
  DonationsState,
  GET_BANK_INSTRUCTIONS_SUCCESS, GET_BANK_REFERENCE_NUMBER_SUCCESS,
  GET_CRYPTO_PROXY_ADDRESS_SUCCESS,
  GET_DONATIONS_PER_CURRENCY_SUCCESS,
  GET_DONOR_COUNTRIES_SUCCESS
} from './Donations.types'

// const countryMock: any = {
//   countries: [
//     {country: 'GB', count: 29},
//     {country: 'US', count: 18},
//     {country: 'DE', count: 12},
//     {country: 'AU', count: 9}
//   ],
//   total: '153'
// }
//
// const currencyMock: any = {
//   currencies: [
//     {currency: 'USD', value: '200', type: 'fiat'},
//     {currency: 'EUR', value: '0', type: 'fiat'},
//     {currency: 'CHF', value: '0', type: 'fiat'},
//     {currency: 'ETH', value: '0', type: 'asset'},
//     {currency: 'DAI', value: '200', type: 'asset'},
//     {currency: 'BTC', value: '900', type: 'asset'}
//   ],
//   total: '1300'
// }

const initialState: DonationsState = {
  perCountries: {
    countries: [],
    total: 0
  },
  perCurrencies: {
    currencies: [],
    total: '0'
  },
  cryptoInstructions: {},
  bankInstructions: {
    bankInfo: [],
    additionalInfo: []
  }
}

export const donationsReducer: Reducer<DonationsState, DonationsActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_DONOR_COUNTRIES_SUCCESS: {
      const { countries, total } = action.payload

      return {
        ...state,
        // perCountries: {...countryMock}
        perCountries: {
          countries: [...countries],
          total
        }
      }
    }

    case GET_DONATIONS_PER_CURRENCY_SUCCESS: {
      const { currencies, total } = action.payload

      return {
        ...state,
        // perCurrencies: {...currencyMock}
        perCurrencies: {
          currencies: [...currencies],
          total
        }
      }
    }

    case GET_CRYPTO_PROXY_ADDRESS_SUCCESS: {
      const { addressData } = action.payload

      return {
        ...state,
        cryptoInstructions: {
          ...addressData
        }
      }
    }

    case GET_BANK_INSTRUCTIONS_SUCCESS: {
      const { bankInstructions } = action.payload

      return {
        ...state,
        bankInstructions: {
          ...bankInstructions
        }
      }
    }

    case GET_BANK_REFERENCE_NUMBER_SUCCESS: {
      const { bankReferenceNumber } = action.payload

      return {
        ...state,
        bankReferenceNumber
      }
    }

    default: {
      return state
    }
  }
}
