import React, { Component, Fragment } from 'react'
import { t, Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { withI18n, withI18nProps } from '@lingui/react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import QRCode from 'qrcode.react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import * as _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import { i18n } from '../../HOC/I18nLoader'
import { ReactComponent as Power } from '../../Common/Assets/power-full.svg'
import { ReactComponent as Tree } from '../../Common/Assets/tree-full.svg'
import { ReactComponent as House } from '../../Common/Assets/house-full.svg'
import { ReactComponent as Money } from '../../Common/Assets/money.svg'
import { toggleModal } from '../../Core/UI/UI.actions'
import { getUserEventSupportedCurrencies } from '../../Core/UserEventDetails/UserEventDetails.actions'
import { AppState } from '../../Core/store'
import Button from '../../Elements/Button'
import {
  MAX_AMOUNT_FIAT,
  MAX_AMOUNT_WITHOUT_KYA,
  MODALS,
  SUPPORTED_LANGUAGES,
} from '../../Common/Constants'
import DialogHeader from '../../Elements/Dialog/DialogHeader'
import DialogBody from '../../Elements/Dialog/DialogBody'
import Dialog from '../../Elements/Dialog'
import SelectDropdown from '../../Elements/SelectDropdown'
import { SelectInputOption } from '../../Elements/SelectDropdown/types'
import { LanguageContext } from '../../HOC/LanguageContext'
import { copy } from '../../Common/Utils/Common.utils'
import ShareButtons from '../ShareButtons'
import { createLoadingSelector } from '../../Core/API/Loading.reducer'
import { GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST } from '../../Core/UserEventDetails/UserEventDetails.types'
import { CALCULATE_DONATION_IMPACT_REQUEST } from '../../Core/EventDetails/EventDetails.types'
import Spinner from '../../Elements/Spinner'
import { calculateDonationImpact } from '../../Core/EventDetails/EventDetails.actions'
import { CurrencyEntity } from '../../Core/API/APIResponse.types'
import {
  getBankInstructions,
  getBankReferenceNumber,
  getCryptoProxyAddress,
} from '../../Core/Donations/Donations.actions'
import { formatDonateAmount } from './utils'
import DonateStripe from './DonateStripe'
import DonateTosEn from './DotanteTosEn'
import DonateTosDe from './DotanteTosDe'
import DonateTosFr from './DotanteTosFr'

import './styles.scss'
import { CountryEntity } from '../../Core/types'
import Config from '../../Common/Config'
import StripeService from '../../Services/Stripe.service'

function getDonationShareUrl(language: SUPPORTED_LANGUAGES) {
  switch (language) {
    case SUPPORTED_LANGUAGES.de: {
      return 'https://www.siemens.com/connect2evolve-de'
    }

    case SUPPORTED_LANGUAGES.fr: {
      return 'https://www.siemens.com/connect2evolve-fr'
    }

    default: {
      return 'https://www.siemens.com/connect2evolve-en'
    }
  }
}

type Props = withI18nProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    userInvestmentAmount?: string
  }

interface State {
  donateAmountInput: string
  tosModalOpen: boolean
  selectedDonateCurrency?: SelectInputOption<CurrencyEntity>
  country?: SelectInputOption<CountryEntity>
}

const isFetchingSupportedCurrencies = createLoadingSelector([
  GET_USER_EVENT_SUPPORTED_CURRENCIES_REQUEST
])

const isCalculatingDonationImpact = createLoadingSelector([
  CALCULATE_DONATION_IMPACT_REQUEST
])

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleModal,
      getUserEventSupportedCurrencies,
      calculateDonationImpact,
      getCryptoProxyAddress,
      getBankInstructions,
      getBankReferenceNumber
    },
    dispatch
  )

const mapStateToProps = (state: AppState) => ({
  modals: state.ui.modals,
  me: state.auth.me,
  userSupportedCurrencies: state.userEventDetails.supportedCurrencies,
  calculatedDonationImpact: state.eventDetails.calculatedDonationImpact,
  cryptoAddressReference: state.donations.cryptoInstructions,
  bankInstructions: state.donations.bankInstructions,
  bankReferenceNumber: state.donations.bankReferenceNumber,
  isFetchingSupportedCurrencies: isFetchingSupportedCurrencies(state),
  isCalculatingDonationImpact: isCalculatingDonationImpact(state)
})

class Donate extends Component<Props, State> {
  public state: State = {
    donateAmountInput: Config.TESTING ? '100' : '',
    tosModalOpen: false
  }

  public calculateDonationImpactRequest = _.debounce(
    (eventId: string, currencyId: string, amount: string) => {
      this.props.calculateDonationImpact(eventId, currencyId, amount)
    },
    500
  )

  public componentDidUpdate = (prevProps: Props) => {
    const { userSupportedCurrencies } = this.props
    if (
      prevProps.userSupportedCurrencies.length === 0 &&
      userSupportedCurrencies.length > 0
    ) {
      const currency = userSupportedCurrencies.find((x) => x.name === 'EUR')
      if (currency) {
        this.setState({
          selectedDonateCurrency: this.currencyToOption(currency)
        })
      }
    }
  }

  public openDonationModal = async () => {
    const { me, toggleModal, getUserEventSupportedCurrencies } = this.props

    toggleModal(MODALS.MAKE_A_DONATION, true)
    if (me.id && me.eventId) {
      await getUserEventSupportedCurrencies(me.id, me.eventId.toString())
    }
  }

  public toggleTosModal = () => {
    this.setState({ tosModalOpen: !this.state.tosModalOpen })
  }

  public toggleModal = (modal: MODALS, open: boolean) => {
    const { toggleModal } = this.props

    toggleModal(modal, open)
  }

  public onCurrencySelect = (
    name: string,
    option: SelectInputOption<CurrencyEntity>
  ) => {
    if (this.state.selectedDonateCurrency) {
      if (option.value === this.state.selectedDonateCurrency) {
        return
      }
    }

    this.setState({
      donateAmountInput: '',
      selectedDonateCurrency: option
    })
  }

  public onDonateAmountChange = (value: NumberFormatValues) => {
    const { me } = this.props

    const { selectedDonateCurrency } = this.state

    this.setState(
      {
        donateAmountInput: value.value
      },
      () => {
        if (
          me.id &&
          me.eventId &&
          selectedDonateCurrency &&
          this.state.donateAmountInput
        ) {
          this.calculateDonationImpactRequest(
            me.eventId,
            selectedDonateCurrency.id,
            this.state.donateAmountInput
          )
        }
      }
    )
  }

  public buttonDisabled = () => {
    const { calculatedDonationImpact } = this.props
    const { selectedDonateCurrency } = this.state

    if (!selectedDonateCurrency) {
      return false
    }

    if (parseFloat(calculatedDonationImpact.baseCurrencyAmount) < 10) {
      return true
    } else if (
      selectedDonateCurrency.type === 'fiat' &&
      parseFloat(calculatedDonationImpact.baseCurrencyAmount) > MAX_AMOUNT_FIAT
    ) {
      return true
    } else {
      return false
    }
  }

  public handleMakeDonation = async () => {
    const {
      userInvestmentAmount = '0',
      calculatedDonationImpact,
      me
    } = this.props

    const { selectedDonateCurrency } = this.state

    if (selectedDonateCurrency) {
      this.toggleModal(MODALS.MAKE_A_DONATION, false)

      const aboveLimit = new BigNumber(userInvestmentAmount)
        .plus(calculatedDonationImpact.baseCurrencyAmount)
        .gte(MAX_AMOUNT_WITHOUT_KYA)
      const certificate = me.certificate
      if (aboveLimit && !certificate) {
        this.toggleModal(MODALS.KYA_PROMPT, true)
      } else {
        this.showPaymentInfo()
      }
    }
  }

  public showPaymentInfo = async () => {
    const { me, getCryptoProxyAddress } = this.props

    const { selectedDonateCurrency } = this.state

    try {
      if (selectedDonateCurrency) {
        if (me.id && me.eventId) {
          if (selectedDonateCurrency.type === 'fiat') {
            this.toggleModal(MODALS.STRIPE, true)
          } else {
            await getCryptoProxyAddress(
              me.id,
              me.eventId,
              selectedDonateCurrency.id
            )
            this.toggleModal(MODALS.CRYPTO_INSTRUCTIONS, true)
          }
        }
      }
    } catch (e) {
      if (e.message) {
        toastr.error(i18n._(t`Error`), e.message)
      }
    }
  }

  public handleBackDonateModal = () => {
    this.toggleModal(MODALS.MAKE_A_DONATION, false)
  }

  public handleBackKyaModal = () => {
    this.toggleModal(MODALS.KYA_PROMPT, false)
    this.toggleModal(MODALS.MAKE_A_DONATION, true)
  }

  public handleContinueKyaModal = () => {
    this.showPaymentInfo()
    this.toggleModal(MODALS.KYA_PROMPT, false)
  }

  public handleBackCryptoInstructionsModal = () => {
    this.toggleModal(MODALS.CRYPTO_INSTRUCTIONS, false)
    this.toggleModal(MODALS.MAKE_A_DONATION, true)
  }

  public handleDoneCryptoInstructionsModal = () => {
    this.toggleModal(MODALS.CRYPTO_INSTRUCTIONS, false)
    this.toggleModal(MODALS.SHARE_MODAL, true)
  }

  public handleBackBankInstructionsModal = () => {
    this.toggleModal(MODALS.BANK_INSTRUCTIONS, false)
    this.toggleModal(MODALS.MAKE_A_DONATION, true)
  }

  public handleDoneBankInstructionsModal = () => {
    this.toggleModal(MODALS.BANK_INSTRUCTIONS, false)
    this.toggleModal(MODALS.SHARE_MODAL, true)
  }

  public handleBackStripeModal = () => {
    this.toggleModal(MODALS.STRIPE, false)
    this.toggleModal(MODALS.MAKE_A_DONATION, true)
  }

  public handleDoneStripeModal = () => {
    this.toggleModal(MODALS.STRIPE, false)
    this.toggleModal(MODALS.SHARE_MODAL, true)
  }

  public handleCloseShareModal = () => {
    this.toggleModal(MODALS.SHARE_MODAL, false)
    const url = new URL(window.location.href)
    url.searchParams.delete('source')
    url.searchParams.delete('client_secret')
    url.searchParams.delete('livemode')
    window.location.replace(url.toString())
  }

  public handleClosePaymentFailedModal = () => {
    this.toggleModal(MODALS.PAYMENT_FAILED_MODAL, false)
    const url = new URL(window.location.href)
    url.searchParams.delete('source')
    url.searchParams.delete('client_secret')
    url.searchParams.delete('livemode')
    window.location.replace(url.toString())
  }

  public renderDonateAmount = () => {
    return formatDonateAmount(
      this.state.donateAmountInput,
      this.state.selectedDonateCurrency
    )
  }

  public renderTotalAmount = () => {
    const { calculatedDonationImpact, userInvestmentAmount = '0' } = this.props
    return formatDonateAmount(
      new BigNumber(userInvestmentAmount)
        .plus(calculatedDonationImpact.baseCurrencyAmount)
        .toFixed(2)
    )
  }

  public currencyToOption = (currency: CurrencyEntity) => {
    return {
      label: (
        <Fragment>
          <strong>{currency.symbol.toUpperCase()}</strong> - {currency.name}
        </Fragment>
      ),
      labelString: `${currency.symbol} ${currency.name}`,
      value: currency.symbol.toUpperCase(),
      ...currency
    }
  }

  public renderDonateModal = () => {
    const {
      modals,
      toggleModal,
      userSupportedCurrencies,
      calculatedDonationImpact,
      isFetchingSupportedCurrencies,
      isCalculatingDonationImpact
    } = this.props

    const { selectedDonateCurrency, donateAmountInput } = this.state

    const filterCurrenciesByType = (
      currencies: CurrencyEntity[],
      type: string
    ) => {
      return currencies
        .filter((currency) => currency.type === type)
        .map((currency) => this.currencyToOption(currency))
        .sort((a, b) =>
          a.symbol.toUpperCase().localeCompare(b.symbol.toUpperCase())
        )
    }

    const cryptos = filterCurrenciesByType(userSupportedCurrencies, 'asset')
    const fiats = filterCurrenciesByType(userSupportedCurrencies, 'fiat')
    const cryptosString = cryptos.map((x) => x.labelString).join('')
    const fiatsString = fiats.map((x) => x.labelString).join('')

    let currencyOptions: SelectInputOption[]

    if (cryptos.length && fiats.length) {
      currencyOptions = [
        {
          label: (
            <Fragment>
              <strong>Cryptocurrencies</strong>
            </Fragment>
          ),
          labelString: cryptosString,
          value: '_crypto_',
          header: true
        },
        ...cryptos,
        {
          label: (
            <Fragment>
              <strong>Currencies</strong>
            </Fragment>
          ),
          labelString: fiatsString,
          value: '_fiat_',
          header: true
        },
        ...fiats
      ]
    } else {
      currencyOptions = [...cryptos, ...fiats]
    }

    const peoplePowered = new BigNumber(calculatedDonationImpact.peoplePowered)

    return (
      <Dialog
        className='donateModal'
        isOpen={!!modals[MODALS.MAKE_A_DONATION]}
        toggleFunction={() => toggleModal(MODALS.MAKE_A_DONATION, false)}
      >
        <DialogHeader>
          <Trans>Make a donation</Trans>
        </DialogHeader>

        <DialogBody>
          <div className='position-relative'>
            <SelectDropdown
              name='selectCurrency'
              disabled={isFetchingSupportedCurrencies}
              label={<Trans>Select reference currency:</Trans>}
              onOptionSelect={this.onCurrencySelect}
              selectedOption={selectedDonateCurrency}
              options={currencyOptions}
            />
            <div>
              <small>
                <Trans>All payments are processed in EUR.</Trans>
              </small>
            </div>
          </div>

          <div className='inputWrapper'>
            <label>
              <Trans>How much would you like to donate?</Trans>
            </label>
            <div>
              <NumberFormat
                disabled={!selectedDonateCurrency}
                className={!selectedDonateCurrency ? 'disabledElement' : ''}
                thousandSeparator={','}
                decimalSeparator={'.'}
                suffix={`  ${
                  selectedDonateCurrency ? selectedDonateCurrency.value : ''
                }`}
                onValueChange={this.onDonateAmountChange}
                displayType='input'
                value={donateAmountInput}
              />
              <div className='helperText'>
                {!selectedDonateCurrency ||
                selectedDonateCurrency.type === 'fiat' ? (
                  <Trans>
                    Minimum donation 10 EUR. Maximum 10,000 EUR. The limit
                    applies per transaction. Multiple donations are possible.
                  </Trans>
                ) : (
                  <Trans>
                    Minimum donation 10 EUR. Your donation will be shown in your
                    dashboard when received, less any transaction fees, which
                    depend on your payment type.
                  </Trans>
                )}
              </div>
            </div>
          </div>

          <div className='impactCalcsWrapper'>
            <p>
              <Trans>
                Your impact for the lifetime of the container would be:
              </Trans>
            </p>
            <div className='impactCalcs'>
              {isCalculatingDonationImpact && (
                <Spinner className='position-absolute' />
              )}
              <div className='impact'>
                <div className='labelWrapper'>
                  <div className='labelImgWrapper'>
                    <Money width={'24px'} />
                  </div>
                  <span>
                    <Trans>Donated amount</Trans>
                  </span>
                </div>
                <div className='value'>
                  {new BigNumber(
                    calculatedDonationImpact.baseCurrencyAmount
                  ).toFormat(2)}{' '}
                  EUR
                </div>
              </div>
              <div className='impact'>
                <div className='labelWrapper'>
                  <div className='labelImgWrapper'>
                    <Power width={'24px'} />
                  </div>
                  <span>
                    <Trans>Clean energy generated</Trans>
                  </span>
                </div>
                <div className='value'>
                  {new BigNumber(
                    calculatedDonationImpact.estimatedPower
                  ).toFormat(0)}{' '}
                  kWh
                </div>
              </div>
              <div className='impact'>
                <div className='labelWrapper'>
                  <div className='labelImgWrapper'>
                    <Tree />
                  </div>
                  <span>
                    CO₂ <Trans>emission reduced</Trans>
                  </span>
                </div>
                <div className='value'>
                  {new BigNumber(
                    calculatedDonationImpact.reducedCO2Lifetime
                  ).toFormat(0)}{' '}
                  kg
                </div>
              </div>
              <div className='impact'>
                <div className='labelWrapper'>
                  <div className='labelImgWrapper'>
                    <House />
                  </div>
                  <span>
                    <Trans>People powered</Trans>
                  </span>
                </div>
                <div className='value'>
                  {peoplePowered.isLessThan(1)
                    ? '< 1'
                    : peoplePowered.toFormat(0, BigNumber.ROUND_DOWN)}
                </div>
              </div>
            </div>
          </div>

          <div className='actionButtonWrapper'>
            <Button onClick={this.handleBackDonateModal} buttonType='outline'>
              <Trans>Back</Trans>
            </Button>
            <Button
              className='makeDonationButton'
              onClick={this.handleMakeDonation}
              disabled={this.buttonDisabled()}
            >
              <Trans>Make donation</Trans>
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    )
  }

  public renderKYAPromptModal = ({ modals }: Props) => (
    <Dialog className='kyaPromptModal' isOpen={!!modals[MODALS.KYA_PROMPT]}>
      <DialogHeader>
        <Trans>Special requirements for large donors</Trans>
        <br />
        <small>
          <Trans>Please Note: After this donation, your total will be</Trans>
          &nbsp
          <b>{this.renderTotalAmount()} EUR</b>
        </small>
      </DialogHeader>

      <DialogBody>
        <Trans>
          Donors who donate a total of over 10,000 EUR will be required to
          verify their identity. Instructions for this process will be provided
          by email.
        </Trans>
        <div className='actionButtonWrapper'>
          <Button onClick={this.handleBackKyaModal} buttonType='outline'>
            <Trans>Back</Trans>
          </Button>

          <Button onClick={this.handleContinueKyaModal}>
            <Trans>Continue with donating</Trans>
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )

  public renderCryptoInstructionsModal = (props: Props, state: State) => {
    const { modals, cryptoAddressReference } = props

    const { tag } = cryptoAddressReference

    return (
      <Dialog
        className='cryptoInstructions'
        isOpen={!!modals[MODALS.CRYPTO_INSTRUCTIONS]}
      >
        <DialogHeader>
          <Trans>Make a donation</Trans>
        </DialogHeader>

        <DialogBody>
          <div>
            <Trans>Your donation</Trans>
          </div>
          <div className='donateAmountLabel'>
            <strong>{this.renderDonateAmount()}</strong>
          </div>

          <div className='qrCodeLabel'>
            <Trans>
              Please make payment using this QR code or the address below:
            </Trans>
          </div>

          <div className='donateQRCodeWrapper'>
            <QRCode
              width={'100%'}
              height={'100%'}
              renderAs='svg'
              value={
                cryptoAddressReference.address
                  ? cryptoAddressReference.address
                  : ''
              }
            />
          </div>

          <div className='addressWrapper'>
            <div className='text-break'>{cryptoAddressReference.address}</div>
            <Button
              buttonType='link'
              onClick={() =>
                copy(
                  cryptoAddressReference.address
                    ? cryptoAddressReference.address
                    : ''
                )
              }
            >
              <Trans>Click to copy</Trans>
            </Button>
          </div>

          {tag ? (
            <div className='addressWrapper'>
              <div className='text-break'>
                <div className='mb-2'>
                  <Trans>Tag</Trans>
                </div>
                <span>{tag}</span>
              </div>
              <Button buttonType='link' onClick={() => copy(tag)}>
                <Trans>Click to copy</Trans>
              </Button>
            </div>
          ) : null}

          <div className='actionButtonWrapper'>
            <Button
              onClick={this.handleBackCryptoInstructionsModal}
              buttonType='outline'
            >
              <Trans>Back</Trans>
            </Button>

            <Button onClick={this.handleDoneCryptoInstructionsModal}>
              <Trans>Done</Trans>
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    )
  }

  public renderBankInstructionsModal = (props: Props, state: State) => {
    const { modals, bankInstructions, bankReferenceNumber } = props

    return (
      <Dialog
        className='bankInstructions'
        isOpen={!!modals[MODALS.BANK_INSTRUCTIONS]}
      >
        <DialogHeader>
          <Trans>Make a donation</Trans>
        </DialogHeader>

        <DialogBody>
          <div>
            <Trans>Your donation</Trans>
          </div>
          <div className='donateAmountLabel'>
            <strong>{this.renderDonateAmount()}</strong>
          </div>

          <div className='detailsNote'>
            <strong>
              <Trans>Details you may need to use for your wire transfer.</Trans>
            </strong>
          </div>

          <div className='note'>
            <Trans>
              Note, you must send the transfer from a bank account in the same
              name as one you used to register.
            </Trans>
          </div>

          <div className='instructions'>
            {bankInstructions.bankInfo.map((info, i) => {
              return (
                <div key={i} className='bankInfoItem'>
                  <div className='title'>{info.title}</div>
                  <div className='value'>{info.values.map((text) => text)}</div>
                </div>
              )
            })}

            {bankInstructions.additionalInfo.map((info, i) => {
              return (
                <div key={i} className='bankInfoItem'>
                  <div className='title'>{info.title}</div>
                  <div className='value'>{info.values.map((text) => text)}</div>
                </div>
              )
            })}

            {bankReferenceNumber && (
              <div className='bankInfoItem'>
                <div className='title'>
                  <Trans>Reference</Trans>
                </div>
                <div className='value'>{bankReferenceNumber}</div>
              </div>
            )}
          </div>

          <div className='actionButtonWrapper'>
            <Button
              onClick={this.handleBackBankInstructionsModal}
              buttonType='outline'
            >
              <Trans>Back</Trans>
            </Button>

            <Button onClick={this.handleDoneBankInstructionsModal}>
              <Trans>Done</Trans>
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    )
  }

  public renderShareModal = (
    { modals }: Props,
    selectedLanguage: SUPPORTED_LANGUAGES
  ) => (
    <Dialog className='shareModal' isOpen={!!modals[MODALS.SHARE_MODAL]}>
      <DialogHeader>
        <Trans>Thank you!!!</Trans>
      </DialogHeader>

      <DialogBody>
        <div className='text-center'>
          <Trans>
            Thank you for your donation! <br />
            Your donation will be visible in your dashboard as soon as the
            payment is processed.
          </Trans>
        </div>

        <div className='shareDescription'>
          <Trans>
            Invite your friends and business partners to join you in providing
            affordable, clean and reliable energy for the community of Bacco
            Ndieme. On average, each share results in another 10 EUR in
            donations!
          </Trans>

          <ShareButtons
            text={this.props.i18n
              ._(t`I just donated to the project #connect2evolve to create a real impact in the community
                    of Bacco Ndieme, in Senegal, by providing affordable, clean and reliable energy. Would you like to join me?`)}
            hashTags={'connect2evolve,socialimpact'}
            url={getDonationShareUrl(selectedLanguage)}
          />
        </div>
        <Button onClick={this.handleCloseShareModal} className='closeButton'>
          <Trans>Close</Trans>
        </Button>
      </DialogBody>
    </Dialog>
  )

  public renderPaymentFailedModal = (
    { modals }: Props,
    selectedLanguage: SUPPORTED_LANGUAGES
  ) => (
    <Dialog
      className='paymentFailedModal'
      isOpen={!!modals[MODALS.PAYMENT_FAILED_MODAL]}
    >
      <DialogHeader>
        <Trans>Payment failed.</Trans>
      </DialogHeader>

      <DialogBody>
        <div className='text-center'>
          <Trans>
            Something went wrong with your donation and it wasn't processed.
            Please try again with a different payment method or contact your
            financial institution.
          </Trans>
        </div>

        <div className='actionButtonWrapper'>
          <Button
            onClick={this.handleClosePaymentFailedModal}
            className='closeButton'
          >
            <Trans>Close</Trans>
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  )

  public renderTosModal(language: string) {
    return (
      <Dialog
        className='wide'
        isOpen={this.state.tosModalOpen}
        toggleFunction={() => this.toggleTosModal()}
      >
        <DialogHeader>
          <Trans>Terms and Conditions</Trans>
        </DialogHeader>

        <DialogBody>
          {this.renderTosModalContent(language)}
          <div className='actionButtonWrapper'>
            <Button onClick={() => this.toggleTosModal()}>
              <Trans>Close</Trans>
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    )
  }

  public renderTosModalContent(language: string) {
    switch (language) {
      case SUPPORTED_LANGUAGES.en: {
        return <DonateTosEn />
      }

      case SUPPORTED_LANGUAGES.de: {
        return <DonateTosDe />
      }

      case SUPPORTED_LANGUAGES.fr: {
        return <DonateTosFr />
      }
    }
  }

  public async componentDidMount() {
    const { me } = this.props
    if (!me.eventId) {
      return
    }
    const url = new URL(window.location.href)
    const sourceId = url.searchParams.get('source')
    const clientSecret = url.searchParams.get('client_secret')
    if (sourceId && clientSecret) {
      const source = await StripeService.getSource(me.eventId, sourceId)
      if (source && source.redirect && source.redirect.status === 'failed') {
        this.toggleModal(MODALS.PAYMENT_FAILED_MODAL, true)
      } else {
        this.toggleModal(MODALS.SHARE_MODAL, true)
      }
    }
  }

  public render() {
    const amountEur = new BigNumber(
      this.props.calculatedDonationImpact.baseCurrencyAmount
    ).toNumber()

    return (
      <LanguageContext.Consumer>
        {({ selectedLanguage }) => (
          <div className='donateFeatureWrapper'>
            <Button
              className='w-100 donateButton'
              onClick={() => this.openDonationModal()}
            >
              <span className='icon-log-in d-flex justify-content-center align-items-center'>
                <Trans>Donate now</Trans>
              </span>
            </Button>
            <div className='tosLink'>
              <small>
                <a href='#' onClick={() => this.toggleTosModal()}>
                  <Trans>Terms & Conditions</Trans>
                </a>
              </small>
            </div>

            {this.renderDonateModal()}

            {this.renderKYAPromptModal(this.props)}

            {this.renderCryptoInstructionsModal(this.props, this.state)}

            {this.renderBankInstructionsModal(this.props, this.state)}

            <DonateStripe
              onBack={this.handleBackStripeModal}
              onDone={this.handleDoneStripeModal}
              amount={amountEur}
            />

            {this.renderShareModal(this.props, selectedLanguage)}
            {this.renderPaymentFailedModal(this.props, selectedLanguage)}
            {this.renderTosModal(selectedLanguage)}
          </div>
        )}
      </LanguageContext.Consumer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Donate))
