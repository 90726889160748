import BigNumber from 'bignumber.js'
import { t } from '@lingui/macro'
import { AppState } from './store'
import { ChartData } from './types'
import { i18n } from '../HOC/I18nLoader'
import { toPercentage } from '../Common/Utils/Math.utils'
import { countries as listOfCountries } from '../Common/Data/countries'
import { InvestmentsPerCurrency, SaleCycleResponseEntity } from './API/APIResponse.types'

export function isHardCapReached(state: AppState): boolean {
  const donationSum = state.eventDetails.financialDetails.collectedBaseCurrency
  const eventHardCap = state.eventDetails.event.hardCap

  return new BigNumber(donationSum).gte(eventHardCap ? eventHardCap : 0)
}

export function isSoftCapReached(state: AppState): boolean {
  const donationSum = state.eventDetails.financialDetails.collectedBaseCurrency
  const eventSoftCap = state.eventDetails.event.softCap

  return new BigNumber(donationSum).gte(eventSoftCap ? eventSoftCap : 0)
}

/**
 * @description Number of donors progress:
 *                - number of investors between 500 and 7500 1 icon active,
 *                - number of investors between 7500 and 15000 2 icons active
 *                - number of investors between 15000 and 22000 3 icons active
 *                - number of investors between 22000 and 30000 4 icons active
 *                - number of investors > 30000 5 icons active
 * @param state
 */
export function getDonorsProgress(state: AppState): number {
  const x = state.eventDetails.financialDetails.numOfInvestors

  if (new BigNumber(x).gte(500) && new BigNumber(x).lt(7500)) {
    return 1
  }

  if (new BigNumber(x).gte(7500) && new BigNumber(x).lt(15000)) {
    return 2
  }

  if (new BigNumber(x).gte(15000) && new BigNumber(x).lt(22000)) {
    return 3
  }

  if (new BigNumber(x).gte(22000) && new BigNumber(x).lt(30000)) {
    return 4
  }

  if (new BigNumber(x).gte(30000)) {
    return 5
  }

  return 0
}

export function getPowerProgress(state: AppState): number {
  const x = state.eventDetails.impactKPIs.estimatedPower

  if (new BigNumber(x).lt(39000)) {
    return 0
  } else if (new BigNumber(x).lt(250000)) {
    return 1
  } else if (new BigNumber(x).lt(460000)) {
    return 2
  } else if (new BigNumber(x).lt(670000)) {
    return 3
  } else if (new BigNumber(x).lt(885000)) {
    return 4
  } else {
    return 5
  }
}

export function getEmissionsProgress(state: AppState): number {
  const x = state.eventDetails.impactKPIs.reducedCO2Lifetime

  if (new BigNumber(x).lt(33333)) {
    return 0
  } else if (new BigNumber(x).lt(212500)) {
    return 1
  } else if (new BigNumber(x).lt(391667)) {
    return 2
  } else if (new BigNumber(x).lt(570833)) {
    return 3
  } else if (new BigNumber(x).lt(750000)) {
    return 4
  } else {
    return 5
  }
}

export function getPeoplePoweredProgress(state: AppState): number {
  const x = state.eventDetails.impactKPIs.peoplePowered

  if (new BigNumber(x).lt(130)) {
    return 0
  } else if (new BigNumber(x).lt(850)) {
    return 1
  } else if (new BigNumber(x).lt(1550)) {
    return 2
  } else if (new BigNumber(x).lt(2300)) {
    return 3
  } else if (new BigNumber(x).lt(3000)) {
    return 4
  } else {
    return 5
  }
}

export function getDonationsPerCountryForChart(state: AppState): ChartData[] {
  const { countries, total } = state.donations.perCountries
  const colors = ['#005578', '#AF235F', '#FFB900', '#AAB414', '#41AAAA']
  const countryDonationsChart: ChartData[] = []

  for (let i = 0; i < countries.length; i++) {
    const countryAlpha2 = countries[i].country

    if (countryAlpha2) {
      const countryTranslationId = listOfCountries(i18n)[countryAlpha2]

      countryDonationsChart.push({
        title: countryTranslationId ? i18n._({id: countryTranslationId}) : countryAlpha2,
        value: toPercentage(countries[i].count, total),
        color: colors[i]
      })
    }
  }

  return countryDonationsChart
}

export function getDonationsPerCurrencyForChart(state: AppState): ChartData[] {
  const total = state.donations.perCurrencies.total

  if (!state.donations.perCurrencies.currencies.length) {
    return []
  }

  const fiatCurrencies = state.donations.perCurrencies.currencies.filter((currency: InvestmentsPerCurrency) => currency.type === 'fiat')
  const fiatSum = fiatCurrencies.reduce((accumulator, currentValue) => {
    return new BigNumber(accumulator).plus(currentValue.value).toNumber()
  }, 0)

  const cryptoCurrencies = state.donations.perCurrencies.currencies.filter((currency: InvestmentsPerCurrency) => currency.type === 'asset')
  const cryptoSum = cryptoCurrencies.reduce((accumulator, currentValue) => {
    return new BigNumber(accumulator).plus(currentValue.value).toNumber()
  }, 0)

  const currenciesSymbol = fiatCurrencies.map((currency: InvestmentsPerCurrency) => currency.currency)
  const currencySymbolsString = currenciesSymbol && currenciesSymbol.length ? `(${currenciesSymbol.join(', ')})` : ''

  return [
    {
      title: i18n._(t`Fiat ${currencySymbolsString}`),
      value: toPercentage(fiatSum, total),
      color: '#005578'
    },
    {
      title: i18n._(t`Cryptocurrencies`),
      value: toPercentage(cryptoSum, total),
      color: '#41AAAA'
    }
  ]
}

export function getActiveSaleCycle(state: AppState): SaleCycleResponseEntity | undefined {
  const {
    saleCycles = []
  } = state.eventDetails.event

  return saleCycles.filter((cycle) => cycle.active)[0]
}

export function getUserSharePercentageOfTotalDonatedAmount(state: AppState): string {
  const { investmentDetails } = state.userEventDetails
  const { financialDetails } = state.eventDetails

  const myInvestment = investmentDetails.investmentValue ? investmentDetails.investmentValue : '0'
  const numOfDecimals = new BigNumber(myInvestment).gt(0) ? 4 : 0

  if (new BigNumber(financialDetails.collectedBaseCurrency).eq(0)) {
    return '0'
  }

  return new BigNumber(myInvestment)
    .dividedBy(financialDetails.collectedBaseCurrency)
    .multipliedBy(100)
    .toFormat(numOfDecimals)
}
